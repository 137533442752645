import { generateTokenPath, getProvidersPath } from '../../util/constant/endpoint'
import requests from './AxiosClient'

const ProviderService = {
  getListProviders: async () => {
    return requests.get(getProvidersPath, null, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getToken: async ({ access_token, state }) => {
    const jsonData = {
      "access_token": access_token,
      "state": state
    }
    console.log("🚀 ~ getToken: ~ jsonData:", jsonData)
    return requests.post(generateTokenPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}

export default ProviderService
