import React, { useState } from 'react';
import {
    CreditCardOutlined,
    QrcodeOutlined,
    CheckOutlined,
    CloseOutlined,
    SyncOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import { Button, Card, Space, Typography, message } from 'antd';
import { useApiPolling } from './useApiPolling';
import POSService from '../../core/services/POSService';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const PaymentComponent = ({ orderId, handleShowQR, addNewOrder, handleComletePaymentWithQR, posId, orderDetail, totalPaymentAmount }) => {
    const [qrSentloading, setQrSentloading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const { t } = useTranslation();


    const handleSentCardPayment = async () => {
        await POSService.getPosMachines(posId).then(res => {
            let orderData = {
                orderId: orderDetail.id,
                requestId: orderDetail.pos_reference,
                amount: totalPaymentAmount
            }
            POSService.sendOrderToPOSCardPayment(orderData, res.records[0]).then(res => {
                console.log(res, "res");
                startPolling()
                console.log();

            })

        })
    }


    const handlePaymentSuccess = async (paymentData) => {
        await handleComletePaymentWithQR().then((res) => {
            addNewOrder()
            message.success('Payment Successful!');
        })
        localStorage.removeItem('reloadProcessed');
        localStorage.setItem('qrPos', JSON.stringify({
            show_qr_pos: false,
        }));
        window.dispatchEvent(new Event('localStorageChanged'));
        console.log('Payment successful:', paymentData)
    }

    const handlePaymentFailed = (paymentData) => {
        message.error('Payment Failed');
        console.log('Payment failed:', paymentData)
    }

    const [errorMessage, setErrorMessage] = useState(null)

    const { data, loading, startPolling, stopPolling } = useApiPolling(
        async () => {
            try {
                const response = await POSService.getOrderDetail(orderId)
                return response[0]
            } catch (err) {
                throw err
            }
        },
        (order) => {
            if (order.state === 'paid') {
                handlePaymentSuccess(order)
                return true
            }
            if (['FAILED', 'CANCELLED', 'REJECTED'].includes(order.state)) {
                handlePaymentFailed(order)
                return true
            }
            return false
        },
        {
            interval: 5000,
            maxAttempts: 20,
            onError: (err) => {
                setErrorMessage(err.message || 'An error occurred during payment verification')
            },
        }
    )

    const [qr, setQr] = useState(null)

    const handlePaymentMethodSelection = (method) => {
        setPaymentMethod(method);
    }

    console.log(loading, "loading");
    console.log(qrSentloading, "qrSentloading");
    console.log(qr, "qr");
    console.log(data, "data");





    const renderPaymentMethodSelection = () => (
        <Card>
            <Title level={4} style={{ textAlign: 'center', marginBottom: 24 }}>
                {t('choose_payment_method')}
            </Title>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-around' }}>
                <Button
                    type="primary"
                    icon={<CreditCardOutlined />}

                    onClick={() => handlePaymentMethodSelection('card')}
                    style={{ width: 150, height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    danger
                >
                    <span style={{ marginTop: 10 }}>{t('card_payment')}</span>
                </Button>
                <Button
                    type="primary"
                    icon={<QrcodeOutlined />}

                    onClick={() => handlePaymentMethodSelection('qr')}
                    style={{ width: 150, height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                    danger
                >
                    <span style={{ marginTop: 10 }}> {t('qr_payment')}</span>
                </Button>
            </Space>
        </Card>
    )

    const renderQRPayment = () => (
        <Card>
            <Title level={4} style={{ textAlign: 'center', marginBottom: 24 }}>
                {t('qr_payment')}
            </Title>
            <Space direction="vertical" style={{ width: '100%', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                    <Button
                        type="primary"
                        icon={<QrcodeOutlined />}
                        onClick={() => {
                            setQrSentloading(true)
                            setQr(null)
                            handleShowQR().then(res => {
                                if (res) {
                                    setQr(true)
                                } else {
                                    setQr(false)
                                }
                            })
                        }}
                        danger
                        style={{ marginRight: 10 }}
                    >
                        {t('show_qr_code')}
                    </Button>

                    {!loading && (
                        <Button
                            type="primary"
                            icon={<SyncOutlined />}
                            onClick={startPolling}
                            danger
                        >
                            {t('check_payment_status')}
                        </Button>

                    )}

                    {loading && (
                        <Button
                            type="primary"
                            icon={<CloseOutlined />}
                            onClick={stopPolling}
                            danger
                        >
                            {t('stop_checking')}
                        </Button>
                    )}
                </div>

                {loading && (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                        <LoadingOutlined style={{ fontSize: 24, marginRight: 10 }} />
                        <Text>Checking payment status...</Text>
                    </div>
                )}

                {errorMessage && (
                    <div style={{ color: 'red', marginBottom: 16 }}>{errorMessage}</div>
                )}

                {data?.state === 'paid' && !loading && (
                    <div style={{ display: 'flex', alignItems: 'center', color: 'green' }}>
                        <CheckOutlined style={{ fontSize: 24, marginRight: 10 }} />
                        <Text strong>  {t('payment_completed')}</Text>
                    </div>
                )}

                {qrSentloading && qr == null && (
                    <div style={{ textAlign: 'center', margin: '20px 0' }}>
                        <LoadingOutlined style={{ fontSize: 24, marginRight: 10 }} />
                        <Text>{t('generating_qr_code')}</Text>
                    </div>
                )}

                {qr != null && !data?.state !== 'paid' && (
                    <>
                        {qr == true ? (
                            <div style={{ display: 'flex', alignItems: 'center', color: 'green', justifyContent: 'space-between' }}>
                                <CheckOutlined style={{ fontSize: 24, marginRight: 10 }} />
                                <Text style={{ marginRight: 10 }} strong>{t('qr_code_sent')}</Text>
                                {!loading && <Button
                                    type="primary"
                                    icon={<CloseOutlined />}
                                    onClick={() => {
                                        stopPolling();
                                        localStorage.removeItem('reloadProcessed');
                                        localStorage.setItem('qrPos', JSON.stringify({
                                            show_qr_pos: false
                                        }));
                                        window.dispatchEvent(new Event('localStorageChanged'));
                                        setQr(null)
                                        setQrSentloading(false)
                                    }}
                                    danger
                                >
                                    {t('hide_qr_code')}
                                </Button>}

                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', color: 'red' }}>
                                <CloseOutlined style={{ fontSize: 24, marginRight: 10 }} />
                                <Text strong>     {t('qr_code_not_sent_try_again')}</Text>
                            </div>
                        )}
                    </>
                )}

                <Button
                    type="default"
                    onClick={() => setPaymentMethod(null)}
                    style={{ marginTop: 16 }}
                >
                    {t('change_payment_method')}
                </Button>
            </Space>
        </Card >
    )

    const renderCardPayment = () => (
        <Card>
            <Title level={4} style={{ textAlign: 'center', marginBottom: 24 }}>
                {t('card_payment')}
            </Title>

            <Button
                type="primary"
                size="large"
                icon={<CreditCardOutlined />}
                style={{ width: '100%', marginTop: 16 }}
                onClick={handleSentCardPayment}
                danger
            >
                {t('pay_now')}
            </Button>
            <Button
                type="default"
                onClick={() => setPaymentMethod(null)}
                style={{ width: '100%', marginTop: 16 }}
            >
                {t('change_payment_method')}
            </Button>

        </Card>
    )

    return (
        <div >
            {!paymentMethod && renderPaymentMethodSelection()}
            {paymentMethod === 'qr' && renderQRPayment()}
            {paymentMethod === 'card' && renderCardPayment()}
        </div>
    )
}

export default PaymentComponent