import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UserService from '../../core/services/auth'
import ButtonLoading from '../../components/general/ButtonLoading'
import { useNavigate } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage } from '../../util/util'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = (data) => {
    setIsSubmitting(true)
    UserService.forgotPassword(data)
      .then((res) => {
        showSuccessMessage('Forgot password successfully')
      })
      .catch((err) => {
        showErrorMessage(err?.detail || 'Forgot password failed')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center">
          <div className="flex flex-col gap-[64px] bg-white p-10 shadow-lg rounded-md w-[479px]">
            <div>
              <div className='flex flex-col items-center gap-3'>
                <ImageWithBasePath
                  src={'assets/img/forgot-password/lock.svg'}
                  className="object-cover w-[32px] h-[40px]"
                />
                <div className='text-[20px] text-center mb-2 font-bold text-[rgb(38,38,38)]'>{t('forgot_password_title')}</div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='text-sm text-center text-[rgb(38,38,38)]'>
                  {t('forgot_password_message')}
                </div>
                <input type="text"  {...register('email')} className='border-[1px] border-solid w-full border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder={t('forgot_password_placeholder')} />
              </div>
            </div>
            <div className='flex flex-col gap-3'>
              <ButtonLoading
                loading={isSubmitting}
                content={t('forgot_password_submit_button')}
                className='w-full bg-emerald-500 text-white rounded-md py-3 px-4 font-medium hover:bg-emerald-600 transition-colors'
              />
              <ButtonLoading
                type='button'
                func={() => navigate('/signin')}
                content={t('forgot_password_back_button')}
                className='w-full bg-red-500 text-white rounded-md py-3 px-4 font-medium hover:bg-red-600 transition-colors'
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
