import React from 'react'
import ImageWithBasePath from '../../components/general/ImageCustom'
import ButtonLoading from '../../components/general/ButtonLoading'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const ExpiredToken = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div className="w-full h-screen">
            <div className="relative w-full h-full">
                <ImageWithBasePath
                src={'assets/img/authentication/signin-bg.png'}
                className="object-cover w-full h-full"
                />
                <form className="absolute inset-0 flex justify-center items-center">
                    <div className="flex flex-col gap-[64px] bg-white p-10 shadow-lg rounded-md w-[479px]">
                        <div>
                            <div className='flex flex-col items-center gap-3'>
                                <ImageWithBasePath
                                src={'assets/img/forgot-password/oops.svg'}
                                className="object-cover"
                                />
                            </div>
                            <div className='flex flex-col gap-3 items-center'>
                                <h1 className="text-3xl font-semibold text-[#464B77]">{t('expired_title')}</h1>
                                <p className="text-[#464B77] text-base text-center">
                                    {t('expired_message_1')}
                                </p>
                                <p className="text-[#464B77] text-base text-center">
                                    {t('expired_message_2')}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-3'>
                            <ButtonLoading type='button' func={() => navigate('/signin')} content={t('expired_back_button')} className='w-full bg-emerald-500 text-white rounded-md py-3 px-4 font-medium hover:bg-emerald-600 transition-colors' />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ExpiredToken
