import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { pagesRoute, posRoutes, publicRoutes } from './router.link'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../components/header'
import Sidebar from '../components/header/sidebar/SideBar'
import MetadataManager from '../metadata/MetadataManager'
import StoreService from '../core/services/company/StoreService'
import { MetaProvider } from './MetaContext'
import '../style/css/color.css'
import { useTranslation } from 'react-i18next'
import useScrollToTop from '../hooks/useScrollToTop'
import useHeaderStore from '../store/header'
import useThemeStore from '../store/theme'
import useBrowserSession from '../util/TabCloseDetector'
import { LocalStorageService } from '../util/lib/LocalStorage'
import { LANG } from '../util/constant/lang'
import ThemeSettings from '../components/header/ThemeSettings'
import { CookieStorage } from '../util/lib/Cookies'

const HeaderLayout = () => {
  const data = useSelector((state) => state.toggle_header);
  const [toggleMenuMobile, setToggleMenuMobile] = useState(false)

  return (<div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
    {/* <Loader /> */}
    <Header />
    <Sidebar setToggleMenuMobile={setToggleMenuMobile} />
    <Outlet />
    <ThemeSettings />
  </div>)
};


const AllRoutes = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const data = useSelector((state) => state.toggle_header)
  const location = useLocation()
  const { setRootContext } = useHeaderStore()
  const { setLayoutStyling } = useThemeStore()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const lang = CookieStorage.getLanguage() || LANG.VI
  const langContext = CookieStorage.getLanguageContext() || LANG.VI_VN

  const handleTabClose = useCallback(() => {
    try {
      LocalStorageService.clearToken()
      // sessionStorage.removeItem('token')
      // dispatch(actions.authLogout())
    } catch (error) {
      console.error('Error in handleTabClose:', error)
    }
  }, [dispatch])

  useBrowserSession(handleTabClose)

  useScrollToTop();

  useEffect(() => {
    CookieStorage.setLanguage(lang);
    CookieStorage.setLanguageContext(langContext);
    i18n.changeLanguage(lang);

    const hasAccessToken = () => {
      const token = LocalStorageService.getAccessToken()
      return !!token
    }

    if (
      !hasAccessToken() &&
      location.pathname !== "/forgot-password" &&
      location.pathname !== "/reset-password" &&
      location.pathname !== "/expired"
    ) {
      navigate("/signin");
    } else {
      if (location.pathname === "/") {
        navigate("/sales-dashboard");
      }
    }
  }, []);

  const Authpages = () => (
    <div className={`${data ? "header-collapse" : ""}`}>
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  );
  const [id, setId] = useState(null);
  const [metadata, setMetadata] = useState(null);
  useEffect(() => {
    if (metadata && metadata.primary_color) {
      // const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary')
      document.documentElement.style.setProperty('--primary', metadata.primary_color);
      document.documentElement.style.setProperty('--secondary', metadata.secondary_color);
    } else {
      document.documentElement.style.setProperty("--primary", "#000000"); // Example fallback color
    }
  }, [metadata]);

  const [loadingImport, setLoadingImport] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorData, setErrorData] = useState()
  const [openTouch, setOpenTouch] = useState(true)
  const [hover, setHover] = useState(false)
  const accessToken = LocalStorageService.getAccessToken()
  const fetchCurrentCompany = async () => {
    await StoreService.getCurrentCompany().then((res) => {
      setId(res?.current_company_id);
    });
  };
  const handleLoadingImport = (action) => {
    setLoadingImport(action);
  };
  const getUserCompany = async (companyId) => {
    await StoreService.getUserCompany(companyId).then((res) => {
      setMetadata(res?.records[0]);
    });
  };
  useEffect(() => {
    accessToken && fetchCurrentCompany();
    if (id && accessToken) {
      getUserCompany(id);
    }
  }, [id, accessToken]);

  const PosPages = () => (
    <div>
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  );

  return (
    <div className="App">
      <MetaProvider
        metadata={metadata}
        errorData={errorData}
        setErrorData={setErrorData}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        loadingImport={loadingImport}
        setLoadingImport={handleLoadingImport}
        openTouch={openTouch}
        setOpenTouch={setOpenTouch}
        setHover={setHover}
        hover={hover}
      >
        {metadata && <MetadataManager metadata={metadata} />}
        <Routes>
          <Route path="/" element={<PosPages />}>
            {posRoutes.map((route, id) => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
          </Route>

          <Route path={"/"} element={<HeaderLayout />}>
            {pagesRoute.map((route, id) => (
              <Route path={route.path} key={id} element={<route.element key={`${(route.name !== 'dashboard') && location.key}`} />} />
            ))}
          </Route>
          <Route path={"/"} element={<Authpages />}>
            {publicRoutes.map((route, id) => (
              <Route key={id} path={route.path} element={route.element} />
            ))}
          </Route>
        </Routes >
      </MetaProvider >
    </div >
  );
};
export default AllRoutes;
