export const COMPONENT_TYPE = {
  GROUP: 'group',
  FIELD: 'field',
  TREE: 'tree',
}

export const OBJECT_POSITION = 2
export const LIMIT_LOOP = 2

export const TABLE_TYPE = {
  GROUP: 'group',
  LIST: 'list',
}
