import React, { useState, useEffect } from 'react'
import ViewServices from '../../../../core/services/view-service/view-service'
import { useSearchParams } from 'react-router-dom'
import ImageWithBasePath from '../../../general/ImageCustom'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { evalJSONContext, evalJSONDomain, getSpecification } from '../../../../util/util'
import PopupFilter from '../PopupFilter'
import useSearch from '../../model/search'
import SearchList from './search-list'
import TagSearch from './tag-search'
import useSearchStore from '../../../../store/search'
import { useTranslation } from 'react-i18next'
import useHeaderStore from '../../../../store/header'
import { SEARCH_TYPE } from '../../../../util/constant/search-type'
import { matchDomains } from '../../../../core/domain/domain'
import { Filter } from 'react-feather'

const Search = ({ actionData, viewData, model, setDomain }) => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get('vid')
  const [showPopupFilter, setShowPopupFilter] = useState(false)
  const [specification, setSpecification] = useState()
  const [isFirstLoad, setIsFirstLoad] = useState(false)
  const { setTableHead, searchString, selectedTags, setFirstDomain, setFilterBy, setGroupBy, filterBy } = useSearchStore()
  const { onKeyDown, onChangeSearchInput, formatDomain, clearSearch, handleAddTagSearch } = useSearch()
  const { t } = useTranslation()
  const { rootContext } = useHeaderStore()

  const popupFilterRef = useClickOutside(() => {
    setShowPopupFilter(false)
  })

  const { search_view_id: searchView, views, domain, context } = actionData || {};
  const actionContext = evalJSONContext(context)
  const contextSearch = { ...rootContext, ...actionContext }

  const fetchData = async () => {
    try {
      views.push([searchView, 'search']);

      const searchRes = await ViewServices.getFieldView({
        id: Number(vid), resModel: model, views: views, context: contextSearch
      });

      const dataModel = searchRes?.models?.[model];
      const searchViews = searchRes?.views?.search;
      const heading = searchViews?.search_by
        ?.filter((item) => !matchDomains(contextSearch, item.invisible))
        ?.map(({ string, name, filter_domain, operator }) => ({
          dataIndex: name,
          title: string ?? dataModel[name]?.string,
          name: name ?? dataModel[name]?.name,
          filter_domain,
          operator
        }));

      const filterByList = searchViews?.filter_by.filter((item) => !matchDomains(contextSearch, item.invisible) && matchDomains(contextSearch, item.domain))?.map(item => ({ ...item, active: false }))
      const groupByList = searchViews?.group_by.filter((item) => !matchDomains(contextSearch, item.invisible))

      setSpecification(getSpecification(viewData, model));
      setFirstDomain(...evalJSONDomain(domain, contextSearch));
      setTableHead(heading);
      setFilterBy(filterByList);
      setGroupBy(groupByList);
      clearSearch()
      setIsFirstLoad(true)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (actionData) {
      fetchData()
    }
  }, [vid])

  useEffect(() => {
    const containSearchFilter = selectedTags?.find(item => item?.type === SEARCH_TYPE.FILTER_BY || item?.type === SEARCH_TYPE.SEARCH_BY)

    if (specification && (containSearchFilter || selectedTags?.length === 0)) {
      setDomain(formatDomain())
    }
  }, [selectedTags])

  useEffect(() => {
    if (specification && filterBy.length > 0 && isFirstLoad) {
      const searchDefaultList = Object.entries(actionContext).filter(([key, value]) => key.includes("search_default"));
      searchDefaultList.forEach((searchDefaultItem => {
        const filterDefault = filterBy?.find(item => item.name === searchDefaultItem[0].split("search_default_")[1])
        if (filterDefault) {
          handleAddTagSearch({
            title: <Filter size={16} className='h-4 w-4' />,
            name: filterDefault?.name,
            value: filterDefault?.string ?? filterDefault?.help,
            icon: <Filter />,
            domain: filterDefault?.domain,
            groupIndex: filterDefault?.group_index,
            type: SEARCH_TYPE.FILTER_BY
          });
          setFilterBy(filterBy.map(item => (item.name === filterDefault.name ? { ...item, active: true } : item)))
        }
      }))
      setIsFirstLoad(false)
    }
  }, [specification, isFirstLoad])

  return (
    <div className='!order-3 xl:!order-2 bg-white custom-search-input relative flex items-center w-full min-w-full max-w-full xl:min-w-[480px] flex-none xl:flex-1 rounded-[10px] bg-grey-100 shadow-[0px_1px_3px_rgba(16,24,40,0.1),0px_1px_2px_rgba(16,24,40,0.06)] min-h-[40px] border border-[#F2F2F2]'>
      <div className='relative flex md:min-w-[400px] max-w-full items-center gap-[8px] border-r border-[rgba(242,242,242,1)] p-1 !pl-4 w-full'>
        <div className='min-h-5 min-w-5'>
          <ImageWithBasePath src='assets/img/search-icon.svg' alt='search icon' width={20} height={20} />
        </div>
        <div className='flex flex-1 flex-wrap items-center gap-[8px]'>
          <TagSearch />
          <input
            value={searchString}
            className='min-h-[25px] w-fit flex-1 border-none bg-transparent outline-none min-w-[50px] text-sm'
            placeholder={t("search...")}
            onChange={(e) => {
              onChangeSearchInput(e.target.value)
              setShowPopupFilter(false)
            }}
            onKeyDown={onKeyDown}
            onKeyUp={(e) => e.preventDefault()}
          />
          <SearchList />
        </div>
      </div>
      <div
        className={`flex h-full cursor-pointer items-center justify-center px-[10px] max-w-fit`}
        onClick={() => {
          setShowPopupFilter(true)
        }}
      >
        <button className='w-max'>
          <ImageWithBasePath src='assets/img/arrow-down.svg' alt='arrow down' className='h-5 w-5 min-w-fit' />
        </button>
      </div>
      {showPopupFilter && (
        <div ref={popupFilterRef}>
          <PopupFilter />
        </div>
      )}
    </div>
  )
}

export default Search
