import React, { useEffect, useMemo, useState } from 'react';
import Calendar from '../../widget/Calendar';
import useHandleField from '../model/main';
import useTabModel from '../model/tab';
import TreeViewForm from './tree-view-form';
import { decimalToTime, getArrayValues } from '../../../util/util';
import { FaRegCalendarAlt, FaTable } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import useSelectStore from '../../../store/select';

const types = [{
  name: 'tree',
  icon: <FaTable />,
}, {
  name: 'calendar',
  icon: <FaRegCalendarAlt />
}]

const TabsView = ({ id, viewData, onchangeData, resModel, context, handleOnchange, title, }) => {
  const [searchParams] = useSearchParams()
  const modal = searchParams.get('model')
  const [tabFocus, setTabFocus] = useState()
  const [typeView, setTypeView] = useState(types[0].name)
  const { listSubject } = useSelectStore()

  const tabsData = useMemo(() => {
    return useTabModel(viewData, onchangeData, resModel)
  }, [viewData, onchangeData, resModel]);

  useEffect(() => {
    if (!tabFocus && tabsData && tabsData.length > 0) {
      setTabFocus(0)
    }
  }, [tabsData])

  const getViewInTab = (tab) => {
    if (tab?.type_tab === "form") {
      const { fieldList } = useHandleField({ fields: tab?.fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData, handleOnchange: handleOnchange })
      return (
        <>
          {fieldList}
        </>
      );
    } else if (tab?.type_tab === "tree") {
      return <TreeViewForm handleOnchange={handleOnchange} onchangeData={onchangeData} viewData={viewData} resModel={resModel} tab={tab} context={context} title={title} />
    }
  }

  const renderTableData = () => {
    return (
      tabsData?.length > 0 && <div>
        <div style={{ scrollbarWidth: "none" }} className='w-full flex border-b border-[#EEEEEE] mb-2 overflow-x-auto'>
          {tabsData?.map((item, index) => {
            return <button key={index} onClick={() => setTabFocus(index)} type='button'
              className={`p-4 border-b-2 whitespace-nowrap ${tabFocus === index ? '!border-primary font-medium text-[#121212]' : 'border-transparent text-[#525252] font-normal'}`}>{item?.string}</button>
          })}
        </div>
        {tabsData?.map((tab, index) => {          
          return (
            <div className={`${tabFocus === index ? 'block' : 'hidden'} relative`} key={`tab-${index}`}>
              {getViewInTab({ ...tab, index })}
            </div>
          )
        })}
      </div>
    )
  }

  const renderCalendarData = () => {
    const formatEvents = getArrayValues(onchangeData).map(event => {
      const padTime = (time) => {
        if (time < 10) {
          return `0${time}`;
        }
        return time;
      };

      const title = listSubject.find(subject => subject?.value === event?.subject)?.label || 'event 1'
      return ({
        title: title,
        itemId: event?.id,
        start: new Date(`${event?.date}T${padTime(decimalToTime(event?.time_from))}:00`),
        end: new Date(`${event?.date}T${padTime(decimalToTime(event?.time_till))}:00`),
      })
    });

    return (
      <Calendar events={formatEvents} context={context} tabsData={tabsData} id={id} />
    )
  }

  const hasCalendar = viewData?.views?.form?.notebook_view_mode?.calendar?.invisible === false

  return (
    <div>
      {hasCalendar && <div className="flex gap-2 items-enter mb-4 justify-end">
        {types.map((item, index) => (
          <button
            key={index}
            onClick={() => setTypeView(item.name)}
            type='button'
            className={`p-2 duration-100 rounded-md border-2 !border-primary whitespace-nowrap ${typeView === item.name ? '!bg-primary font-medium text-[#fff]' : ' text-[#525252] hover:bg-primary/10 font-normal'}`}
          >
            {item.icon}
          </ button>
        ))}
      </div>}

      {typeView === types[0]?.name ? renderTableData() : renderCalendarData()}
    </div>
  )
}

export default TabsView
