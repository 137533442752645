import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { menu_focus } from "../../../util/redux/Action";
import { buildUrl } from "../../../util/url";
import { useTranslation } from "react-i18next";
import ImageWithBasePath from "../../general/ImageCustom";
import useHeaderStore from "../../../store/header";


const HorizontalSidebar = ({ menuList = [] }) => {
  const { t } = useTranslation()
  const paddingStart = 15
  const mainMenu = [
    {
      "isMenuHard": true,
      "link": "/sales-dashboard",
      "action": false,
    }
  ]
  const posMenu = [
    {
      "isMenuHard": true,
      "link": "/pos-session",
      "action": false,
    }
  ]

  const LIMIT_MENU = 7
  const menus = menuList.map(menu => {
    if (menu.code === "dashboard") {
      return {
        ...menu,
        ...mainMenu[0]
      }
    }

    if (menu.code === "pos") {
      return {
        ...menu,
        ...posMenu[0]
      }
    }

    return menu
  })

  // const menus = [...mainMenu, ...posMenu, ...menuList]
  const menuMain = menus.length > LIMIT_MENU ? menus.slice(0, LIMIT_MENU) : menus
  const menuMore = menus.length > LIMIT_MENU ? menus.slice(LIMIT_MENU) : []
  const itemMore = {
    "id": "more",
    "isMenuHard": true,
    "link": "",
    "action": false,
    "active": true,
    "child_id": menuMore,
    "groups_id": [],
    "is_display": true,
    "name": "More",
    "sequence": 30,
    "url_icon": false,
    "web_icon_data": "assets/img/icons/menu-dot.svg"
  }
  const [openMenus, setOpenMenus] = useState({});
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setFadeIn } = useHeaderStore()

  const handleNavigation = (item) => {
    const id = item?.action?.id?.id;
    const model = item?.action?.res_model;
    const view = item?.action?.binding_view_types?.split(",")[0];

    sessionStorage.setItem("url_icon", JSON.stringify(item));
    setFadeIn(false)
    dispatch(menu_focus(id));
    navigate(buildUrl(view, id, model));
  };

  const refs = useRef([...mainMenu, ...menuList, ...posMenu, itemMore].map(() => React.createRef()))

  useEffect(() => {
    refs.current = [...mainMenu, ...menuList, ...posMenu, itemMore].map(() => React.createRef())
  }, [menuList])

  const handleClickOutside = (event) => {
    const isOutside = refs.current.some(ref => ref.current && ref.current.contains(event.target));
    // Kiểm tra nếu click bên ngoài dropdown
    if (!isOutside) {
      setOpenMenus({});
    }
  };

  const getChildMenu = (items, level = 0, isMore = false, pStart, menuRoot,) => {
    return items?.map((menu, index) => {
      return (
        <li key={index}
          className={`submenu ${!menu?.is_display && 'hidden'} ${isMore && 'relative'} bg-white`}>
          <Link
            style={{
              paddingLeft: `${pStart}px`
            }}
            onClick={(e) => {
              e.preventDefault();
              if (!menu?.child_id.length > 0 && menu?.isMenuHard) {
                navigate(menu?.link)
              } else if (menu.child_id && menu.child_id.length > 0) {
                toggleMenu(menu, level, menuRoot, isMore);
              } else {
                handleNavigation(menu);
                setOpenMenus({});
              }
            }}
            className={openMenus[`level-${level}-${menu.id}`] ? "subdrop" : ""}
          >
            <div>
              {!isMore ? <><span>{menu?.name}</span> {menu?.child_id.length > 0 && <span className="menu-arrow" />}</> : <>{menu?.child_id.length > 0 && <span className="menu-arrow" />} <span>{menu?.name}</span></>}
            </div>
          </Link>
          {
            menu?.child_id.length > 0 && (
              <ul id="menu"
                className={` bg-white
                ${isMore && '!absolute !right-[100%] !shadow-[4px_6px_12px_-1px_rgb(0,0,0,0.3)]'}
                level-${level}-${menu.id} 
                ${openMenus[`level-${level}-${menu.id}`]
                    ? `open px-10 ${isMore && '!overflow-hidden !py-[10px] opacity-100'}`
                    : `!max-h-0 !overflow-hidden !p-0 ${isMore && 'opacity-0'}`} 
                `}
              // style={{
              //   display:
              //     openMenus[`level-${level}-${menu.id}`] && menu.is_display
              //       ? "block"
              //       : "none",
              // }}
              >
                {getChildMenu(menu?.child_id, level + 1, false, (level + 1) * paddingStart, menuRoot)}
              </ul>
            )
          }
        </li>
      )
    })
  }


  const toggleMenu = (item, level, menuRoot, isMore = false) => {
    let paddingTotal = 21
    let menuRootItem = document.querySelector(`.${menuRoot}`)
    let menuChildItem = document.querySelector(`.level-${level}-${item.id}`)

    setOpenMenus((prev) => {
      const newOpenMenus = { ...prev };
      Object.keys(newOpenMenus).forEach((key) => {
        if (
          newOpenMenus[key] &&
          key !== item.id.toString() &&
          key.startsWith(`level-${level}-`)
        ) {
          delete newOpenMenus[key];
        }
      });
      newOpenMenus[`level-${level}-${item.id}`] =
        !prev[`level-${level}-${item.id}`];

      if (!prev[menuRoot]) {
        menuRootItem.style.overflow = "hidden";
        requestAnimationFrame(() => {
          const menuRootHeight = menuRootItem.scrollHeight;
          menuRootItem.style.maxHeight = menuRootHeight + paddingTotal + "px";
        });
      } else {
        if (isMore) {
          menuRootItem.style.overflow = 'visible'
        }
        const menuRootHeight = menuRootItem.scrollHeight
        if (!prev[`level-${level}-${item.id}`] && `level-${level}-${item.id}` !== menuRoot) {
          const menuChildHeight = menuChildItem.scrollHeight
          if (!isMore) { menuRootItem.style.maxHeight = menuRootHeight + menuChildHeight + "px" }
          menuChildItem.style.maxHeight = menuRootHeight + menuChildHeight + "px"
        } else if (prev[`level-${level}-${item.id}`] && `level-${level}-${item.id}` !== menuRoot) {
          const menuChildHeight = menuChildItem.scrollHeight
          if (!isMore) menuRootItem.style.maxHeight = menuRootHeight - menuChildHeight + "px"
          menuChildItem.style.maxHeight = 0 + "px"
          menuChildItem.style.border = 'none'
        }
      }

      sessionStorage.setItem("parent_icon", JSON.stringify(item));

      return newOpenMenus;
    });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="sidebar relative horizontal-sidebar bg-white">
      <div id="sidebar-menu-3" className="sidebar-menu ms-2 flex items-center relative h-full w-full">
        <ul className="nav absolute left-0 flex-nowrap menu-container bg-white">
          {menuMain?.map((menu, index) =>
          (
            <li key={index} className="submenu z-10 bg-white">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (!menu?.child_id.length > 0 && menu?.isMenuHard) {
                    navigate(menu?.link)
                  } else if (menu.child_id && menu.child_id.length > 0) {
                    toggleMenu(menu, 0, `level-${0}-${menu.id}`);
                    // setMenuFocus((prev) => {
                    //   return { ...prev, [`level-${0}-${menu.id}`]: !prev[`level-${0}-${menu.id}`] }
                    // });
                  } else {
                    handleNavigation(menu);
                  }
                }}
                className={openMenus[`level-${0}-${menu.id}`] ? "subdrop" : ""}
              >
                <img src={menu?.web_icon_data} width={50} height={50} alt="" />
                <div className="flex items-center whitespace-nowrap" >
                  <span> {menu?.name}</span> {menu?.child_id.length > 0 && <span className="menu-arrow" />}</div>
              </Link>
              {
                menu?.child_id.length > 0 && (
                  <ul key={index} id="menu"
                    className={`bg-white shadow-[2px_8px_12px_-1px_rgb(0,0,0,0.1)]
                      ${openMenus[`level-${0}-${menu.id}`] ? 'open px-10' : '!max-h-0 !overflow-hidden p-0'} 
                      level-${0}-${menu.id}`}
                    ref={refs.current[index]}
                  >
                    {getChildMenu(menu?.child_id, 1, false, paddingStart, `level-${0}-${menu.id}`)}
                  </ul>
                )
              }
            </li>
          )
          )}

          {menuMore.length > 0 && <li className="submenu z-10 relative">
            <Link
              onClick={(e) => {
                e.preventDefault();
                if (itemMore.child_id && itemMore.child_id.length > 0) {
                  toggleMenu(itemMore, 0, `level-${0}-${itemMore.id}`, true);
                  // setMenuFocus((prev) => {
                  //   return { ...prev, [`level-${0}-${itemMore.id}`]: !prev[`level-${0}-${itemMore.id}}`] }
                  // });
                }
              }}
              className={openMenus[`level-${0}-${itemMore.id}`] ? "subdrop" : ""}
            >
              <div className="flex items-center whitespace-nowrap">
                <ImageWithBasePath src={itemMore?.web_icon_data} width={50} height={50} alt="" />
              </div>
            </Link>
            {
              itemMore?.child_id.length > 0 && (
                <ul key={itemMore.id} id="menu"
                  className={`bg-white top-full right-0 shadow-[2px_8px_12px_-1px_rgb(0,0,0,0.1)]
                  level-${0}-${itemMore.id} ${openMenus[`level-${0}-${itemMore.id}`]
                      ? 'open px-10'
                      : '!max-h-0 !overflow-hidden !p-0'}`}
                  ref={refs.current[refs.current?.length - 1]}
                >
                  {getChildMenu(itemMore?.child_id, 1, true, paddingStart, `level-${0}-${itemMore.id}`)}
                </ul>
              )
            }
          </li>}
        </ul>
      </div>
    </div >
  );
};

export default HorizontalSidebar;
