import { create } from 'zustand'

const useHeaderStore = create((set) => ({
  lang: 'vi_VN',
  uid: undefined,
  companies: [],
  fadeIn: false,
  rootContext: { lang: 'vi_VN', allowed_company_ids: [] },
  isLoadingOAuth: false,
  setLang: (lang) => set(() => ({ lang: lang })),
  setLoadingOAuth: (isLoading) => set(() => ({ isLoadingOAuth: isLoading })),
  setRootContext: (context) => set((prev) => ({ rootContext: { ...prev['rootContext'], ...context } })),
  setCompanies: (companyValues) => set(() => ({ companies: companyValues })),
  setUid: (uid) => set(() => ({ uid: uid })),
  setFadeIn: (focus) => set(() => ({ fadeIn: focus })),
}))

export default useHeaderStore