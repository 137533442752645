import React from 'react';
import { Controller } from 'react-hook-form';
import ActiveBadge from './ActiveBadge';

const CheckboxGroup = (props) => {
  const { name, readonly, required, invisible, methods, value, handleOnchange, isForm } = props
  // const formProps = methods ? methods.register(name, {
  //   onChange: (e) => {
  //     methods.setValue(e.target.name, e.target.checked, {
  //       shouldDirty: true
  //     });
  //     handleOnchange(e.target.name, e.target.checked)
  //   }
  // }) : {};

  console.log("checkbox", props);

  if (!isForm) {
    if (name === "is_active" || name === "active") {
      return (
        <ActiveBadge type={value} />
      );
    } else {
      return (<input
        type='checkbox'
        disabled={true}
        className='custom-checkbox'
        checked={value}
      />)
    }

  }

  return (
    <Controller
      name={name}
      control={methods?.control}
      render={({ field }) => {
        console.log("🚀 ~ field:", field)
        return (
          <div className='flex items-center gap-2'>
            <input
              {...field}
              onChange={(e) => {
                methods.setValue(name, e.target.checked, {
                  shouldDirty: true
                })
                handleOnchange(name, e.target.checked)
              }}
              type='checkbox'
              disabled={readonly}
              required={!invisible && required}
              className='custom-checkbox'
              checked={field?.value}
            />
            {name === "is_active" && <ActiveBadge type={value} />}
          </div>
        )
      }}
    />
  );
};

export default CheckboxGroup;