import { create } from 'zustand'

const useTableStore = create((set) => ({
  fields: {},
  selectedRowKeys: [],
  page: 0,
  specification: {},
  openTableFilter: false,
  footerGroupTable: {},
  setFields: (newFields) => set(() => ({ fields: newFields })),
  setSelectedRowKeys: (newFields) => set(() => ({ selectedRowKeys: newFields })),
  setSpecification: (newSpecification) => set(() => ({ specification: newSpecification })),
  setPage: (newPage) => set(() => ({ page: newPage })),
  setOpenTableFilter: (newOpenTableFilter) => set(() => ({ openTableFilter: newOpenTableFilter })),
  setFooterGroupTable: (newFooterGroupTable) => set(() => ({ footerGroupTable: newFooterGroupTable }))
}))

export default useTableStore
