/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ImageWithBasePath from '../../components/general/ImageCustom'
import { ShoppingCart } from 'feather-icons-react/build/IconComponents'
import {
  ArrowLeft,
  Check,
  MinusCircle,
  PlusCircle,
  RotateCw,
  Settings,
  Table,
  Trash2,
  UserPlus,
  X,
} from 'react-feather'

import ReactLoading from 'react-loading'
import Select from 'react-select'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CustomTab from './tab'
import QuantityProduct from './quantityProduct'
import POSService from '../../core/services/POSService'
import { formatPrice } from '../../core/utils/formatter'
import { useTranslation } from 'react-i18next'
import alertify from 'alertifyjs'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'
import { currentDateOdoo } from '../../core/utils/formatterDay'
import RestaurantTable from './restaurant'
import { CookieStorage } from '../../util/lib/Cookies'
import { LANG } from '../../util/constant/lang'
import RestaurantService from '../../core/services/RestaurantService'
import { convertToUTC7, generatePosReference } from '../../util/util'
import AddCustomerModal from './addNewCustomer'
import Loading from './Loading'
const Pos = () => {
  const { t } = useTranslation()
  const { posId } = useParams()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const lang = CookieStorage.getLanguageContext() || LANG.VI_VN
  if (!posId) {
    navigate(`/pos-session`)
  }

  const MySwal = withReactContent(Swal)

  const showConfirmationAlert = () => {
    MySwal.fire({
      title: 'Bạn có chắc chắn muốn bỏ sản phẩm này ?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Đồng ý',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Không',
    }).then((result) => {
      if (result.isConfirmed) {
        // POSService.deleteProductInOrder()
        MySwal.fire({
          title: 'Deleted!',
          text: 'Your file has been removed.',
          className: 'btn btn-success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationAlertCloseSession = () => {
    MySwal.fire({
      title: t('confirm_close_session'),
      text: t('confirm_close_session_text'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: t('notify_close_session'),
          className: 'btn btn-success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        handleCloseSession()
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationAlertDeleteProductOrder = (productId) => {
    MySwal.fire({
      title: t('confirm_delete_product_order'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await POSService.deleteProductInOrder(productId)
        let newOrderList = orderList.filter((item) => item.id !== productId)
        setOrderList((prev) => prev.filter((item) => item.id !== productId))
        updateOrderInvoice(newOrderList)
        MySwal.fire({
          title: t('notify_remove_product'),
          className: 'btn btn-success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationAlertClearAllProductOrder = () => {
    MySwal.fire({
      title: t('confirm_delete_product_order'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await clearAllProductsOrder().then(res => {
          if (res) {
            MySwal.fire({
              title: t('notify_remove_product'),
              className: 'btn btn-success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            MySwal.fire({
              title: t('error_remove_product'),
              className: 'btn btn-danger',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            })
          }
        }
        )


      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationAlertLeaveTableInvoice = () => {
    MySwal.fire({
      title: t('confirm_leave_table_invoice'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await POSService.updateOrder(orderDetail.id, {
          table_id: false,
          lines: [],
        }).then(async () => {
          setSelectedTable(null)
          updatePosContext(null, null)
          const productIds = orderList.map((order) => order.id).join(',')

          await POSService.deleteProductInOrder(productIds)
          setOrderList([])
          updateOrderInvoice([])
          POSService.getOrderBySession(sessionId).then((res) => {
            setOrderBySession(res)
          })
        })
        // MySwal.fire({
        //   title: t('notify_remove_product'),
        //   className: 'btn btn-success',
        //   confirmButtonText: 'OK',
        //   customClass: {
        //     confirmButton: 'btn btn-success',
        //   },
        // })
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationAlertRemoveOrderInKitchen = (func) => {
    MySwal.fire({
      title: t('confirm_remove_order_kitchen_complete'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        func()
        MySwal.fire({
          title: t('notify_remove_product'),
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } else {
        MySwal.close()
      }
    })
  }

  const languages = [
    {
      name: 'VI',
      code: 'vi_VN',
    },
    {
      name: 'ENG',
      code: 'en_US',
    },
  ]

  const context_company_redux = useSelector((state) => state.context_company)
  const [context_company, setContext_company] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [productList, setProductList] = useState([])
  const [orderList, setOrderList] = useState([])
  const [orderDetail, setOrderDetail] = useState({})
  const [categories, setCategories] = useState([])
  const [posCategories, setPosCategories] = useState([])
  const [cateFilter, setCateFilter] = useState(0)
  const [loadingOrderDetail, setLoadingOrderDetail] = useState(true)
  const [loadingAddProduct, setLoadingAddProduct] = useState(-1)
  const [loadingProduct, setLoadingProduct] = useState(true)
  const [sessionId, setSessionId] = useState(true)
  const [orderBySession, setOrderBySession] = useState([])
  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [showAddProduct, setShowAddProduct] = useState(false)
  const [productAmountChange, setProductAmountChange] = useState(1)
  const [productAdd, setProductAdd] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState([])
  const [methodSelect, setMethodSelect] = useState(null)
  const [showCompletePayment, setShowCompletePayment] = useState(false)
  const [showChooseMethod, setShowChooseMethod] = useState(false)
  const [showPrintReceipt, setShowPrintReceipt] = useState(false)
  const [activeKey, setActiveKey] = useState(null)
  const [showBarCode, setShowBarCode] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [keyDelete, setKeyDelete] = useState(0)
  const [barcode, setBarcode] = useState('')
  const [isScanning, setIsScanning] = useState(false)
  const [updateState, setUpdateState] = useState(false)
  const [posConfig, setPosConfig] = useState({})
  const [floors, setFloor] = useState([])
  const [selectedTable, setSelectedTable] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])
  const [isOn, setIsOn] = useState(languages[0].code === lang)
  const [newOrders, setNewOrders] = useState([])
  const [posSession, setPosSession] = useState()
  const [posContext, setPosContext] = useState({ selected_table: null, order_by_session: null })
  const [preparationOrderLinesIdOfTable, setPreparationOrderLinesIdOfTable] = useState([])
  const [preparationOrders, setPreparationOrders] = useState([])
  const ordersOfTable = []

  const tableIdSet = new Set(floors.flatMap((t) => t.table_ids.map((table) => table.id)))

  const tableHasOrder = orderBySession?.records
    ?.filter((record) => tableIdSet.has(record.table_id.id) && record.state === 'draft')
    .map((record) => record.table_id.id)

  const checkCompleteOrder = (orderList) => {
    // return true if order is not completed
    return orderList?.at(-1)?.stage_id?.display_name === 'Completed' ? false : true
  }

  orderBySession?.records?.forEach((order) => {
    const preparationOrderOfOrder = []
    preparationOrders?.map((preparationOrder) => {
      if (order.id === preparationOrder.pos_order_id && checkCompleteOrder(preparationOrder?.order_stage_ids)) {
        preparationOrderOfOrder.push({ ...preparationOrder, table_id: order.table_id.id })
      }
    })
    ordersOfTable?.push({ ...order, preparationOrders: preparationOrderOfOrder })
  })

  useEffect(() => {
    // Retrieve posContext from localStorage when the component mounts
    const storedPosContext = localStorage.getItem('posContext')
    if (storedPosContext) {
      setPosContext(JSON.parse(storedPosContext))
    }
  }, [])

  const updatePosContext = (selectedTable, orderBySession) => {
    const posContext = {
      selected_table: selectedTable,
      order_by_session: orderBySession,
    }

    setPosContext(posContext)

    localStorage.setItem('posContext', JSON.stringify(posContext))
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!isScanning) {
        setIsScanning(true)
        setBarcode('')
      }

      // Check if the "Enter" key was pressed
      if (event.key === 'Enter') {
        console.log('Scanned Barcode:', barcode)
        setShowBarCode(barcode)
        setIsScanning(false)
        setBarcode('') // Reset after processing
      } else {
        // Append each key press to the barcode state
        setBarcode((prevBarcode) => prevBarcode + event.key)
      }
    }

    // Listen for keypress events
    window.addEventListener('keypress', handleKeyPress)

    // Clean up the event listener
    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [barcode, isScanning])

  useEffect(() => {
    const fetchSessionId = async () => {
      const sessionIdResponse = await POSService.getSessionIdByPosId(posId, context_company)

      setContext_company(context_company_redux)
      if (sessionIdResponse.length <= 0) {
        navigate(`/pos-session`)
      }
      setSessionId(sessionIdResponse.records[0]?.id)
    }
    fetchSessionId()
  }, [posId])

  useEffect(() => {
    if (sessionId) {
      fetchData()
    }
  }, [cateFilter, sessionId])

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const fetchedCustomerData = await loadOptionsCustomers()
        setCustomers(fetchedCustomerData)
        if (orderDetail?.partner_id?.id) {
          const defaultValue = customers.find((option) => option.value === orderDetail.partner_id.id)
          if (defaultValue) {
            setSelectedCustomer({ value: defaultValue.value, label: defaultValue.label })
          }
        }
      } catch (error) {
        console.error('Error fetching customers:', error)
      }
    }

    fetchCustomers()
  }, [orderDetail])

  useEffect(() => {
    if (posSession) {
      createInitInvoice()
    }
  }, [posSession])

  useEffect(() => {
    if (posId && orderBySession) {
      fetchPreparationOrders()
    }
  }, [posId, orderBySession, orderList])

  useEffect(() => {
    let preparationOrders = ordersOfTable?.find((order) => order.table_id.id === selectedTable)?.preparationOrders
    let preparationProductOrder = preparationOrders?.flatMap((order) => order.preparation_display_order_line_ids)
    setPreparationOrderLinesIdOfTable(preparationProductOrder?.flatMap((order) => order.product_id?.id))
  }, [orderBySession, orderList])

  const fetchPreparationOrders = async () => {
    const preparationOrderData = await Promise.all([RestaurantService.getPreparationOrderByPosConfigId(posId)])
    if (preparationOrderData) {
      setPreparationOrders(preparationOrderData[0]?.records)
    }
  }

  // Add this to your print handling function
  const handlePrint = () => {
    // Trigger print
    window.print()
  }

  const fetchData = async () => {
    const [categoriesData, posCategoriesData, productsData, orderBySessionData, posConfigData, sessionData] =
      await Promise.all([
        POSService.getCategories(),
        POSService.getPosCategories(),
        POSService.getProducts(cateFilter),
        POSService.getOrderBySession(sessionId),
        POSService.getListPOSById(posId),
        POSService.getSessionById(sessionId),
      ])

    if (categoriesData?.length > 0) {
      setCategories(categoriesData)
    }

    if (posCategoriesData?.length > 0) {
      setPosCategories(posCategoriesData)
    }

    if (orderBySessionData?.records) {
      setOrderBySession(orderBySessionData)
    }

    if (productsData?.records) {
      setProductList(productsData.records)
      setFilteredProducts(productsData.records)
    }

    if (posConfigData?.records) {
      setPosConfig(posConfigData.records[0])
      setFloor(posConfigData.records[0].floor_ids?.sort((a, b) => a.id - b.id))
    }

    if (sessionData?.records) {
      setPosSession(sessionData.records[0])
    }

    setLoadingProduct(false)
  }

  const createInitInvoice = async () => {
    if (orderBySession.length === 0) {
      const bodyNewOrder = {
        session_id: sessionId,
        amount_tax: 0,
        amount_total: 0,
        amount_paid: 0,
        amount_return: 0,
        table_id: false,
        sequence_number: orderBySession.length + 1,
        pos_reference: `Order ${generatePosReference(posSession?.id, posSession?.login_number, orderBySession.length + 1)}`,
      }
      await POSService.newOrder(bodyNewOrder).then((res) => {
        POSService.getOrderBySession(sessionId).then((res) => {
          setOrderBySession(res)
        })
      })
    }
  }

  const onChange = useCallback(async (newActiveKey) => {
    if (newActiveKey !== undefined) {
      setActiveKey(newActiveKey)
      setLoadingOrderDetail(true)
      const orderDetail = await POSService.getOrderDetail(newActiveKey)
      if (orderDetail?.length > 0 && orderDetail[0]?.lines && orderDetail[0].lines?.length >= 0) {
        const { lines, ...rest } = orderDetail[0]
        setOrderList(lines)
        setOrderDetail(rest)
        updateOrderInvoice(lines, newActiveKey)
        updateCustomerInvoice(null)
        setSelectedCustomer(null)
      }
    }
    setLoadingOrderDetail(false)
  }, [])

  const handleQuantityChange = useCallback((productId, newQuantity) => {
    setOrderList((prevList) =>
      prevList.map((product) => (product.id === productId ? { ...product, qty: newQuantity } : product))
    )
  }, [])

  const handleOrderDetailsUpdate = async (orderDetail) => {
    try {
      const orderDetailData = await POSService.getOrderDetail(orderDetail)

      if (
        orderDetailData.length > 0 &&
        orderDetailData[0]?.lines &&
        orderDetailData[0].lines?.length > 0 // Updated to be more strict on lines check
      ) {
        const { lines, ...rest } = orderDetailData[0]

        // Set the order list and order details
        setOrderList(lines)
        setOrderDetail(rest)
        updateOrderInvoice(lines)
        // Notify success
      } else {
        // Notify failure if order details are missing
        alertify.error(t('change_fail'))
      }
    } catch (error) {
      // Handle errors (e.g., network issues, API errors)
      console.error('Error fetching order details:', error)
      alertify.error(t('change_fail'))
    }
  }

  const handleSelectCate = (cateID) => {
    setLoadingProduct(true)
    setCateFilter(cateID)
  }

  const loadOptionsCustomers = async (inputValue = '') => {
    try {
      const result = await POSService.getCustomers(inputValue)

      const option = result.map((customer) => ({
        value: customer.id,
        label: `${customer.name} - ${customer.phone}`,
      }))
      return option
    } catch (error) {
      console.error('Error loading customers:', error)
    }
  }

  const handleQuantityChangeAddProduct = async (product) => {
    if (product.qty > 0 && !isNaN(product.qty)) {
      let tax_rate = product.tax_ids_after_fiscal_position[0]?.amount / 100
      let taxAmount = product.price_unit * tax_rate ? product.price_unit * tax_rate : 0
      let parse_quantity = parseInt(product.qty, 10) // Parse new quantity to integer

      // Use the base unit price to calculate the total, not the subtotal
      let unitPrice = product.price_unit // Base unit price without tax
      let unitPriceIncl = product.price_unit + taxAmount // Base unit price with tax

      // Update product quantity in the backend
      await POSService.updateProductAmountInOrder(product.id, {
        order_id: orderBySession.id, // Use current order ID
        product_id: product.product_id.id, // Use current product ID
        qty: parse_quantity, // Update the quantity
        price_subtotal_incl: unitPriceIncl * parse_quantity, // Recalculate subtotal with tax based on unit price
        price_subtotal: unitPrice * parse_quantity, // Recalculate subtotal without tax based on unit price
      })

      // Update local state and notify parent or other components
      // setQuantity(newQuantity) // Update quantity in local state
      handleQuantityChange(product.id, product.qty) // Call the callback to notify change
      handleOrderDetailsUpdate(orderDetail.id)
    }
  }
  const handleAmountChange = (e) => {
    const value = e.target.value
    if (value !== '' && Number.isInteger(Number(value)) && Number(value) > 0) {
      setProductAmountChange(Number(value))
    } else {
      alertify.error('Giá trị nhập phải là số nguyên dương')
    }
  }
  const handleDecrement = () => setProductAmountChange((prev) => Math.max(prev - 1, 1))
  const handleIncrement = () => setProductAmountChange((prev) => prev + 1)

  const handleCloseAddProduct = () => {
    setProductAdd(null)
    setLoadingAddProduct(-1)
    setShowAddProduct(false)
  }

  const isProductInOrderList = () => {
    return orderList.some((order) => order.product_id.id === productAdd.id)
  }

  const handleAddProductFinal = async () => {
    try {
      // setLoadingAddProduct(productAdd.id);
      if (orderDetail?.state === 'draft') {
        let tax_rate = productAdd?.taxes_id[0]?.amount / 100
        const taxAmount = productAdd.list_price * tax_rate ? productAdd.list_price * tax_rate : 0

        if (isProductInOrderList()) {
          const index = orderList.findIndex((order) => order.product_id.id === productAdd.id)

          if (index !== -1) {
            orderList[index] = {
              ...orderList[index],
              qty: orderList[index].qty + productAmountChange,
            }
            handleQuantityChangeAddProduct(orderList[index])
            setUpdateState((prev) => !prev)
          }
        } else {
          await POSService.updateProductInOrder([
            {
              order_id: orderDetail.id,
              product_id: productAdd.id,
              qty: parseInt(productAmountChange),
              full_product_name: productAdd.name,
              price_unit: productAdd.list_price,
              tax_ids: [productAdd?.taxes_id[0]?.id ? productAdd?.taxes_id[0]?.id : []],
              price_subtotal_incl: (productAdd.list_price + taxAmount) * parseInt(productAmountChange),
              price_subtotal: productAdd.list_price * parseInt(productAmountChange),
            },
          ])
          const orderDetailData = await POSService.getOrderDetail(orderDetail.id)
          if (orderDetailData.length > 0 && orderDetailData[0]?.lines && orderDetailData[0].lines?.length >= 0) {
            const { lines, ...rest } = orderDetailData[0]
            setOrderList(lines)
            setOrderDetail(rest)
            updateOrderInvoice(lines)
          }
        }

        addingOrder(productAdd.id)
      } else {
        alertify.error(t('add_fail'))
      }
    } catch (err) {
      alertify.error(err?.message || t('add_fail'))
    } finally {
      setLoadingAddProduct(-1)
      setShowAddProduct(false)
      setProductAmountChange(1)
    }
  }
  const handleAddProduct = async (product) => {
    alertify.set('notifier', 'position', 'top-right')
    setShowAddProduct(true)
    setLoadingAddProduct(product.id)
    setProductAdd(product)
  }

  const handleOpenPrintReceipt = () => setShowPrintReceipt(true)
  const handleClosePrintReceipt = () => setShowPrintReceipt(false)
  const handleCloseCompletePayment = () => setShowCompletePayment(false)
  const handleCloseChooseMethod = () => setShowChooseMethod(false)

  const handleChooseMethod = (method) => {
    setMethodSelect(method)
  }
  const handlePayment = async () => {
    setShowChooseMethod(true)
    const paymentMethod = await POSService.getPaymentMethod(posId)
    setPaymentMethod(paymentMethod?.records)
  }

  const handleCloseSession = async () => {
    let delete_ids = []
    orderBySession?.records.forEach((element) => {
      if (element.state === 'draft') {
        delete_ids.push(element.id)
      }
    })
    await POSService.deleteOrder(delete_ids).then(() => {
      closeSesison()
    })
  }

  const closeSesison = async () => {
    const closeSessionData = {
      session_id: sessionId,
      stop_at: currentDateOdoo(),
      state: 'closed',
    }
    await POSService.closeSession(closeSessionData).then(() => {
      POSService.updateSessionCloseDate(closeSessionData).then(() => {
        navigate(`/pos-session`)
      })
    })
    clearPosContext()
    alertify.success(t('close_session_success'))
  }

  const handleComletePaymentWithQR = async () => {
    setShowCompletePayment(true)
    setShowChooseMethod(false)
    alertify.success('Thanh toán thành công')
    setNewOrders([])
    fetchData()
    return true
  }

  const handleCompletePayment = async () => {
    if (!methodSelect) {
      alertify.error('Hãy chọn phương thức thanh toán')
      return false
    }
    if (!selectedCustomer) {
      alertify.error('Vui lòng nhập thông tin khách hàng')
      setShowChooseMethod(false)
      return false
    }

    setShowCompletePayment(true)
    try {
      const total = orderList.reduce((sum, item) => sum + item.price_subtotal_incl, 0)
      const data = {
        session_id: sessionId,
        payment_method_id: methodSelect.id,
        pos_order_id: orderDetail.id,
        amount: total,
        is_change: true,
      }
      await POSService.makePayment(data)
      await POSService.updateOrder(orderDetail.id, {
        state: 'paid',
        amount_paid: total,
        amount_total: total,
      }).then(async (res) => {
        fetchData()
      })

      alertify.success('Thanh toán thành công')
      setNewOrders([])
      return true
    } catch (err) {
      console.log(err)
      alertify.error(err?.message || 'Thanh toán thất bại')
    } finally {
      setShowChooseMethod(false)
    }
  }

  const clearAllProductsOrder = async () => {
    setLoadingOrderDetail(true)
    try {
      const productIds = orderList.map((order) => order.id).join(',')

      await POSService.deleteProductInOrder(productIds)
      setOrderList([])
      updateOrderInvoice([])
      alertify.success('Xóa tất cả thành công')
      return true
    } catch (error) {
      alertify.error('Xóa tất cả thất bại')
      return false
    } finally {
      setLoadingOrderDetail(false)
    }
  }

  const refetchOrderBySession = async () => {
    POSService.getOrderBySession(sessionId).then((res) => {
      setOrderBySession(res)
    })
  }

  const handleDeleteDraftInvoice = async (order_id) => {
    setKeyDelete(order_id)
    setOpenModal(true)
  }

  const handleDeleteDraftInvoiceRestaurant = async (order_id) => {
    setKeyDelete(order_id)
    setOpenModal(true)
    clearPosContext()
  }

  const checkFreeTable = (tableId) => {
    // . If no such order is found, the function returns true, indicating the table is free. Otherwise, it returns false
    let orderOfTable = orderBySession?.records?.find((order) => {
      return (order.table_id.id === tableId || order.table_id.id === false) && order.state === 'draft' ? order : null
    })

    return typeof orderOfTable === 'undefined' ? true : false
  }

  const handleSelectTable = async (tableId) => {
    console.log(tableId, 'tableId')
    console.log(orderBySession, 'orderBySession')
    setSelectedTable(tableId)
    let orderOfTable = orderBySession?.records?.find((order) => {
      return order.table_id.id === tableId && order.state === 'draft' ? order : null
    })

    if (checkFreeTable(tableId)) {
      // await POSService.updateOrder(orderBySession?.records[0]?.id, {
      //   table_id: tableId,
      // }).then((res) => {

      //   onChange(res[0])
      //   POSService.getOrderBySession(sessionId).then((res) => {
      //     setOrderBySession(res)
      //     updatePosContext(tableId, res)
      //   })
      // })
      const bodyNewOrder = {
        session_id: sessionId,
        amount_tax: 0,
        amount_total: 0,
        amount_paid: 0,
        amount_return: 0,
        table_id: tableId,
        sequence_number: orderBySession.length + 1,
        pos_reference: `Order ${generatePosReference(posSession?.id, posSession?.login_number, orderBySession.length + 1)}`,
      }
      await POSService.newOrder(bodyNewOrder).then((res) => {
        onChange(res[0])
        POSService.getOrderBySession(sessionId).then((res) => {
          setOrderBySession(res)
        })
      })
    } else {
      onChange(orderOfTable?.id)
    }
  }

  const handleViewTable = async (tableId, order_by_session) => {
    setSelectedTable(tableId)
    updatePosContext(tableId, order_by_session)
    const draftOrderId = order_by_session?.records?.find((order) => {
      return order.table_id.id === tableId && order.state === 'draft'
    })?.id
    onChange(draftOrderId)
  }

  const handleChangeTable = async (tableId) => {
    console.log('changeeeeeeee')

    if (checkFreeTable(tableId)) {
      await POSService.updateOrder(orderDetail.id, {
        table_id: tableId,
      }).then((res) => {
        onChange(res[0])
        setSelectedTable(tableId)
        // setPosContext({ selected_table: tableId })
        POSService.getOrderBySession(sessionId).then((res) => {
          setOrderBySession(res)
        })
      })
    } else {
      alertify.error(t('order_exit_in_table'))
    }
  }

  const handleClearTableIdForEmptyInvoice = async () => {
    const preparationOrderData = await Promise.all([RestaurantService.getPreparationOrderByPosConfigId(posId)])
    if (preparationOrderData) {
      const preparationIdSet = new Set(preparationOrderData[0]?.records.map((order) => order.pos_order_id))
      if (!preparationIdSet.has(orderDetail.id)) {
        showConfirmationAlertLeaveTableInvoice()
      } else {
        setSelectedTable(null)
        updatePosContext(null, null)
      }
    }
  }

  const handleSearch = (e) => {
    const term = e.target.value
    const filteredProducts = productList.filter((product) => {
      return (
        product.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/đ/g, 'd')
          .replace(/Đ/g, 'D')
          .toLowerCase()
          .includes(term.toLowerCase()) || product.name.toLowerCase().includes(term.toLowerCase())
      )
    })
    setFilteredProducts(filteredProducts)
    setSearchTerm(term)
  }

  const toggleLanguage = async () => {
    let newLang = languages[0].code === lang ? languages[1].code : languages[0].code
    // Split the language code and take the first part (e.g., "vi" from "vi_VN")
    const codeLangLowerCase = newLang.split('_')[0]
    // Update cookies and i18n
    CookieStorage.setLanguage(codeLangLowerCase)
    CookieStorage.setLanguageContext(newLang)
    i18n.changeLanguage(codeLangLowerCase)
    // setPosContext({ order_by_session: orderBySession })
  }

  const processOrder = async () => {
    await RestaurantService.processOrderToPreparation(orderDetail.id).then(async (res) => {
      fetchPreparationOrders()
      await POSService.getOrderBySession(sessionId).then((res) => {
        setOrderBySession(res)
        res?.records?.forEach((order) => {
          const preparationOrderOfOrder = []
          preparationOrders?.map((preparationOrder) => {
            if (order.id === preparationOrder.pos_order_id && checkCompleteOrder(preparationOrder?.order_stage_ids)) {
              preparationOrderOfOrder.push({ ...preparationOrder, table_id: order.table_id.id })
            }
          })
          ordersOfTable?.push({ ...order, preparationOrders: preparationOrderOfOrder })
        })
      })
      alertify.success(t('order_success'))
      setNewOrders([])
    })
  }

  const addingOrder = (orderId) => {
    setNewOrders(
      (prev) =>
        prev.includes(orderId)
          ? prev // do not add item if it exists
          : [...prev, orderId] // Add item if it doesn't exist
    )
    alertify.success(t('add_success'))
  }

  const clearPosContext = () => {
    updatePosContext(null, null)
  }

  const updateCustomerInvoice = async (customerOption) => {
    await POSService.getCustomersById(customerOption?.value || null).then((res) => {
      localStorage.removeItem('reloadProcessed')

      localStorage.setItem(
        'customerPos',
        JSON.stringify({
          timestamp: Date.now(),
          customer: res[0],
          need_reload: true,
        })
      )
      // Dispatch a custom event to ensure immediate update in the same window
      window.dispatchEvent(new Event('localStorageChanged'))
    })
  }

  const updateOrderInvoice = async (orderList, orderId = orderDetail.id) => {
    localStorage.removeItem('reloadProcessed')

    localStorage.setItem(
      'posUpdate',
      JSON.stringify({
        timestamp: Date.now(),
        items: orderList,
        order_id: orderId,
        transactionComplete: false,
        need_reload: true,
      })
    )
    // Dispatch a custom event to ensure immediate update in the same window
    window.dispatchEvent(new Event('localStorageChanged'))
  }

  const handleShowQR = async () => {
    if (!selectedCustomer) {
      alertify.error('Vui lòng nhập thông tin khách hàng')
      setShowChooseMethod(false)
      return
    }
    try {
      const res = await POSService.createVA()
      if (res.data) {
        localStorage.removeItem('reloadProcessed')
        localStorage.setItem(
          'qrPos',
          JSON.stringify({
            show_qr_pos: true,
            order_info: {
              va_account: res.data.va_account_no,
            },
          })
        )
        window.dispatchEvent(new Event('localStorageChanged'))
        return true
      } else {
        localStorage.removeItem('reloadProcessed')
        localStorage.setItem(
          'qrPos',
          JSON.stringify({
            show_qr_pos: false,
          })
        )
        window.dispatchEvent(new Event('localStorageChanged'))
        return false
      }
    } catch (error) {
      console.error('Error creating virtual account:', error)
      localStorage.removeItem('reloadProcessed')
      localStorage.setItem(
        'qrPos',
        JSON.stringify({
          show_qr_pos: false,
        })
      )
      window.dispatchEvent(new Event('localStorageChanged'))
      return false
    }
  }

  const handleAddCustomer = async (customer) => {
    setSelectedCustomer(customer)
    await POSService.updateOrder(orderDetail.id, {
      partner_id: parseInt(customer.value),
    }).then(async (res) => {
      fetchData()
    })
  }

  const checkProductIsOrdered = (product) => {
    return preparationOrderLinesIdOfTable.includes(product.product_id.id)
  }

  return (
    <div>
      <div className='pos-pg-wrapper ms-0'>
        {posConfig.module_pos_restaurant == true && selectedTable == null ? (
          <RestaurantTable
            tableHasOrder={tableHasOrder}
            orderBySession={orderBySession}
            floors={floors}
            posContext={posContext}
            orderList={orderList}
            ordersOfTable={ordersOfTable}
            preparationOrders={preparationOrders}
            handleViewTable={handleViewTable}
            setPreparationOrderLinesIdOfTable={setPreparationOrderLinesIdOfTable}
            handleSelectTable={handleSelectTable}
            isUpdate={false}
            setFloor={setFloor}
            posId={posId}
            posConfigId={posConfig?.id}
            showConfirmationAlertCloseSession={showConfirmationAlertCloseSession}
          />
        ) : (
          <>
            <div className='content pos-design p-0'>
              <div className='btn-row d-sm-flex align-items-center justify-content-between'>
                <div className='col-md-12 col-lg-8 align-items-center'>
                  {/* <div>
                <h2>Scanned barcode : {showBarCode}</h2>

              </div> */}
                  <CustomTab
                    key={productList?.id}
                    activeKeyPos={activeKey}
                    sessionId={sessionId}
                    orderBySession={orderBySession}
                    onChange={onChange}
                    showCompletePayment={showCompletePayment}
                    openModal={openModal}
                    keyDelete={keyDelete}
                    showChooseMethod={showChooseMethod}
                    paymentMethod={paymentMethod}
                    methodSelect={methodSelect}
                    orderList={orderList}
                    orderDetail={orderDetail}
                    selectedTable={selectedTable}
                    posId={posId}
                    posSession={posSession}
                    posContext={posContext}
                    handleComletePaymentWithQR={handleComletePaymentWithQR}
                    handleShowQR={handleShowQR}
                    updateCustomerInvoice={updateCustomerInvoice}
                    setSelectedCustomer={setSelectedCustomer}
                    refetchOrderBySession={refetchOrderBySession}
                    setOrderBySession={setOrderBySession}
                    handleCloseCompletePayment={handleCloseCompletePayment}
                    handleOpenPrintReceipt={handleOpenPrintReceipt}
                    handleCloseChooseMethod={handleCloseChooseMethod}
                    handleCompletePayment={handleCompletePayment}
                    handleChooseMethod={handleChooseMethod}
                    updateOrderInvoice={updateOrderInvoice}
                    setOpenModal={setOpenModal}
                    setOrderList={setOrderList}
                    setOrderDetail={setOrderDetail}
                    setLoadingOrderDetail={setLoadingOrderDetail}
                  />
                </div>
                <div className='col-md-12 col-lg-4 align-items-center justify-content-center'>
                  <div className='btn-row d-sm-flex align-items-center justify-content-between px-0'>
                    <div className='btn-row d-sm-flex align-items-center justify-content-center px-2'>
                      {floors.length > 0 && selectedTable != null && posConfig.module_pos_restaurant == true ? (
                        <>
                          <Link
                            to='#'
                            className='btn btn-success'
                            onClick={(e) => {
                              e.preventDefault()
                              handleClearTableIdForEmptyInvoice()
                            }}
                          >
                            <span className='d-flex align-items-center me-1'>
                              <Table className='feather-16' />
                            </span>
                            {t('view_table')}
                          </Link>
                          <div
                            to='#'
                            className='btn btn-info'
                            data-bs-toggle='modal'
                            data-bs-target='#tableModal'
                            onClick={(e) => {
                              e.preventDefault()
                            }}
                          >
                            <span className='d-flex align-items-center justify-content-center me-1 gap-1'>
                              <RotateCw className='feather-16' />
                              {t('change_table')}
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <Link
                            to='#'
                            className='btn btn-success mb-xs-3'
                            data-bs-toggle='modal'
                            data-bs-target='#orders'
                          >
                            <span className='d-flex align-items-center me-1'>
                              <ShoppingCart className='feather-16' />
                            </span>
                            {t('view_orders')}
                          </Link>
                          <Link to='#' className='btn btn-info'>
                            <span className='d-flex align-items-center me-1'>
                              <RotateCw className='feather-16' />
                            </span>
                            {t('reload')}
                          </Link>
                        </>
                      )}
                    </div>

                    <div className='btn-row d-sm-flex align-items-center justify-content-center px-0'>
                      <div className=''>
                        <button
                          onClick={() => {
                            setIsOn(!isOn)
                            toggleLanguage()
                          }}
                          className={`relative h-8 w-16 rounded-full p-1 transition-colors duration-200 ${isOn ? 'bg-red-400' : 'bg-blue-300'} `}
                        >
                          <div
                            className={`absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition-transform duration-200 ease-in-out ${isOn ? 'translate-x-8' : 'translate-x-0'} `}
                          />
                          <span
                            style={{ bottom: '5px' }}
                            className={`absolute text-sm font-medium ${isOn ? 'left-2 text-white' : 'right-2 text-white'} `}
                          >
                            {isOn ? 'VN' : 'EN'}
                          </span>
                        </button>
                      </div>{' '}
                      <div className='dropdown pos-menu-action'>
                        <Link to='#' className='dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'>
                          <Settings style={{ zIndex: '-1' }} />
                        </Link>
                        <div className='dropdown-menu dropdown-menu-right'>
                          <Link
                            to='#'
                            className='dropdown-item'
                            onClick={(e) => {
                              showConfirmationAlertCloseSession()
                              e.preventDefault()
                            }}
                          >
                            <span className='d-flex align-items-center me-1'>
                              <X className='feather-16' />
                            </span>
                            {t('close_session')}
                          </Link>
                          <Link
                            to='/pos-session'
                            className='dropdown-item'
                            onClick={() => {
                              clearPosContext()
                            }}
                          >
                            <span className='d-flex align-items-center me-1'>
                              <ArrowLeft className='feather-16' />
                            </span>
                            {t('back')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row align-items-start pos-wrapper'>
                <div className='col-md-12 col-lg-8'>
                  <div className='pos-categories tabs_wrapper'>
                    <div className='rounded-md border border-gray-200'>
                      <button
                        className='flex w-full items-center justify-between bg-gray-100 px-4 py-2 text-left hover:bg-gray-200 focus:outline-none'
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <div>
                          <h5 className='text-lg font-semibold'>{t('category')}</h5>
                          <p className='text-sm text-gray-600'>{t('category_below')}</p>
                        </div>
                        <ChevronDownIcon
                          className={`h-5 w-5 transition-transform duration-200 ${isOpen ? 'rotate-180 transform' : ''}`}
                        />
                      </button>
                      {isOpen && (
                        <div className='d-flex justify-content-center gap-2 p-2'>
                          <Link
                            key={posCategories[0].id}
                            to='#'
                            className={`btn ${cateFilter === 0 ? 'btn-info' : 'btn-success'} btn-icon flex-fill`}
                            onClick={(e) => {
                              e.preventDefault()
                              if (cateFilter === 0) {
                                e.preventDefault()
                              } else {
                                handleSelectCate(0)
                                e.preventDefault()
                              }
                            }}
                          >
                            {t('select_all').toUpperCase()}
                          </Link>

                          {posCategories?.map((category) => (
                            <Link
                              key={category.id}
                              to='#'
                              className={`btn ${cateFilter === category?.id ? 'btn-info' : 'btn-success'
                                } btn-icon flex-fill`}
                              onClick={(e) => {
                                e.preventDefault()
                                if (cateFilter === category?.id) {
                                  e.preventDefault()
                                } else {
                                  e.preventDefault()
                                  handleSelectCate(category?.id)
                                }
                              }}
                            >
                              {category?.name}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>

                    <div className='pos-products' style={{ marginTop: '8px' }}>
                      <div className='d-flex align-items-center justify-content-between'>
                        <h5 className='mb-3'>{t('Products')}</h5>
                      </div>
                      <div className='search-input'>
                        <div className='input-blocks d-flex align-items-center justify-content-center'>
                          <input
                            type='search'
                            className='form-control'
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder={t('search')}
                          />
                          {/* <Link className='btn btn-filters ms-auto' onClick={e => {
                            e.preventDefault()
                          }}>

                            {t("search")}
                          </Link> */}
                        </div>
                      </div>

                      <div className='tabs_container'>
                        <div className='tab_content active' data-tab='all'>
                          <div className='scrollable-container'>
                            <div
                              style={{
                                width: '100%',
                                justifyContent: 'flex-start',
                              }}
                              className='row'
                            >
                              {/* use product api */}
                              {loadingProduct ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Loading />
                                </div>
                              ) : (
                                <>
                                  {productList.length > 0 ? (
                                    filteredProducts.map((product) => (
                                      <div
                                        style={{ borderRadius: '4px' }}
                                        key={product.id}
                                        className='col-sm-2 col-md-6 col-lg-3 col-xl-3'
                                      >
                                        <div
                                          style={{ borderRadius: '8px' }}
                                          onClick={() => handleAddProduct(product)}
                                          className='product-info default-cover card'
                                        >
                                          {loadingAddProduct == product.id ? (
                                            <div
                                              style={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <ReactLoading width={'54%'} height={'100%'} type='spin' color='black' />
                                            </div>
                                          ) : (
                                            <>
                                              <Link
                                                to='#'
                                                className='img-bg'
                                                onClick={(e) => {
                                                  e.preventDefault()
                                                }}
                                                style={{
                                                  overflow: 'hidden',
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                  }}
                                                  src={
                                                    product?.image_128
                                                      ? `${product?.image_128}`
                                                      : 'https://zippo.vn/wp-content/uploads/2022/02/no-image.jpeg'
                                                  }
                                                  alt='Products'
                                                />
                                                <span>
                                                  <Check className='feather-16' />
                                                </span>
                                              </Link>
                                            </>
                                          )}
                                          <h6 className='cat-name'>
                                            <Link
                                              to='#'
                                              onClick={(e) => {
                                                e.preventDefault()
                                              }}
                                            >
                                              {product?.categ_id?.display_name}
                                            </Link>
                                          </h6>
                                          <h6 className='product-name'>
                                            <Link
                                              to='#'
                                              onClick={(e) => {
                                                e.preventDefault()
                                              }}
                                            >
                                              {product?.name}
                                            </Link>
                                          </h6>
                                          <div className='d-flex align-items-center justify-content-between price'>
                                            {/* <span>
                                            {product.qty_available} PCS
                                          </span> */}
                                            <p>{formatPrice(product?.list_price, 'VND')}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                        height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      Không có sản phẩm
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-12 col-lg-4 ps-0`}>
                  <aside className='product-order-list' id='invoice-lines'>
                    <div className='head d-flex align-items-center justify-content-between w-100'>
                      <div className=''>
                        {/* {selectedTable && (
                          <div className='info-sp'>
                            <span style={{ color: 'white', backgroundColor: '#16A84F' }}>
                              table # {orderDetail?.table_id?.display_name}
                            </span>
                          </div>
                        )} */}

                        <h5>
                          {t('Order')} # {orderDetail?.id}
                        </h5>
                        <span>
                          {t('Sessions')} : {sessionId}
                        </span>
                      </div>

                      {/* DELETE DRAFT INVOICE */}
                      {orderDetail?.state === 'draft' && selectedTable == null && posContext?.selected_table == null ? (
                        <div className=''>
                          {orderBySession.length > 1 && (
                            <Link
                              onClick={(e) => {
                                handleDeleteDraftInvoice(orderDetail?.id)
                                e.preventDefault()
                              }}
                              className='btn-icon delete-icon confirm-text'
                              to='#'
                            >
                              <Trash2 className='h-6 w-6' />
                            </Link>
                          )}
                        </div>
                      ) : (
                        <div className=''>
                          {orderBySession.length > 1 && (
                            <Link
                              onClick={(e) => {
                                handleDeleteDraftInvoiceRestaurant(orderDetail?.id)
                                e.preventDefault()
                              }}
                              className='btn-icon delete-icon confirm-text'
                              to='#'
                            >
                              <Trash2 className='h-6 w-6' />
                            </Link>
                          )}
                        </div>
                      )}
                    </div>

                    {/* CUSTOMER */}
                    <div style={{ display: 'block' }} className='customer-info block-section'>
                      {orderDetail?.state === 'draft' ? (
                        <div className='input-block d-flex align-items-center'>
                          <div className='flex-grow-1'>
                            <Select
                              options={customers}
                              placeholder={t('customer')}
                              value={selectedCustomer}
                              onChange={(customer) => {
                                handleAddCustomer(customer)
                                updateCustomerInvoice(customer)
                              }}
                            />
                          </div>
                          <Link
                            to='#'
                            className='btn btn-primary btn-icon'
                            data-bs-toggle='modal'
                            data-bs-target='#create-new-customer'
                            onClick={(e) => {
                              e.preventDefault()
                            }}
                          >
                            <UserPlus className='feather-16' />
                          </Link>
                        </div>
                      ) : (
                        <>
                          {' '}
                          <div className='input-block d-flex align-items-center'>
                            <h3>
                              {orderDetail?.partner_id?.display_name} - {orderDetail?.partner_id?.phone}
                            </h3>
                          </div>
                        </>
                      )}
                    </div>

                    {/* ORDER-LIST */}
                    <div className={`product-added block-section`}>
                      {loadingOrderDetail ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <>
                          <div className='head-text d-flex align-items-center justify-content-between'>
                            <h6 className='d-flex align-items-center mb-0'>
                              {t('product_added')}
                              <span className='count'>{orderList.length}</span>
                            </h6>
                            {orderDetail?.state === 'draft' ? (
                              <Link
                                to='#'
                                className='d-flex align-items-center text-danger'
                                onClick={(e) => {
                                  showConfirmationAlertClearAllProductOrder()
                                  e.preventDefault()
                                }}
                              >
                                <span className='me-1'>
                                  <i data-feather='x' className='feather-16' />
                                </span>
                                {t('clear_all')}
                              </Link>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className='product-wrap'>
                            {orderList.map((product) => (
                              <div key={product.id} className='product-list flex items-center justify-between'>
                                <div className='product-info flex items-center'>
                                  <Link
                                    to='#'
                                    onClick={(e) => {
                                      e.preventDefault()
                                    }}
                                    data-bs-toggle='modal'
                                    data-bs-target={`#item-${product.id}`}
                                    className='img-bg'
                                  >
                                    <ImageWithBasePath
                                      src={
                                        product?.product_id?.image_128 ||
                                        'https://zippo.vn/wp-content/uploads/2022/02/no-image.jpeg'
                                      }
                                      alt='Products'
                                    />
                                  </Link>
                                  <div className='info'>
                                    <span>SP {product.product_id.id}</span>
                                    <h6>
                                      <Link
                                        to='#'
                                        data-bs-toggle='modal'
                                        data-bs-target={`#item-${product.id}`}
                                        className='img-bg'
                                        onClick={(e) => {
                                          e.preventDefault()
                                        }}
                                        style={{
                                          color: newOrders.includes(product.product_id.id) ? '#28C76F' : '#092C4C',
                                        }}
                                      >
                                        {product.full_product_name}
                                      </Link>
                                    </h6>
                                    <p>
                                      {formatPrice(product.price_subtotal, product?.currency_id?.display_name || 'VND')}
                                    </p>
                                  </div>
                                </div>
                                <div className='flex flex-col items-center justify-between gap-3'>
                                  <QuantityProduct
                                    key={updateState}
                                    product={product}
                                    order_detail={orderDetail}
                                    checkProductIsOrdered={checkProductIsOrdered}
                                    showConfirmationAlertRemoveOrderInKitchen={
                                      showConfirmationAlertRemoveOrderInKitchen
                                    }
                                    onQuantityChange={handleQuantityChange}
                                    handleOrderDetailsUpdate={handleOrderDetailsUpdate}
                                    addingOrder={addingOrder}
                                  />
                                  {orderDetail?.state === 'draft' ? (
                                    <div className='action flex items-center'>
                                      {/* <Link
                                    className="btn-icon edit-icon mr-2"
                                    to="#"
                                  >
                                    <Edit className="w-3.5 h-3.5" />
                                  </Link> */}
                                      <Link
                                        onClick={(e) => {
                                          showConfirmationAlertDeleteProductOrder(product.id)
                                          e.preventDefault()
                                        }}
                                        className='btn-icon delete-icon confirm-text'
                                        to='#'
                                      >
                                        <Trash2 className='h-3.5 w-3.5' />
                                      </Link>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>

                    {!loadingOrderDetail ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      ></div>
                    ) : (
                      <>
                        {orderDetail?.state === 'draft' ? (
                          <div className='p-4'>
                            <table className='w-full'>
                              <tbody>
                                <tr className='font-bold'>
                                  <td className='py-2'>{t('provisional')}</td>
                                  <td className='text-right'>
                                    {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                                      ? formatPrice(
                                        orderList?.reduce((sum, item) => sum + item.price_subtotal, 0),
                                        orderDetail?.currency_id?.display_name
                                      )
                                      : '0'}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <>
                            {' '}
                            <div className='p-4'>
                              <table className='w-full'>
                                <tbody>
                                  <tr>
                                    <td className='py-2'>Tạm Tính</td>
                                    <td className='text-right'>
                                      {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                                        ? formatPrice(
                                          orderList?.reduce((sum, item) => sum + item.price_subtotal, 0),
                                          orderDetail?.currency_id?.display_name
                                        )
                                        : '0'}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='py-2'>Thuế (VAT)</td>
                                    <td className='text-right'>
                                      {' '}
                                      {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                                        ? formatPrice(
                                          orderList?.reduce(
                                            (sum, item) => sum + (item.price_subtotal_incl - item.price_subtotal),
                                            0
                                          ),
                                          orderDetail?.currency_id?.display_name
                                        )
                                        : '0'}
                                    </td>
                                  </tr>

                                  <tr className='font-bold'>
                                    <td className='py-2'>Tổng</td>
                                    <td className='text-right'>
                                      {' '}
                                      {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                                        ? formatPrice(
                                          orderList?.reduce((sum, item) => sum + item.price_subtotal_incl, 0),
                                          orderDetail?.currency_id?.display_name
                                        )
                                        : '0'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {/* move */}
                    <div>
                      <div className='btn-row d-sm-flex justify-content-between'>
                        {orderDetail?.state === 'draft' && orderList.length > 0 ? (
                          <>
                            {floors.length > 0 && selectedTable != null && (
                              <Link
                                to='#'
                                className='btn btn-info btn-icon flex-fill'
                                onClick={(e) => {
                                  e.preventDefault()
                                  processOrder()
                                }}
                              >
                                <span className='d-flex align-items-center me-1'>
                                  <i data-feather='credit-card' className='feather-16' />
                                </span>
                                {t('order')}
                              </Link>
                            )}

                            <Link
                              to='#'
                              className='btn btn-success btn-icon flex-fill'
                              onClick={(e) => {
                                handlePayment()
                                e.preventDefault()
                              }}
                            >
                              <span className='d-flex align-items-center me-1'>
                                <i data-feather='credit-card' className='feather-16' />
                              </span>
                              {t('checkout')}
                            </Link>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* fixed */}
                  </aside>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* add product amount */}
      <Modal
        show={showAddProduct}
        onHide={handleCloseAddProduct}
        centered
        id='addproduct-pos'
        aria-labelledby='addproduct-pos'
      >
        {' '}
        <Modal.Header>
          <Modal.Title id='ad-product-modal'>{t('receipt_add_product')}</Modal.Title>
          <button
            type='button'
            className='close'
            data-bs-dismiss='modal'
            aria-label='Close'
            onClick={() => {
              handleCloseAddProduct()
            }}
          >
            <span aria-hidden='true'>×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              flexDirection: 'column',
            }}
          >
            <div className='payment-method-custom block-section payment-method'>
              <h6 className='text-center'>Thêm số lượng sản phẩm</h6>
              <div className='row d-flex align-items-center justify-content-center methods'>
                <div className='d-flex justify-center'>
                  <ImageWithBasePath
                    src={productAdd?.image_128 || 'https://zippo.vn/wp-content/uploads/2022/02/no-image.jpeg'}
                    alt='Products'
                  />
                </div>

                <div className='tabs-sets'>
                  <div className='d-flex justify-center'>
                    {' '}
                    <div className='info-sp'>
                      <span>SP {productAdd?.id}</span>
                      <h6>
                        <Link
                          to='#'
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                        >
                          {productAdd?.name}
                        </Link>
                      </h6>
                      <p>{formatPrice(productAdd?.list_price, productAdd?.currency_id?.display_name || 'VND')}</p>
                    </div>
                  </div>

                  <div className='tab-content'>
                    <div className='order-body'>
                      <div className='default-cover mt-4' key={productAdd?.id}>
                        <div className='row'>
                          <div className='qty-item flex items-center justify-around text-center'>
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-minus'>Minus</Tooltip>}>
                              <Link
                                to='#'
                                className='dec flex items-center justify-center'
                                onClick={(e) => {
                                  handleDecrement()
                                  e.preventDefault()
                                }}
                              >
                                <MinusCircle className='h-8 w-8' />
                              </Link>
                            </OverlayTrigger>
                            <input
                              type='text'
                              className='form-control text-center'
                              name='qty'
                              value={productAmountChange}
                              onChange={handleAmountChange}
                              maxLength='8'
                              style={{ width: '240px', height: '40px', fontSize: '24px' }}
                            />
                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-plus'>Plus</Tooltip>}>
                              <Link
                                to='#'
                                className='inc flex items-center justify-center'
                                onClick={(e) => {
                                  handleIncrement()
                                  e.preventDefault()
                                }}
                              >
                                <PlusCircle className='h-8 w-8' />
                              </Link>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer d-sm-flex justify-content-between'>
              <Link
                onClick={(e) => {
                  handleAddProductFinal()
                  e.preventDefault()
                }}
                to='#'
                className='btn btn-secondary flex-fill'
              >
                {t('add_product_order')}
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Print Receipt */}
      <Modal
        show={showPrintReceipt}
        onHide={handleClosePrintReceipt}
        centered
        id='print-receipt'
        aria-labelledby='print-receipt'
      >
        <Modal.Body>
          <div className='d-flex justify-content-end'>
            <button type='button' className='close p-0' onClick={handleClosePrintReceipt} aria-label='Close'>
              <span aria-hidden='true'>×</span>
            </button>
          </div>
          <div className='print-section'>
            <div className='icon-head text-center'>
              <Link
                to='#'
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                <ImageWithBasePath src='assets/img/logoLotte.png' width={100} height={30} alt='Receipt Logo' />
              </Link>
            </div>

            <div className='info text-center'>
              <h6>LOTTE Mart</h6>
              <p className='mb-0'>{t('phone')} : 1900 3353</p>
              <p className='mb-0'>
                Email: <Link to='mailto:hotro247@atomsolution.vn'>hotro247@lottemart.vn</Link>
              </p>
            </div>
            <div className='tax-invoice'>
              <h6 className='text-center'>{t('tax_invoice')}</h6>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <div className='invoice-user-name'>
                    <span>{t('customer')} : </span>
                    <span>{orderDetail?.partner_id?.display_name}</span>
                  </div>
                  <div className='invoice-user-name'>
                    <span>{t('invoice')} : </span>
                    <span>{orderDetail?.id}</span>
                  </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                  <div className='invoice-user-name'>
                    <span>{t('customer_code')} : </span>
                    <span>#{orderDetail?.partner_id?.id}</span>
                  </div>
                  <div className='invoice-user-name'>
                    <span>{t('date')}: </span>
                    <span>{convertToUTC7(orderDetail?.date_order)}</span>
                  </div>
                </div>
              </div>
            </div>
            <table className='table-borderless w-100 table-fit'>
              <thead>
                <tr>
                  <th># {t('item')}</th>
                  <th>{t('product_price')}</th>
                  <th>{t('quantity')}</th>
                  <th className='text-end'>{t('total')}</th>
                </tr>
              </thead>
              <tbody>
                {orderList.length > 0 &&
                  orderList?.map((order) => (
                    <tr key={order.id}>
                      <td>1. {order?.full_product_name}</td>
                      <td>{formatPrice(order.price_subtotal_incl, order?.currency_id?.display_name || 'VND')}</td>
                      <td>{order?.qty}</td>
                      <td className='text-end'>
                        {formatPrice(order.price_subtotal_incl * order?.qty, order?.currency_id?.display_name || 'VND')}
                      </td>
                    </tr>
                  ))}

                <tr>
                  <td colSpan={4}>
                    <table className='table-borderless w-100 table-fit'>
                      <tbody>
                        <tr>
                          <td>{t('total_payable')}:</td>
                          <td className='text-end'>
                            {formatPrice(
                              orderList.reduce((sum, item) => sum + item.price_subtotal_incl, 0),
                              orderList[0]?.currency_id?.display_name || 'VND'
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className='invoice-bar text-center'>
              <p>{t('invoice_thank')}</p>
              <br></br>
              <Link
                to='#'
                onClick={(e) => {
                  e.preventDefault()
                  handlePrint()
                }}
                className='btn btn-primary pt-2'
              >
                {t('print_receipt')}
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className='modal fade' id='tableModal' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='tableModalLabel'>
                {t('select_table')}
              </h5>
              <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <RestaurantTable
                tableHasOrder={tableHasOrder}
                orderBySession={orderBySession}
                floors={floors}
                posContext={posContext}
                orderList={orderList}
                ordersOfTable={ordersOfTable}
                preparationOrders={preparationOrders}
                handleViewTable={handleViewTable}
                setPreparationOrderLinesIdOfTable={setPreparationOrderLinesIdOfTable}
                handleChangeTable={handleChangeTable}
                isUpdate={true}
                setFloor={setFloor}
                posId={posId}
                posConfigId={posConfig?.id}
                showConfirmationAlertCloseSession={showConfirmationAlertCloseSession}
              />
            </div>
          </div>
          o
        </div>
      </div>
      {/* /Print Receipt */}
      {/* Products */}
      {/* <div className='modal fade modal-default pos-modal' id='products' aria-labelledby='products'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header d-flex align-items-center justify-content-between p-4'>
              <div className='d-flex align-items-center'>
                <h5 className='me-4'>{t('Products')}</h5>
                <span className='badge bg-info d-inline-block mb-0'>Order ID : #666614</span>
              </div>
              <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body p-4'>
              <form>
                <div className='product-wrap'>
                  {orderList.map((product) => (
                    <div key={product.id} className='product-list d-flex align-items-center justify-content-between'>
                      <div className='d-flex align-items-center flex-fill'>
                        <Link
                          to='#'
                          onClick={(e) => {
                            e.preventDefault()
                          }}
                          className='img-bg me-2'
                        >
                          <ImageWithBasePath
                            src={
                              product?.product_id?.image_128
                                ? `${product?.product_id?.image_128}`
                                : 'https://www.mobismea.com/upload/iblock/2a0/2f5hleoupzrnz9o3b8elnbv82hxfh4ld/No%20Product%20Image%20Available.png'
                            }
                            alt='Products'
                          />
                        </Link>
                        <div className='info d-flex align-items-center justify-content-between flex-fill'>
                          <div>
                            <span>ID {product.id}</span>
                            <h6>
                              <Link
                                to='#'
                                onClick={(e) => {
                                  e.preventDefault()
                                }}
                              >
                                {product.full_product_name}
                              </Link>
                            </h6>
                          </div>
                          <p>{formatPrice(product.price_subtotal_incl, product?.currency_id?.display_name || 'VND')}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='modal-footer d-sm-flex justify-content-end'>
                  <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                  <Link
                    to='#'
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                    className='btn btn-primary'
                  >
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      {/* CUSTOMER MODAL */}

      <AddCustomerModal
        setCustomers={setCustomers}
        loadOptionsCustomers={loadOptionsCustomers}
        handleAddCustomer={handleAddCustomer}
      />
    </div>
  )
}

export default Pos
