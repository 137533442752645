import React, { useEffect, useState } from 'react'
import { RotateCw, Settings, X } from 'react-feather'
import { useDispatch } from 'react-redux'
import ImageWithBasePath from '../general/ImageCustom'
import useThemeStore from '../../store/theme'
import { setLayoutChange } from '../../util/redux/Action'
import { updateQueryParams } from '../../util/util'
import { SIDEBAR_TYPE } from '../../util/constant/sidebar-type'

const ThemeSettings = () => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)
  const [layoutColor, setlayoutColor] = useState(localStorage.getItem('colorschema'))

  const [layoutView, setLayoutView] = useState(localStorage.getItem('layoutStyling'))

  const [layoutTheme, setLayoutTheme] = useState(localStorage.getItem('layoutThemeColors'))

  const { setLayoutStyling } = useThemeStore()

  // const themeSettingsRef = (() => {
  //   setShow(false)
  // })

  const showSettings = () => {
    setShow(!show)
  }

  // const DarkThemes = () => {
  //   localStorage.setItem('colorschema', 'dark_mode')
  //   setlayoutColor('dark_mode')
  //   document.documentElement.setAttribute('data-layout-mode', 'dark_mode')
  // }

  // const LightThemes = () => {
  //   localStorage.setItem('colorschema', 'light_mode')
  //   setlayoutColor('light_mode')
  //   document.documentElement.setAttribute('data-layout-mode', 'light_mode')
  // }

  const DefaultStyle = () => {
    localStorage.setItem('layoutStyling', SIDEBAR_TYPE.DEFAULT)
    setLayoutView(SIDEBAR_TYPE.DEFAULT)
    dispatch(setLayoutChange(SIDEBAR_TYPE.DEFAULT))
    document.documentElement.setAttribute('data-layout-style', SIDEBAR_TYPE.DEFAULT)
    setLayoutStyling(SIDEBAR_TYPE.DEFAULT)
    updateQueryParams("menu", SIDEBAR_TYPE.DEFAULT)
  }

  // const LayoutBox = () => {
  //   localStorage.setItem('layoutStyling', 'box')
  //   setLayoutView('box')
  //   dispatch(setLayoutChange('box'))
  //   document.documentElement.setAttribute('data-layout-style', 'box')
  // }
  // const collapsedLayout = () => {
  //   localStorage.setItem('layoutStyling', 'collapsed')
  //   setLayoutView('collapsed')
  //   dispatch(setLayoutChange('collapsed'))
  //   document.documentElement.setAttribute('data-layout-style', 'collapsed')
  // }

  const HorizontalLayout = () => {
    localStorage.setItem('layoutStyling', SIDEBAR_TYPE.HORIZONTAL)
    setLayoutView(SIDEBAR_TYPE.HORIZONTAL)
    dispatch(setLayoutChange(SIDEBAR_TYPE.HORIZONTAL))
    document.documentElement.setAttribute('data-layout-style', SIDEBAR_TYPE.HORIZONTAL)
    setLayoutStyling(SIDEBAR_TYPE.HORIZONTAL)
    updateQueryParams("menu", null)
  }

  // const modernLayout = () => {
  //   localStorage.setItem('layoutStyling', 'modern')
  //   setLayoutView('modern')
  //   dispatch(setLayoutChange('modern'))
  //   document.documentElement.setAttribute('data-layout-style', 'modern')
  // }

  // const LayoutGrey = () => {
  //   localStorage.setItem('layoutThemeColors', 'grey')
  //   setLayoutTheme('grey')
  //   document.documentElement.setAttribute('data-nav-color', 'grey')
  // }

  // const LayoutDark = () => {
  //   localStorage.setItem('layoutThemeColors', 'dark')
  //   setLayoutTheme('dark')
  //   document.documentElement.setAttribute('data-nav-color', 'dark')
  // }
  // const LayoutLight = () => {
  //   localStorage.setItem('layoutThemeColors', 'light')
  //   setLayoutTheme('light')
  //   document.documentElement.setAttribute('data-nav-color', 'light')
  // }
  const ResetData = () => {
    localStorage.setItem('colorschema', 'light_mode')
    localStorage.setItem('layoutStyling', SIDEBAR_TYPE.HORIZONTAL)
    localStorage.setItem('layoutThemeColors', 'light')

    setlayoutColor('light_mode')
    setLayoutView(SIDEBAR_TYPE.HORIZONTAL)
    setLayoutTheme('light')

    document.documentElement.setAttribute('data-layout-mode', 'light_mode')
    document.documentElement.setAttribute('data-layout-style', SIDEBAR_TYPE.HORIZONTAL)
    document.documentElement.setAttribute('data-nav-color', 'light')
    updateQueryParams("menu", null)
  }

  useEffect(() => {
    document.documentElement.setAttribute('data-layout-mode', layoutColor)
    document.documentElement.setAttribute('data-layout-style', layoutView)
    document.documentElement.setAttribute('data-nav-color', layoutTheme)
  }, [layoutColor, layoutTheme, layoutView])
  return (
    <>
      <div className='customizer-links hidden' id='setdata'>
        <ul className='sticky-sidebar'>
          <li className='sidebar-icons' onClick={showSettings}>
            <button
              type='button'
              className='navigation-add'
              data-bs-toggle='tooltip'
              data-bs-placement='left'
              data-bs-original-title='Theme'
            >
              <Settings className='feather-five' />
            </button>
          </li>
        </ul>
      </div>

      <div
        className={show ? 'sidebar-settings nav-toggle show-settings' : 'sidebar-settings nav-toggle'}
        id='layoutDiv'
      // onclick="toggleClassDetail()"
      >
        <div className='sidebar-content sticky-sidebar-one'>
          <div className='sidebar-header'>
            <div className='sidebar-theme-title'>
              <h5>Theme Customizer</h5>
              <p>Customize &amp; Preview in Real Time</p>
            </div>
            <div className='close-sidebar-icon d-flex'>
              {/* <Link className="sidebar-refresh me-2" onclick="resetData()"> */}
              <button type='button' className='sidebar-refresh me-2' onClick={ResetData}>
                <RotateCw size={16} color='white' />
              </button>
              <button type='button' className='sidebar-close' to='#' onClick={showSettings}>
                <X size={20} color='white' />
              </button>
            </div>
          </div>
          <div className='sidebar-body p-0'>
            <form id='theme_color' method='post' className='h-full'>
              <div className='theme-mode mb-0 h-full flex flex-col'>
                <div className='theme-body-main'>
                  {/* <div className='theme-head'>
                    <h6>Theme Mode</h6>
                    <p>Enjoy Dark &amp; Light modes.</p>
                  </div>
                  <div className='row'>
                    <div className='col-xl-6 ere'>
                      <div className='layout-wrap'>
                        <div className='d-flex align-items-center'>
                          <div className='status-toggle d-flex align-items-center me-2' onClick={LightThemes}>
                            <input
                              type='radio'
                              name='theme-mode'
                              id='light_mode'
                              className='check color-check stylemode lmode'
                              defaultValue='light_mode'
                              defaultChecked
                            />
                            <label htmlFor='light_mode' className='checktoggles'>
                              <ImageWithBasePath src='assets/img/theme/theme-img-01.jpg' alt='img' />
                              <span className='theme-name'>Light Mode</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-6 ere'>
                      <div className='layout-wrap'>
                        <div className='d-flex align-items-center'>
                          <div className='status-toggle d-flex align-items-center me-2'>
                            <input
                              type='radio'
                              name='theme-mode'
                              id='dark_mode'
                              className='check color-check stylemode'
                              defaultValue='dark_mode'
                            />
                            <label htmlFor='dark_mode' className='checktoggles'>
                              <div onClick={DarkThemes}>
                                <ImageWithBasePath src='assets/img/theme/theme-img-02.jpg' alt='img' />
                              </div>

                              <span className='theme-name'>Dark Mode</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='theme-mode border-0'>
                    <div className='theme-mode mb-0 border-0'>
                      <div className='theme-head'>
                        <h6>Layout Mode</h6>
                        <p>Select the primary layout style for your app.</p>
                      </div>
                      <div className='row'>
                        <div className='col-xl-6 ere'>
                          <div className='layout-wrap'>
                            <div className='d-flex align-items-center'>
                              <div className='status-toggle d-flex align-items-center me-2' onClick={DefaultStyle}>
                                <input
                                  type='radio'
                                  name='layout'
                                  id='default_layout'
                                  className='check layout-mode'
                                  defaultValue='default'
                                  checked={layoutView === SIDEBAR_TYPE.DEFAULT}
                                />
                                <label htmlFor='default_layout' className='checktoggles'>
                                  <ImageWithBasePath src='assets/img/theme/theme-img-01.jpg' alt='img' />
                                  <span className='theme-name'>Default</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='col-xl-6 ere'>
                          <div className='layout-wrap'>
                            <div className='d-flex align-items-center'>
                              <div className='status-toggle d-flex align-items-center me-2' onClick={LayoutBox}>
                                <input
                                  type='radio'
                                  name='layout'
                                  id='box_layout'
                                  className='check layout-mode'
                                  defaultValue='box'
                                />
                                <label htmlFor='box_layout' className='checktoggles'>
                                  <ImageWithBasePath src='assets/img/theme/theme-img-07.jpg' alt='img' />
                                  <span className='theme-name'>Box</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-6 ere'>
                          <div className='layout-wrap'>
                            <div className='d-flex align-items-center'>
                              <div className='status-toggle d-flex align-items-center me-2' onClick={collapsedLayout}>
                                <input
                                  type='radio'
                                  name='layout'
                                  id='collapse_layout'
                                  className='check layout-mode'
                                  defaultValue='collapsed'
                                />
                                <label htmlFor='collapse_layout' className='checktoggles'>
                                  <ImageWithBasePath src='assets/img/theme/theme-img-05.jpg' alt='img' />
                                  <span className='theme-name'>Collapsed</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className='col-xl-6 ere'>
                          <div className='layout-wrap'>
                            <div className='d-flex align-items-center'>
                              <div className='status-toggle d-flex align-items-center me-2' onClick={HorizontalLayout}>
                                <input
                                  type='radio'
                                  name='layout'
                                  id='horizontal_layout'
                                  className='check layout-mode'
                                  defaultValue='horizontal'
                                  checked={layoutView === SIDEBAR_TYPE.HORIZONTAL}
                                />
                                <label htmlFor='horizontal_layout' className='checktoggles'>
                                  <ImageWithBasePath src='assets/img/theme/theme-img-06.jpg' alt='img' />
                                  <span className='theme-name'>Horizontal</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='col-xl-6 ere'>
                          <div className='layout-wrap'>
                            <div className='d-flex align-items-center'>
                              <div className='status-toggle d-flex align-items-center me-2' onClick={modernLayout}>
                                <input
                                  type='radio'
                                  name='layout'
                                  id='modern_layout'
                                  className='check layout-mode'
                                  defaultValue='modern'
                                />
                                <label htmlFor='modern_layout' className='checktoggles'>
                                  <ImageWithBasePath src='assets/img/theme/theme-img-04.jpg' alt='img' />
                                  <span className='theme-name'>Modern</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {/* <div id='nav_color' method='post'>
                      <div className='theme-mode'>
                        <div className='theme-head'>
                          <h6>Navigation Colors</h6>
                          <p>Setup the color for the Navigation</p>
                        </div>
                        <div className='row'>
                          <div className='col-xl-4 ere'>
                            <div className='layout-wrap'>
                              <div className='d-flex align-items-center'>
                                <div className='status-toggle d-flex align-items-center me-2'>
                                  <input
                                    type='radio'
                                    name='nav_color'
                                    id='light_color'
                                    className='check nav-color'
                                    defaultValue='light'
                                  />
                                  <label htmlFor='light_color' className='checktoggles'>
                                    <span className='theme-name' onClick={LayoutLight}>
                                      Light
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-4 ere'>
                            <div className='layout-wrap'>
                              <div className='d-flex align-items-center'>
                                <div className='status-toggle d-flex align-items-center me-2'>
                                  <input
                                    type='radio'
                                    name='nav_color'
                                    id='grey_color'
                                    className='check nav-color'
                                    defaultValue='grey'
                                  />
                                  <label htmlFor='grey_color' className='checktoggles'>
                                    <span className='theme-name' onClick={LayoutGrey}>
                                      Grey
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-xl-4 ere'>
                            <div className='layout-wrap'>
                              <div className='d-flex align-items-center'>
                                <div className='status-toggle d-flex align-items-center me-2'>
                                  <input
                                    type='radio'
                                    name='nav_color'
                                    id='dark_color'
                                    className='check nav-color'
                                    defaultValue='dark'
                                  />
                                  <label htmlFor='dark_color' className='checktoggles'>
                                    <span className='theme-name' onClick={LayoutDark}>
                                      Dark
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='sidebar-footer mt-auto'>
                  <div className='row'>
                    <div className='col-xl-12'>
                      <div className='footer-preview-btn'>
                        <button type='button' className='btn btn-secondary w-100' onClick={ResetData}>
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThemeSettings
