import React, { useState, useRef, useEffect } from 'react'
import { Loader } from 'react-feather'
import { useTranslation } from 'react-i18next'
import useHeaderStore from '../../store/header'
const BranchSelector = ({ currentStore, stores, onStoreChange, onSelectAll }) => {
    const { t } = useTranslation()
    const [selectedStores, setSelectedStores] = useState([])
    const [showTooltip, setShowTooltip] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const selectorRef = useRef(null)
    const { rootContext } = useHeaderStore()
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectorRef.current && !selectorRef.current.contains(event.target)) {
                setShowDropdown(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    useEffect(() => {
        setSelectedStores(rootContext.allowed_company_ids)
    }, [rootContext.allowed_company_ids])

    const handleStoreSelect = (store) => {
        const newSelected = selectedStores.includes(store.id)
            ? selectedStores.filter((id) => id !== store.id)
            : [...selectedStores, store.id]

        setSelectedStores(newSelected)
        onStoreChange?.(store)
    }

    const handleSelectAll = () => {
        const allStoreIds = stores.map((store) => store.id)
        setSelectedStores(selectedStores.length === stores.length ? [] : allStoreIds)
        onSelectAll?.()
    }

    const getSelectedBranchesText = () => {
        const count = selectedStores.length
        return count > 0 ? `${count} ${t('branch_selected')}` : t('select_branch')
    }

    const getSelectedBranchNames = () => {
        return stores
            .filter((store) => selectedStores.includes(store.id))
            .map((store) => store.name)
            .join('\n')
    }

    return (
        <div
            className='relative'
            ref={selectorRef}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <div className='relative inline-block'>
                <button
                    onClick={() => setShowDropdown(!showDropdown)}
                    className='flex items-center gap-2 rounded-md border bg-white px-4 py-2 shadow-sm hover:bg-gray-50'
                >
                    {currentStore?.logo ? (
                        <img src={currentStore.logo} alt='Store Logo' className='h-5 w-5 object-contain' />
                    ) : (
                        <Loader className='h-5 w-5 animate-spin text-red-500' />
                    )}
                    <span className='text-sm font-medium'>{getSelectedBranchesText()}</span>
                </button>

                {/* Custom Tooltip */}
                {showTooltip && selectedStores.length > 0 && (
                    <div
                        style={{ transform: 'translateX(-105%)' }}
                        className='absolute top-0 z-50 mb-2 w-max max-w-xs rounded-md border bg-white p-2 shadow-lg'
                    >
                        <div className='whitespace-pre-line text-sm'>{getSelectedBranchNames()}</div>
                    </div>
                )}
            </div>

            {/* Dropdown Menu */}
            {showDropdown && (
                <div className='absolute left-0 top-full z-40 mt-1 w-64 rounded-md border bg-white shadow-lg'>
                    {stores.map((store) => (
                        <label key={store.id} className='flex cursor-pointer items-center px-4 py-2 hover:bg-gray-50'>
                            <input
                                type='checkbox'
                                className='mr-2'
                                checked={selectedStores.includes(store.id)}
                                onChange={() => handleStoreSelect(store)}
                            />
                            <span className='text-sm text-black'>{store.name}</span>
                        </label>
                    ))}

                    <label className='flex cursor-pointer items-center border-t px-4 py-2 hover:bg-gray-50'>
                        <input
                            type='checkbox'
                            className='mr-2'
                            checked={selectedStores.length === stores.length}
                            onChange={handleSelectAll}
                        />
                        <span className='text-sm text-black'>All</span>
                    </label>
                </div>
            )}
        </div>
    )
}

export default BranchSelector
