import React, { useState } from 'react';

// Utility function for VND price formatting
export const formatVNDPrice = (value) => {
    // Convert to number and handle potential undefined/null
    const numValue = Number(value || 0);

    // Check if it's a valid number
    if (isNaN(numValue)) return '0 VND';

    // Format with commas and add VND
    return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(numValue);
};

const CashInputGroup = ({
    label,
    value,
    onChange,
    name = 'cashbox_value',
    placeholder = ''
}) => {
    const [error, setError] = useState('');
    const [displayValue, setDisplayValue] = useState(value ? value : '');

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Remove formatting characters, keeping only numbers
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Validate input
        if (numericValue.length > 15) {
            setError('Amount is too large');
            return;
        }

        // Clear error if validation passes
        setError('');

        // Update display value
        setDisplayValue(numericValue);

        // Call parent onChange with numeric value
        onChange({
            target: {
                name,
                value: numericValue
            }
        });
    };

    const handleBlur = () => {
        // Ensure display is formatted on blur
        setDisplayValue(value ? formatVNDPrice(value) : '');
    };

    return (
        <div className="space-y-2">
            <label htmlFor={name} className="block mb-2">
                {label}
            </label>
            <div className="flex items-center space-x-4">
                <div className="flex-grow">
                    <input
                        id={name}
                        name={name}
                        type="text"
                        placeholder={placeholder}
                        value={displayValue}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        className={`form-control form-control-lg w-full ${error ? 'border-red-500' : ''}`}
                    />
                    {error && (
                        <p className="text-danger text-sm mt-1">{error}</p>
                    )}
                </div>
                <div className="text-muted">
                    {formatVNDPrice(value || '0')}
                </div>
            </div>

        </div>
    );
};

export default CashInputGroup;