import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import POSService from '../../core/services/POSService'
import ConfirmModal from '../components/modal/ConfirmModal'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatPrice } from '../../core/utils/formatter'
import { useNavigate } from 'react-router-dom'
import { convertToUTC7, generatePosReference } from '../../util/util'
import withReactContent from 'sweetalert2-react-content'
import PaymentComponent from './paymentComponent'
import Swal from 'sweetalert2'
const CustomTab = ({
  orderBySession,
  sessionId,
  activeKeyPos,
  showCompletePayment,
  openModal,
  keyDelete,
  orderList,
  orderDetail,
  paymentMethod,
  selectedTable,
  methodSelect,
  showChooseMethod,
  setOrderBySession,
  posId,
  posSession,
  posContext,
  updateCustomerInvoice,
  handleComletePaymentWithQR,
  refetchOrderBySession,
  handleCompletePayment,
  handleCloseCompletePayment,
  handleOpenPrintReceipt,
  setSelectedCustomer,
  handleShowQR,
  handleCloseChooseMethod,
  handleChooseMethod,
  updateOrderInvoice,
  setOrderList,
  setOrderDetail,
  setLoadingOrderDetail,
  setOpenModal,
}) => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState(activeKeyPos)
  const [items, setItems] = useState([])
  const [hiddenTabIds, setHiddenTabIds] = useState([]) // Track hidden paid invoices
  const navigate = useNavigate()
  const [showQR, setShowQR] = useState(false)

  const MySwal = withReactContent(Swal)

  const showConfirmationAlertCheckout = () => {
    MySwal.fire({
      title: t('confirm_checkout'),

      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: t('confirm_yes'),
      cancelButtonColor: '#ff0000',
      cancelButtonText: t('confirm_no'),
    }).then((result) => {
      if (result.isConfirmed) {
        handleCompletePayment().then((res) => {
          if (res) {
            addNewOrder()
          }
        })
      } else {
        MySwal.close()
      }
    })
  }

  useEffect(() => {
    if (orderBySession.length > 0 && !selectedTable) {
      const paidInvoiceIds = orderBySession?.records
        ?.filter((invoice) => invoice.state === 'paid')
        .map((invoice) => invoice.id)
      setHiddenTabIds(paidInvoiceIds)
      const temp = orderBySession?.records
        ?.filter((invoice) => invoice.state === 'draft')
        .map((invoice) => ({ key: invoice.id, label: `${t('invoice')} ${invoice.id} ` }))
      setActiveKey(temp[0]?.key)
      onChange(temp[0]?.key)

      setItems(temp || [])
    }
    if (activeKeyPos) {
      setActiveKey(activeKeyPos)
    }
  }, [orderBySession, activeKeyPos])

  useEffect(() => {
    const temp = orderBySession?.records
      ?.filter((invoice) => !hiddenTabIds.includes(invoice.id))
      .map((invoice) => ({
        key: invoice.id,
        label: `${t('invoice')} ${invoice.id} `,
      }))
    setItems(temp || [])
  }, [hiddenTabIds, setHiddenTabIds])

  const onChange = async (newActiveKey) => {
    console.log(newActiveKey, 'newActiveKey')
    if (!newActiveKey) {
      return
    }
    setActiveKey(newActiveKey)
    setLoadingOrderDetail(true)
    const orderDetail = await POSService.getOrderDetail(newActiveKey)
    if (orderDetail.length > 0 && orderDetail[0]?.lines && orderDetail[0].lines?.length >= 0) {
      const { lines, ...rest } = orderDetail[0]
      setOrderList(lines)
      setOrderDetail(rest)
      updateOrderInvoice(lines, newActiveKey)
      updateCustomerInvoice(null)
      setSelectedCustomer(null)
    }
    setLoadingOrderDetail(false)
  }

  // Show a specific invoice tab
  const showTabById = (id) => {
    setHiddenTabIds((prev) => prev.filter((tabId) => tabId !== id))
  }

  const addNewOrder = async () => {
    const bodyNewOrder = {
      session_id: sessionId,
      amount_tax: 0,
      amount_total: 0,
      amount_paid: 0,
      amount_return: 0,
      sequence_number: orderBySession.length + 1,
      pos_reference: `Order ${generatePosReference(posSession?.id, posSession?.login_number, orderBySession.length + 1)}`,
    }

    if (selectedTable) {
      bodyNewOrder.table_id = selectedTable
    }

    await POSService.newOrder(bodyNewOrder).then(async (res) => {
      const newActiveKey = res[0]
      const newPanes = [...items]
      await onChange(newActiveKey)
      newPanes.push({
        label: `${t('invoice')} ${newActiveKey}`,
        key: newActiveKey,
      })
      setItems(newPanes)
      setActiveKey(newActiveKey)
    })
    await POSService.getOrderBySession(sessionId).then((res) => {
      setOrderBySession(res)
    })
  }

  const remove = async (targetKey) => {
    let newActiveKey = activeKey
    let lastIndex = -1
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const newPanes = items.filter((item) => item.key !== targetKey)
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key
      } else {
        newActiveKey = newPanes[0].key
      }
    }

    setItems(newPanes)
    setActiveKey(newActiveKey)
    onChange(newActiveKey)
  }

  const handleViewOrder = async (orderId) => {
    onChange(orderId)
  }

  const onEdit = async (targetKey, action) => {
    if (action === 'add') {
      addNewOrder()
    } else {
      remove(targetKey)
    }
  }

  const confirmModal = async () => {
    await POSService.deleteOrder(keyDelete)
    remove(keyDelete)
    setOpenModal(false)
    refetchOrderBySession()
    if (selectedTable) {
      navigate(`/pos/${posId}`)
    }
  }

  let totalPaymentAmount = orderList?.reduce((sum, item) => sum + item.price_subtotal_incl, 0)

  return (
    <>
      {!selectedTable && !posContext.selected_table ? (
        <Tabs
          style={{ marginTop: '10px' }}
          type='editable-card'
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          items={items}
        />
      ) : (
        <Link
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <div className='info-sp' style={{ paddingLeft: '24px' }}>
            <span
              style={{
                color: 'white',
                backgroundColor: '#16A84F',
                height: '40px',
                lineHeight: '40px',
                padding: '0 10px',
              }}
            >
              {t('table')} # {orderDetail?.table_id?.display_name}
            </span>
          </div>
        </Link>
      )}

      <ConfirmModal
        title={t('confirm_delete_draft_invoice')}
        content={t('content_delete_draft_invoice')}
        onOkFunc={confirmModal}
        onCancelFunc={() => setOpenModal(false)}
        open={openModal}
        textCancel={t('cancel')}
        textOk={t('Delete')}
      />

      <Modal
        show={showCompletePayment}
        onHide={handleCloseCompletePayment}
        centered
        id='payment-completed'
        aria-labelledby='payment-completed'
      >
        <Modal.Header>
          <Modal.Title id='payment-method-modal'>{t('complete_payment')}</Modal.Title>{' '}
          <button
            type='button'
            className='close'
            data-bs-dismiss='modal'
            data-bs-target='payment-completed'
            aria-label='Close'
            onClick={handleCloseCompletePayment}
          >
            <span aria-hidden='true'>×</span>
          </button>
        </Modal.Header>

        <Modal.Body className='text-center'>
          <form>
            <div className='flex items-center justify-center text-green-500'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-24 w-24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
                <polyline points='22 4 12 14.01 9 11.01'></polyline>
              </svg>
            </div>
            <div className='modal-footer d-sm-flex justify-content-between'>
              <button type='button' className='btn btn-primary flex-fill me-1' onClick={handleCloseCompletePayment}>
                {t('next_order')}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className='modal fade pos-modal' id='orders' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-md modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header p-4'>
              <h5 className='modal-title'>{t('orders')}</h5>
              <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='modal-body p-4'>
              <div className='tabs-sets'>
                <h3 style={{ marginBottom: '10px' }}>
                  {t('total_orders')} : {orderBySession?.records?.filter((item) => item.state !== 'draft').length}
                </h3>
                <div className='tab-content'>
                  <div className='order-body'>
                    {orderBySession?.records
                      ?.filter((item) => item.state !== 'draft')
                      .map((item) => (
                        <div className='default-cover mb-4 p-4' key={item?.id}>
                          <span className='badge d-inline-block bold mb-4 bg-primary'>
                            {t('Order')} : # {item?.id}
                          </span>
                          <div className='row'>
                            <div className='col-sm-12 col-md-6 record mb-3'>
                              <table>
                                <tbody>
                                  <tr className='mb-3'>
                                    <td>{t('shop')}</td>
                                    <td>:</td>
                                    <td className='text'>{item?.config_id.display_name}</td>
                                  </tr>
                                  <tr>
                                    <td>{t('customer')}</td>
                                    <td>:</td>
                                    <td className='text'>{item?.partner_id.display_name}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className='col-sm-12 col-md-6 record mb-3'>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>{t('date')}</td>
                                    <td>:</td>
                                    <td className='text'>{convertToUTC7(item?.date_order)}</td>
                                  </tr>
                                  <tr>
                                    <td>{t('state')}</td>
                                    <td>:</td>
                                    <td className='text'>{t(`${item?.state}`)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className='btn-row d-flex align-items-center justify-content-between'>
                            <Link
                              to='#'
                              className='btn btn-info btn-icon flex-fill'
                              onClick={(e) => {
                                e.preventDefault()
                                showTabById(item?.id)
                                handleViewOrder(item?.id)
                              }}
                              data-bs-dismiss='modal'
                            >
                              {t('open')}
                            </Link>
                            <Link
                              to='#'
                              className='btn btn-success btn-icon flex-fill'
                              onClick={(e) => {
                                e.preventDefault()
                                handleViewOrder(item?.id)
                                handleOpenPrintReceipt()
                              }}
                            >
                              {t('print')}
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* payment method modal */}
      <Modal
        show={showChooseMethod}
        onHide={handleCloseChooseMethod}
        centered
        id='payment-method-modal'
        aria-labelledby='payment-method-modal'
      >
        <Modal.Header>
          <Modal.Title id='payment-method-modal'>{t('payment_method')}</Modal.Title>
          <button
            type='button'
            className='close'
            data-bs-dismiss='modal'
            aria-label='Close'
            onClick={() => {
              handleCloseChooseMethod()
            }}
          >
            <span aria-hidden='true'>×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mx-auto max-w-2xl space-y-6 rounded-xl bg-white p-6 shadow-lg'>
            <div className='border-b pb-4'>
              <div className='overflow-x-auto'>
                <table className='w-full text-sm'>
                  <tbody>
                    <tr className='border-b'>
                      <td className='py-2 text-gray-600'>{t('provisional')}</td>
                      <td className='py-2 text-end font-semibold'>
                        {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                          ? formatPrice(
                            orderList?.reduce((sum, item) => sum + item.price_subtotal, 0),
                            orderDetail?.currency_id?.display_name
                          )
                          : '0'}
                      </td>
                    </tr>
                    <tr className='border-b'>
                      <td className='py-2 text-gray-600'>{t('tax')}</td>
                      <td className='py-2 text-end font-semibold'>
                        {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                          ? formatPrice(
                            orderList?.reduce(
                              (sum, item) => sum + (item.price_subtotal_incl - item.price_subtotal),
                              0
                            ),
                            orderDetail?.currency_id?.display_name
                          )
                          : '0'}
                      </td>
                    </tr>
                    <tr>
                      <td className='py-2 text-lg font-bold'>{t('total')}</td>
                      <td className='py-2 text-end text-lg font-bold text-green-600'>
                        {orderList?.length > 0 && orderDetail?.currency_id?.display_name
                          ? formatPrice(
                            orderList?.reduce((sum, item) => sum + item.price_subtotal_incl, 0),
                            orderDetail?.currency_id?.display_name
                          )
                          : '0'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className='payment-method-section'>
              <h6 className='mb-4 text-center text-xl font-semibold'>{t('payment_method')}</h6>
              <div className='grid grid-cols-2 gap-4 md:grid-cols-3'>
                {paymentMethod?.length > 0 &&
                  paymentMethod.map((method) => (
                    <div key={method.id} className='col-span-1'>
                      <div
                        onClick={() => {
                          handleChooseMethod(method)
                          setShowQR(false) // Reset QR visibility when method changes
                        }}
                        className={`cursor-pointer rounded-lg border-2 p-3 text-center transition-all duration-300 ${methodSelect && methodSelect?.id === method.id
                          ? 'border-red-500 bg-red-50 text-red-700'
                          : 'border-gray-200 hover:border-red-300 hover:bg-gray-50'
                          } `}
                      >
                        <span className='font-medium'>{t(method?.name)}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* QR Code Button for Bank Payment */}
            {methodSelect && (
              <>
                {' '}
                {methodSelect?.type == 'bank' ? (
                  <PaymentComponent
                    orderId={orderDetail.id}
                    handleShowQR={handleShowQR}
                    addNewOrder={addNewOrder}
                    orderDetail={orderDetail}
                    totalPaymentAmount={totalPaymentAmount}
                    handleComletePaymentWithQR={handleComletePaymentWithQR}
                    posId={posId}
                  />
                ) : (
                  <div className='payment-status-poller rounded-lg border border-gray-200 bg-white p-4'>
                    <Link
                      onClick={async (e) => {
                        e.preventDefault()
                        showConfirmationAlertCheckout()
                      }}
                      to='#'
                      className='block w-full rounded-lg bg-red-500 py-3 text-center font-semibold text-white transition-colors hover:bg-red-600'
                    >
                      {t('checkout')}
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomTab
