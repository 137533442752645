import React from 'react';
import StageOption from './stageOption';
const StageBar = ({ statusOptions, defaultValue, setActiveStage }) => {


  return (
    <div className='flex items-center'>
      {statusOptions?.map((option, index) => {
        const value = option.id === defaultValue

        return (
          <StageOption
            key={option.id}
            isActive={value}
            label={option}
            isFirst={index === 0}
            isLast={index === statusOptions?.length - 1}
            setActiveStage={setActiveStage}
            optionId={option.id}
          />
        )
      })}
    </div>
  )
}

export default StageBar

// const statusOptions1 = [
//   ["backlog", "Backlog"],
//   ["todo", "To Do"],
//   ["inprocess", "In Process"],
//   ["done", "Done"],
// ]
