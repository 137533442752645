import React from 'react'
import { useFormContext } from 'react-hook-form'
import useField from './field'
import RenderWidget from '../widget/RenderWidget'
import PropTypes from 'prop-types'
import useFormStore from '../../store/form'

const FieldView = (props) => {
  const { isModalDetail } = useFormStore()
  const { name, string, onchangeData, isForm, defaultValue, handleOnchange } = props
  const methods = useFormContext()
  const { invisible, required, readonly, nameField } = useField(props)

  const inputElement = RenderWidget({
    ...props,
    required: required,
    readonly: isModalDetail ?? readonly,
    invisible: invisible,
    name: nameField || name,
    methods: methods,
    value: onchangeData?.[name],
    isForm: isForm,
    defaultValue: defaultValue,
    isForm
  })

  if (isForm && (invisible || inputElement === null)) return null

  return (
    <div className={`${isForm && string ? 'min-h-[100px]' : 'min-h-max'} col-span-2`}>
      {isForm && string && (
        <div className='mb-2 flex h-5 gap-[1px]'>
          <span className='text-sm font-medium text-dark'>{string}</span>
          {required && (
            <span style={{ verticalAlign: 'super' }} className='text-sm text-[#DD441A]'>
              *
            </span>
          )}
        </div>
      )}
      {inputElement}
    </div>
  )
}

FieldView.propTypes = {
  name: PropTypes.string,
  invisible: PropTypes.arrayOf(PropTypes.string),
  string: PropTypes.string,
  value: PropTypes.any,
  widget: PropTypes.string,
  onchangeData: PropTypes.object,
  relation: PropTypes.string,
  index: PropTypes.number,
  rootField: PropTypes.object,
  isForm: PropTypes.bool,
}

export default FieldView
