import { CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardTitle } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '../../core/utils/formatter'
import PaymentWaitingScreen from './paymentWaitingScreen'

const PosInvoice = () => {
    const { t } = useTranslation()
    const [items, setItems] = useState([])
    const [transactionComplete, setTransactionComplete] = useState(false)
    const [orderId, setOrderId] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    const [showQr, setShowQr] = useState(false)
    const [orderInfo, setOrderInfo] = useState({})

    console.log(showQr, 'showQr')

    useEffect(() => {
        // Function to handle updates from localStorage
        const handleStorageChange = (event) => {
            // Check if the key we're interested in has changed
            if (event.key === 'posUpdate') {
                try {
                    const posUpdate = localStorage.getItem('posUpdate')
                    if (posUpdate) {
                        console.log(posUpdate)

                        const {
                            items: updatedItems,
                            transactionComplete: completed,
                            order_id: orderId,
                            need_reload: needsReload,
                        } = JSON.parse(posUpdate)

                        // Ensure we're using the latest data
                        setItems(updatedItems)
                        setTransactionComplete(completed || false)
                        setOrderId(orderId)

                        // Check if this is a new update that hasn't been processed
                        if (needsReload && !localStorage.getItem('reloadProcessed')) {
                            // Mark that reload has been processed to prevent multiple reloads
                            localStorage.setItem('reloadProcessed', 'true')

                            // Reload the page
                            window.location.reload()
                        }
                    }
                } catch (error) {
                    console.error('Error parsing localStorage data:', error)
                }
            }

            if (event.key === 'customerPos') {
                try {
                    const customerPos = localStorage.getItem('customerPos')
                    if (customerPos) {
                        const { customer: customer, need_reload: needsReload } = JSON.parse(customerPos)
                        setCustomer(customer)
                        if (needsReload && !localStorage.getItem('reloadProcessed')) {
                            // Mark that reload has been processed to prevent multiple reloads
                            console.log('hello')

                            localStorage.setItem('reloadProcessed', 'true')

                            // Reload the page
                            window.location.reload()
                        }
                    }
                } catch (error) {
                    console.error('Error parsing localStorage data:', error)
                }
            }

            if (event.key === 'qrPos') {
                try {
                    const qrPos = localStorage.getItem('qrPos')
                    if (qrPos) {
                        const { show_qr_pos: showQr, order_info: orderInfo } = JSON.parse(qrPos)
                        setShowQr(showQr)
                        setOrderInfo(orderInfo)
                    }
                } catch (error) {
                    console.error('Error parsing localStorage data:', error)
                }
            }
        }

        // Listen for storage events
        window.addEventListener('storage', handleStorageChange)

        // Also add a direct check method for the same window
        const checkLocalStorage = () => {
            const posUpdate = localStorage.getItem('posUpdate')
            if (posUpdate) {
                try {
                    const { items: updatedItems, transactionComplete: completed, order_id: orderId } = JSON.parse(posUpdate)

                    setItems(updatedItems)
                    setTransactionComplete(completed || false)
                    setOrderId(orderId)
                } catch (error) {
                    console.error('Error parsing localStorage data:', error)
                }
            }
            const customerPos = localStorage.getItem('customerPos')
            if (customerPos) {
                const { customer } = JSON.parse(customerPos)
                setCustomer(customer)
            }
            const qrPos = localStorage.getItem('qrPos')
            if (qrPos) {
                const { show_qr_pos: showQr, order_info: orderInfo } = JSON.parse(qrPos)
                setShowQr(showQr)
                setOrderInfo(orderInfo)
            }
        }
        // Initial load
        checkLocalStorage()
        // Cleanup listener
        return () => {
            window.removeEventListener('storage', handleStorageChange)
        }
    }, [])

    const calculateSubtotal = () => {
        return items.reduce((sum, item) => sum + item.price_subtotal, 0)
    }

    const calculateTax = () => {
        // const subtotal = calculateSubtotal();
        // return subtotal * 0.15; // 15% tax rate
        return items.reduce((sum, item) => sum + (item.price_subtotal_incl - item.price_subtotal), 0)
    }

    const calculateTotal = () => {
        return calculateSubtotal() + calculateTax()
    }

    const orderData = {
        info: {
            amount_total: calculateTotal(),
            des_payment: `THANH TOAN HOA DON MA SO#${orderId}`,
            res_partner_bank_id: {
                acc_number: orderInfo?.va_account,
            },
        },
    }

    const handlePaymentSuccess = () => {
        setPaymentSuccess(true)
        setShowQr(false)
    }

    console.log(orderInfo, 'orderInfo')

    return (
        <div
            className='container mx-auto p-0.5'
            style={{
                position: 'relative',
                width: '25cm',
                height: '16cm',
                maxWidth: '100%',
                maxHeight: '100%',
                fontSize: '0.625rem', // Even smaller base font size
            }}
        >
            {showQr ? (
                <PaymentWaitingScreen orderData={orderData} paymentSuccess={paymentSuccess} />
            ) : (
                <Card className='h-full border border-gray-200 shadow-sm'>
                    <CardHeader className='bg-blue-50 py-0.5'>
                        <CardTitle className='text-3xs text-center font-bold text-blue-800'>LOTTE Mart</CardTitle>
                        <div className='text-3xs text-center text-gray-600'>
                            <p>Email: hotro247@lottemart.vn</p>
                            <p>{t('phone')}: 1900 3353</p>
                        </div>
                    </CardHeader>

                    <CardContent className='space-y-0.5 px-1 py-0.5'>
                        {/* Order Number */}
                        <div className='flex items-center justify-between border-b pb-0.5'>
                            <span className='text-3xs text-gray-600'>{t('orders')} #</span>
                            <span className='text-3xs font-bold text-blue-900'>{orderId}</span>
                        </div>

                        {/* Order Items Table */}
                        <div className='overflow-x-auto'>
                            <table className='text-3xs w-full'>
                                <thead className='bg-gray-100'>
                                    <tr>
                                        <th className='p-0.5 text-left font-medium'>{t('item')}</th>
                                        <th className='p-0.5 text-center font-medium'>{t('quantity')}</th>
                                        <th className='p-0.5 text-right font-medium'>{t('product_price')}</th>
                                        <th className='p-0.5 text-right font-medium'>{t('total')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item) => (
                                        <tr key={item.id} className='border-b last:border-b-0 hover:bg-gray-50'>
                                            <td className='max-w-[80px] truncate p-0.5'>{item.full_product_name}</td>
                                            <td className='p-0.5 text-center'>{item.qty}</td>
                                            <td className='p-0.5 text-right'>
                                                {formatPrice(item?.price_subtotal, item?.currency_id?.display_name || 'VND')}
                                            </td>
                                            <td className='p-0.5 text-right'>
                                                {formatPrice(item.price_subtotal * item.qty, item?.currency_id?.display_name || 'VND')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Customer and Pricing Details */}
                        <div className='mt-0.5 grid grid-cols-2 gap-0.5'>
                            {/* Customer Details */}
                            <div className='space-y-0.5'>
                                <h3 className='text-3xs border-b pb-0.5 font-semibold text-gray-700'>{t('customer_details')}</h3>
                                <div className='text-3xs grid grid-cols-2 gap-0.5'>
                                    <span className='text-gray-600'>{t('customer')}:</span>
                                    <span className='truncate font-medium'>{customer?.name}</span>

                                    <span className='text-gray-600'>{t('phone')}:</span>
                                    <span className='truncate font-medium'>{customer?.phone}</span>

                                    <span className='text-gray-600'>{t('email')}:</span>
                                    <span className='truncate font-medium'>{customer?.email}</span>
                                </div>
                            </div>

                            {/* Pricing Summary */}
                            <div className='space-y-0.5'>
                                <h3 className='text-3xs border-b pb-0.5 font-semibold text-gray-700'>{t('payment_summary')}</h3>
                                <div className='text-3xs grid grid-cols-2 gap-0.5'>
                                    <span className='text-gray-600'>{t('provisional')}:</span>
                                    <span className='text-right'>{formatPrice(calculateSubtotal(), 'VND')}</span>

                                    <span className='text-gray-600'>{t('tax')}:</span>
                                    <span className='text-right'>{formatPrice(calculateTax(), 'VND')}</span>

                                    <span className='font-bold text-gray-700'>{t('total')}:</span>
                                    <span className='text-right font-bold text-blue-900'>{formatPrice(calculateTotal(), 'VND')}</span>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            )}
        </div>
    )
}

export default PosInvoice
