// LocalStorageService.js
export const LocalStorageService = (function () {
    var _service

    function _getService() {
        if (!_service) {
            _service = this
            return _service
        }
        return _service
    }

    function _setToken(tokenData) {
        localStorage.setItem('accessToken', tokenData.access_token)
    }
    function _setStayLoginIn(data) {
        localStorage.setItem('stayLoginIn', data)
    }
    function _setRefreshToken(tokenData) {
        localStorage.setItem('refreshToken', tokenData.refresh_token)
    }
    function _setDBAddress(db_address) {
        localStorage.setItem('dbAddress', db_address)
    }
    function _setIsChangeDBAddress(value) {
        localStorage.setItem('isChangeAddress', value)
    }
    function _getAccessToken() {
        return localStorage.getItem('accessToken')
    }

    function _getRefreshToken() {
        return localStorage.getItem('refreshToken')
    }
    function _getDBAddress() {
        return localStorage.getItem('dbAddress')
    }
    function _getStayLoginIn() {
        return localStorage.getItem('stayLoginIn')
    }
    function _getSchoolAccessToken() {
        return localStorage.getItem('accessToken')
    }

    function _getSchoolRefreshToken() {
        return localStorage.getItem('refreshToken')
    }
    function _setUserID(value) {
        localStorage.setItem('userId', value)
    }
    function _clearToken() {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('googleAccessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userId')
    }

    function _setTokenGoogle(tokenData) {
        if (tokenData) {
            localStorage.setItem('googleAccessToken', tokenData.access_token)
        }
    }

    function _getAccessTokenGoogle() {
        return localStorage.getItem('googleAccessToken')
    }
    function _getIsChangeDBAddress() {
        return localStorage.getItem('isChangeAddress')
    }
    function _getActionBrowser() {
        return sessionStorage.getItem('closeBrowser')
    }
    function _getUserID() {
        return localStorage.getItem('userId')
    }
    return {
        getService: _getService,
        setToken: _setToken,
        setRefreshToken: _setRefreshToken,
        getAccessToken: _getAccessToken,
        getSchoolAccessToken: _getSchoolAccessToken,
        setTokenGoogle: _setTokenGoogle,
        getRefreshToken: _getRefreshToken,
        getAccessTokenGoogle: _getAccessTokenGoogle,
        getSchoolRefreshToken: _getSchoolRefreshToken,
        clearToken: _clearToken,
        setDBAddress: _setDBAddress,
        getDBAddress: _getDBAddress,
        setIsChangeAddress: _setIsChangeDBAddress,
        getIsChangeDBAddress: _getIsChangeDBAddress,
        setStayLoginIn: _setStayLoginIn,
        setUserID: _setUserID,
        getStayLoginIn: _getStayLoginIn,
        getActionBrowser: _getActionBrowser,
        getUserID: _getUserID,
    }
})()
