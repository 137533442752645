import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { useState } from 'react';
import ModalCalendar from '../modal/ModalCalendar';
import { dayInWeek } from '../../util/util';
import useHeaderStore from '../../store/header';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ViewServices from '../../core/services/view-service/view-service';

// generate events
const eventsTest = [
  { title: 'event 1', start: '2024-11-11T10:00:00', end: '2024-11-11T12:00:00' },
  { title: 'event 1', start: '2024-11-11T10:00:00', end: '2024-11-11T12:00:00' },
];

export default function Calendar({ events, tabsData, id, }) {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [date, setDate] = useState(tabsData?.[0])
  const viewData = JSON.parse(sessionStorage.getItem('viewData'))
  const { rootContext, } = useHeaderStore()
  const notebookViewMode = viewData?.views?.form?.notebook_view_mode?.calendar
  const [itemId, setItemId] = useState(null)

  const { data: listView } = useQuery({
    queryKey: [`view-${notebookViewMode?.res_model}`,],
    queryFn: () => ViewServices.getFieldView({
      resModel: notebookViewMode?.res_model,
      views: [notebookViewMode?.views],
      context: rootContext
    }),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleSelect = (info) => {
    setDate(dayInWeek(info.startStr))
    if (listView?.views?.calendar?.quick_create) {
      handleOpenModal()
    }
  };

  const handleEventChange = (info) => {
    setItemId(info?.event?._def?.extendedProps?.itemId)
    handleOpenModal()
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        selectable={true}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        select={info => handleSelect(info)}
        events={events}
        eventClick={(info) => handleEventChange(info)}
      />

      {isOpenModal && <ModalCalendar id={itemId} resModel={notebookViewMode?.res_model} rootContext={rootContext} relationMappingData={{ [notebookViewMode?.related_field]: id }} quickCreateViewId={listView?.views?.calendar?.quick_create_view_id} setIsOpenModal={setIsOpenModal} isOpenModal={isOpenModal} />}
    </>
  );
}