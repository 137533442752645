import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import POSService from '../../core/services/POSService'
const AddCustomerModal = ({ setCustomers, loadOptionsCustomers, handleAddCustomer }) => {
    const { t } = useTranslation()

    const [newCustomerForm, setNewCustomerForm] = useState({
        name: '',
        email: '',
        phone: '',
        contact_address_inline: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target

        setNewCustomerForm((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async () => {
        await POSService.createCustomer(newCustomerForm).then(async res => {

            let newCustomer = {
                value: res[0],
                label: `${newCustomerForm.name} - ${newCustomerForm.phone}`,
            }
            const fetchedCustomerData = await loadOptionsCustomers()
            setCustomers(fetchedCustomerData)
            handleAddCustomer(newCustomer)
        })
        setNewCustomerForm({
            name: '',
            email: '',
            phone: '',
            contact_address_inline: '',
        })


    }

    return (
        <div className='modal fade' id='create-new-customer' tabIndex={-1} aria-labelledby='create' aria-hidden='true'>
            <div className='modal-dialog modal-lg modal-dialog-centered' role='document'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>{t('add_customer')}</h5>
                        <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                            <span aria-hidden='true'>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <form >
                            <div className='row'>
                                <div className='col-lg-6 col-sm-12 col-12'>
                                    <div className='input-blocks'>
                                        <label>{t('customer_name')}</label>
                                        <input
                                            style={{ color: 'black' }}
                                            type='text'
                                            name='name'
                                            className='form-control'
                                            value={newCustomerForm.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-12 col-12'>
                                    <div className='input-blocks'>
                                        <label>Email</label>
                                        <input style={{ color: 'black' }}
                                            type='email'
                                            name='email'
                                            className='form-control'
                                            value={newCustomerForm.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-sm-12 col-12'>
                                    <div className='input-blocks'>
                                        <label>{t('phone')}</label>
                                        <input style={{ color: 'black' }} type='text' name='phone' className='form-control' value={newCustomerForm.phone} onChange={handleChange} />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-12 col-12'>
                                    <div className='input-blocks'>
                                        <label>{t('address')}</label>
                                        <input
                                            style={{ color: 'black' }}
                                            type='text'
                                            name='contact_address_inline'
                                            className='form-control'
                                            value={newCustomerForm.contact_address_inline}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer d-sm-flex justify-content-end'>
                                <Link
                                    to='#'
                                    className='btn btn-submit me-2'
                                    data-bs-dismiss='modal'
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                >
                                    {t('save')}
                                </Link>
                                <button type='button' className='btn btn-cancel' data-bs-dismiss='modal'>
                                    {t('cancel')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCustomerModal
