/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import alertify from 'alertifyjs'
import POSService from '../../core/services/POSService'
const RestaurantCreateFloor = ({ posConfigId, setFloor, posId, updateFloor }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()



  const [newFloorForm, setNewFloorForm] = useState({
    pos_config_ids: [],
    name: '',
  })

  const [editFloorForm, setEditFloorForm] = useState({
    floor_id: 0,
    name: '',
  })



  const handleChange = (e) => {
    const { name, value } = e.target
    setNewFloorForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleUpdate = (e) => {
    const { name, value } = e.target
    setEditFloorForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleCreateNewFloor = async () => {
    alertify.success(t('create_floor_success'))
    newFloorForm.pos_config_ids = [posConfigId]
    await POSService.createFloor(newFloorForm).then(async () => {
      newFloorForm.name = ''
      await POSService.getListPOSById(posId).then((res) => {
        setFloor(res.records[0].floor_ids.sort((a, b) => a.id - b.id))
      })
    })
  }

  const handleUpdateNewFloor = async () => {
    alertify.success(t('update_floor_success'))
    editFloorForm.floor_id = updateFloor?.id
    await POSService.updateFloor(editFloorForm).then(async () => {
      editFloorForm.name = ''
      await POSService.getListPOSById(posId).then((res) => {
        setFloor(res.records[0].floor_ids.sort((a, b) => a.id - b.id))
      })
    })
  }

  return (
    <>
      <div className='modal fade' id='create-new-floor'>
        <div className='modal-dialog sales-details-modal'>
          <div className='modal-content'>
            <div className='page-wrapper-pos fade-in p-0'>
              <div className='content'>
                <div className='modal-header custom-modal-header border-0'>
                  <div className='page-title'>
                    <h4>{t('create_new_floor')}</h4>
                  </div>

                  <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body custom-modal-body'>
                  <div className='row'>
                    <div className='col-lg-12 pe-0'>
                      <div className='form-group mb3'>
                        <label className='mb-2'>{t('floor_name')}</label>
                        <input
                          className='form-control form-control-lg group_formcontrol'
                          id='text'
                          name='name'
                          type='text'
                          value={newFloorForm.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer-btn'>
                    <button type='button' className='btn btn-cancel me-2' data-bs-dismiss='modal'>
                      {t('cancel')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-submit'
                      onClick={() => handleCreateNewFloor()}
                      data-bs-dismiss='modal'
                    >
                      {t('save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='update-floor'>
        <div className='modal-dialog sales-details-modal'>
          <div className='modal-content'>
            <div className='page-wrapper-pos fade-in p-0'>
              <div className='content'>
                <div className='modal-header custom-modal-header border-0'>
                  <div className='page-title'>
                    <h4>{t('edit_floor') + ' ' + updateFloor?.name}</h4>
                  </div>

                  <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                <div className='modal-body custom-modal-body'>
                  <div className='row'>
                    <div className='col-lg-12 pe-0'>
                      <div className='form-group mb3'>
                        <label className='mb-2'>{t('floor_name')}</label>
                        <input
                          className='form-control form-control-lg group_formcontrol'
                          name='name'
                          type='text'
                          placeholder={updateFloor?.name}
                          value={editFloorForm.name}
                          onChange={handleUpdate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer-btn'>
                    <button type='button' className='btn btn-cancel me-2' data-bs-dismiss='modal'>
                      {t('cancel')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-submit'
                      onClick={() => handleUpdateNewFloor()}
                      data-bs-dismiss='modal'
                    >
                      {t('save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RestaurantCreateFloor
