import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../table/view/table-view';
import useTable from '../../table/model/table';
import ModelService from '../../../core/services/model-service/model-service';
import { evalJSONContext, evalJSONDomain, getSpecificationByFields } from '../../../util/util';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Many2ManyView from './many2many-view';
import { useSearchParams } from 'react-router-dom';
import useTableStore from '../../../store/table';
import { useQuery } from '@tanstack/react-query';
import useHeaderStore from '../../../store/header';
import { FIELD_TYPE } from '../../../util/constant/field-type';
import { OBJECT_POSITION } from '../../../util/constant/componen-type';
import { matchDomains } from '../../../core/domain/domain';

const TreeViewForm = ({ viewData, resModel, tab, context, handleOnchange, title, onchangeData }) => {
  const { watch, control } = useFormContext()
  const relation = viewData?.models?.[resModel]?.[tab?.name]?.relation
  const type = viewData?.models?.[resModel]?.[tab?.name]?.type
  const [isModal, setIsModal] = useState(false)
  const [searchParams] = useSearchParams()
  const vid = searchParams.get("vid")
  const { fields } = useTableStore()
  const relationField = viewData?.models?.[resModel]?.[tab?.name]?.relation_field
  const [domainTreeViewForm, setDomainTreeViewForm] = useState((tab?.domain && onchangeData) ? evalJSONDomain(tab?.domain, ModelService.parseORMOdoo(onchangeData)) : [])

  const contextTab = (tab?.context && onchangeData) ? { ...evalJSONContext(tab?.context, { ...onchangeData }), ...evalJSONContext(context, { ...onchangeData }) } : {}
  const objVal = relationField ? { [relationField]: onchangeData } : {}

  const { columns, visibleOptionalFields } = useTable({
    context: onchangeData,
    fields: fields?.[`${vid}_${relation}_tab${tab?.index}`] || tab.fields,
    data: onchangeData?.[tab?.name],
    dataModel: viewData?.models?.[relation],
    model: relation,
    tabIndex: tab?.index
  })
  console.log("🚀 ~ TreeViewForm ~ visibleOptionalFields:", visibleOptionalFields)

  const specification = getSpecificationByFields(tab?.fields, {}, viewData, relation)

  const { append, remove } = useFieldArray({
    control,
    name: tab?.name,
  });
  const fieldArrayValues = watch(tab.name) || [];

  const methods = useFormContext()
  const { dirtyFields, isDirty } = methods.formState
  const { rootContext } = useHeaderStore()

  const handleAddLine = async (id) => {
    try {
      const onchangeReponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: relation,
        specification: specification,
        context: { ...rootContext, ...contextTab },
        object: objVal
      })

      if (onchangeReponse?.value) {
        append(ModelService.toDataJS(onchangeReponse?.value, viewData, relation))
      }
    } catch (err) {
      console.log(err);
    }
  }

  // fetch field can onchange data
  const { data: fieldsOnchange } = useQuery({
    queryKey: [`field-onchange-${relation}`, relation],
    queryFn: () => ModelService.getFieldWithOnchange({ model: relation }),
    refetchOnWindowFocus: false,
  })

  // get default form data
  const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
    try {
      const onchangeReponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: model,
        specification: specification,
        context: context,
        object: objVal,
        fieldChange: fieldChange,
      })
      return onchangeReponse?.value
    } catch (err) {
      console.log(err)
    }
  }

  //handle onchange value
  const handleOnchangeTreeForm = (nameField, value) => {
    const nameItems = nameField.split('.')
    const rootField = nameItems[0]
    const index = parseInt(nameItems[1])
    const name = nameItems[2]
    const data = ModelService.parseORMOdoo(fieldArrayValues[index])
    if (fieldsOnchange?.includes(name) && value !== data?.[name]) {

      const fetchData = async () => {
        //handle dirty fields
        const dirtyFieldTreeForm = dirtyFields?.[rootField][index]
        const obj = { ...objVal, ...data, [name]: value }

        // const fieldChange = Object.entries(dirtyFieldTreeForm)?.map(([key, value]) => {
        //   if (value === true) return key
        // })
        // for (const key of Object.keys(obj)) {
        //   obj[key] = data[key]
        // }
        // obj[name] = value

        // fetch onchange
        const dataOnchange = await fetchOnchange({
          id: data?.id,
          model: relation,
          specification: specification,
          context: { ...rootContext, ...contextTab },
          objVal: obj,
          fieldChange: [name]
        })

        const dataOnchangeJS = ModelService.toDataJS(dataOnchange, viewData, relation)
        if (dataOnchangeJS) {

          Object.keys(dataOnchangeJS).forEach((key) => {
            if ((viewData?.models?.[relation]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[relation]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {

              methods.setValue(`${rootField}.${index}.${key}`, (dataOnchange[key] ??= []).map((item) => {
                const subRelation = viewData?.models?.[relation]?.[key]?.relation
                if (viewData?.models?.[subRelation]) {
                  if (item?.length >= 3) {
                    return ModelService.toDataJS(item[OBJECT_POSITION], viewData, subRelation)
                  } else {
                    return ModelService.toDataJS(item, viewData, subRelation)
                  }
                } else {
                  if (item?.length >= 3) {
                    return item[OBJECT_POSITION]
                  } else {
                    return item
                  }
                }
              }), { shouldDirty: true })
            } else {
              methods.setValue(`${rootField}.${index}.${key}`, dataOnchangeJS[key])
            }
          })
        }
      }
      fetchData()
    }
  }

  return (
    <>
      {isModal ? <Many2ManyView
        title={title}
        tab={tab}
        setIsModal={setIsModal}
        relation={relation}
        domain={domainTreeViewForm}
        setDomain={setDomainTreeViewForm}
        context={contextTab}
        append={append}
      /> :
        <TableView
          viewData={viewData}
          resModel={resModel}
          handleOnchange={handleOnchangeTreeForm}
          rootField={viewData?.models?.[resModel]?.[tab?.name]}
          funcAddLine={handleAddLine}
          funcRemoveLine={remove}
          model={relation}
          isForm={true}
          dataSource={fieldArrayValues}
          columns={columns}
          dataResponseLength={fieldArrayValues.length}
          tabIndex={tab?.index}
          visibleOptionalFields={visibleOptionalFields}
          showFooterTable={!matchDomains(onchangeData, tab?.readonly)}
        />
      }
    </>
  )
}

TreeViewForm.propTypes = {
  viewData: PropTypes.object,
  onchangeData: PropTypes.object,
  resModel: PropTypes.string
}

export default TreeViewForm