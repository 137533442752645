import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StoreService from "../../core/services/company/StoreService";
import useAsync from "../../hooks/useAsync";
import useHeaderStore from "../../store/header";
import BranchSelector from "./BranchSelector";
const SelectStore = () => {

  const dispatch = useDispatch();
  const context = useSelector((state) => state.context_company);

  const { setRootContext } = useHeaderStore()
  const { rootContext } = useHeaderStore()
  // STORE SERVICE
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState({});
  const [selectedStoreList, setSelectedStoreList] = useState([]);
  const { data } = useAsync(
    () => StoreService.getAllowedStore(),
    "allowed-store-list"
  );



  const handleSwitchCompany = async (store) => {
    setSelectedStoreList((selectedStoreList) => {
      const storeExists = selectedStoreList.some(
        (selectedStore) => selectedStore.id === store.id
      );
      if (storeExists) {
        // Remove the store if it exists
        return selectedStoreList.filter(
          (selectedStore) => selectedStore.id !== store.id
        );
      } else {
        // Add the store if it doesn't exist
        return [...selectedStoreList, store];
      }
    });
  };

  const handleSelectAllStore = async () => {
    const allStores = data?.records || [];
    const storedCompanyIds = rootContext.allowed_company_ids || [];
    const newCompanyIds = allStores.map((item) => item.id);

    // Check if all new company ids are already stored
    const AllInContext = newCompanyIds.every((id) =>
      storedCompanyIds.includes(id)
    );

    if (AllInContext) {
      setRootContext({ allowed_company_ids: selectedStoreList.map((item) => item.id) })
    } else {
      setRootContext({ allowed_company_ids: newCompanyIds })
    }
  };

  const fetchCurrentCompany = async () => {
    await StoreService.getUserCompanyByUserId().then((res) => {
      setCurrentStore(res?.records[0]?.company_id);
      setStores(res?.records[0]?.company_ids);
      const checkbox = document.getElementById(
        `checkbox-${res?.records[0]?.company_id.id}`
      );
      if (checkbox) {
        checkbox.checked = true;
      }
      if (selectedStoreList.length === 0) {
        setSelectedStoreList([res?.records[0]?.company_id]);
        setRootContext({ allowed_company_ids: [res?.records[0]?.company_id.id] })
      }
      if (selectedStoreList) {
        setRootContext({ allowed_company_ids: selectedStoreList.map((item) => item.id) })
      }
    });
  };

  useEffect(() => {
    fetchCurrentCompany();
  }, [data, selectedStoreList]);

  return (
    <BranchSelector
      currentStore={currentStore}
      stores={stores}
      onStoreChange={handleSwitchCompany}
      onSelectAll={handleSelectAllStore}
    />
  );
};

export default SelectStore;
