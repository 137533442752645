import { VIETQR, CURRENCY, SERVICE_CODE } from './vietqr.js';
import QRCODE from '@unicube/vietqr/qrcode.js';


const QRCode = (amount, description, accountNumber) => {
    let vietQRdata = new VIETQR();

    vietQRdata.fields.is_dynamic_qr = true;
    vietQRdata.fields.merchant_category = "9999";
    vietQRdata.fields.amount = amount;
    vietQRdata.fields.acq = "970448";
    vietQRdata.fields.merchant_id = accountNumber;
    vietQRdata.fields.service_code = SERVICE_CODE.TO_ACCOUNT;
    vietQRdata.fields.purpose_txn = description;
    vietQRdata.fields.currency = CURRENCY.VND;
    let vietQRData = vietQRdata.builder();
    let qrcode = new QRCODE(0, "M");

    qrcode.addData(vietQRData, "Byte");

    qrcode.make();

    return {
        imgTag: qrcode.createImgTag(),
        qrData: vietQRData,
    };
};

export default QRCode;