import { LocalStorageService } from '../util/lib/LocalStorage';

const useLogoutSubmit = (navigate) => {
  const onLogout = async () => {
    document.documentElement.style.setProperty("--primary", "#ED1C24");
    LocalStorageService.clearToken();
    sessionStorage.removeItem("url_icon")
    sessionStorage.removeItem("parent_icon")
    navigate("/signin");
  }
  return { onLogout }
}

export default useLogoutSubmit
