import { OBJECT_POSITION } from '../../../util/constant/componen-type'
import { callPath, updatePath, uploadFilePath } from '../../../util/constant/endpoint'
import { FIELD_TYPE } from '../../../util/constant/field-type'
import requests from '../AxiosClient'

const ModelService = {
  getAll: async ({ resModel, ids = [], specification = {}, domain = [], offset, sort = '', fields, groupby, context = {} }) => {
    const jsonReadGroup = fields && fields.length > 0 && groupby && groupby.length > 0 && groupby[0] ? {
      fields,
      groupby
    } : {
      count_limit: 10001,
      order: sort,
      specification: specification,
    }

    const jsonData = {
      model: resModel,
      method: jsonReadGroup.fields && jsonReadGroup.groupby ? "web_read_group" : 'web_search_read',
      ids: ids,
      with_context: context,
      kwargs: {
        domain: domain,
        limit: 10,
        offset: offset,
        ...jsonReadGroup
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  saveForm: async ({ resModel, ids = [], data = {}, specification = {}, context = {} }) => {
    const jsonData = {
      model: resModel,
      method: 'web_save',
      with_context: context,
      ids: ids,
      kwargs: {
        vals: data,
        specification: specification,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  deleteByForm: async ({ ids = [], model }) => {
    const jsonData = {
      model: model,
      method: 'unlink',
      ids: ids,
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  updateForm: async ({ model, body, id }) => {
    const jsonData = {
      model: model,
      domain: [['id', '=', id]],
      values: body,
    }
    return requests.post(updatePath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getDetailData: async ({ ids = [], model, specification, context }) => {
    const jsonData = {
      model: model,
      method: 'web_read',
      ids: ids,
      with_context: context,
      kwargs: {
        specification: specification,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  setImage: async (body, queryParams) => {
    const query = new URLSearchParams(queryParams).toString()
    return requests.post(uploadFilePath + '?' + query, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  onChangeForm: async ({ id = [], model, object, specification, context, fieldChange }) => {
    const jsonData = {
      model: model,
      method: 'onchange',
      ids: id,
      with_context: context,
      args: [object ? object : {}, fieldChange ? fieldChange : [], specification],
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  webRead: async ({ id = [], model, specification }) => {
    const jsonData = {
      model: model,
      method: 'web_read',
      ids: id,
      kwargs: {
        specification
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getFieldWithOnchange: async ({ model }) => {
    const jsonData = {
      model: model,
      method: 'get_fields_onchange',
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  parseORMOdoo: (data) => {
    for (const key in data) {
      if (!data[key] && data[key] !== 0) {
        data[key] = false
      } else if (data[key] === 'Draft') {
        data[key] = '/'
      }
    }
    return { ...data }
  },
  toDataJS: (data, viewData, resModel) => {
    for (const key in data) {
      if (data[key] === false) {
        if (viewData && resModel) {
          if (viewData?.models?.[resModel]?.[key]?.type !== FIELD_TYPE.BOOLEAN) {
            data[key] = null
          }
        } else {
          data[key] = null
        }
      } else if (data[key] === '/') {
        data[key] = 'Draft'
      } else if (data[key] !== false) {
        if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
          data[key] = (data[key] ??= [])?.map((item) => {
            const relation = viewData?.models?.[resModel]?.[key]?.relation
            if (viewData?.models?.[relation]) {
              console.log("🚀 ~ data[key]= ~ relation:", key, item[OBJECT_POSITION])

              if (item?.length >= 3) {
                return ModelService.toDataJS(item[OBJECT_POSITION], viewData, relation)
              } else {
                return ModelService.toDataJS(item, viewData, relation)
              }
            } else {
              if (item?.length >= 3) {
                return item[OBJECT_POSITION]
              } else {
                return item
              }
            }
          })
        }
      }
    }

    return { ...data }
  },
}

export default ModelService
