import React, { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useLoginSubmit from '../../hooks/useLoginSubmit'
import { SidebarContext } from '../../util/context/SidebarContext'
import ImageWithBasePath from '../../components/general/ImageCustom'
import ButtonLoading from '../../components/general/ButtonLoading'
import { LocalStorageService } from '../../util/lib/LocalStorage'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import ProviderService from '../../core/services/provider-service'
import alertify from 'alertifyjs'
import useHeaderStore from '../../store/header'
import Loader from "react-js-loader"

const Signin = () => {
  localStorage.setItem("layoutStyling", "horizontal")
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [isRemember, setIsRemember] = useState(true)
  const location = useLocation()
  let navigate = useNavigate()
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const { onSubmit } = useLoginSubmit({
    navigate: navigate,
    isRemember: isRemember,
  })
  const { isLoading, setIsLoading } = useContext(SidebarContext)
  const { isLoadingOAuth, setLoadingOAuth } = useHeaderStore()

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState)
  }
  const handleRemember = () => {
    !isRemember ? setIsRemember(true) : setIsRemember(false)
  }

  useEffect(() => {
    setIsLoading(false)
    const hasAccessToken = () => {
      const token = LocalStorageService.getAccessToken()
      return !!token
    }
    if (hasAccessToken()) {
      navigate('/sales-dashboard')
    }
  }, [])

  const parseAuthURL = (url) => {
    // Tách URL thành các phần
    const urlObj = new URL(url)

    // Parse state từ hash (vì nó nằm trong phần hash của URL)
    const hashParams = new URLSearchParams(urlObj.hash.substring(1))

    // Decode state object (là một JSON string được encode)
    const stateStr = hashParams.get('state')
    const stateObj = stateStr ? JSON.parse(decodeURIComponent(stateStr)) : null

    // Lấy các params khác
    const params = {
      // Base URL info
      baseUrl: `${urlObj.protocol}//${urlObj.host}`,
      pathname: urlObj.pathname,

      // State object
      state: {
        d: stateObj?.d, // "edu"
        p: stateObj?.p, // 3
        r: stateObj?.r, // "http://localhost:3000/edu/login"
      },

      // Auth params
      access_token: hashParams.get('access_token'),
      token_type: hashParams.get('token_type'),
      expires_in: parseInt(hashParams.get('expires_in')),
      scope: decodeURIComponent(hashParams.get('scope')),
      authuser: hashParams.get('authuser'),
      prompt: hashParams.get('prompt'),
    }

    // Tách scope thành array
    const scopes = params.scope.split(' ')

    return {
      ...params,
      scopes,

      // Helper methods
      getBaseInfo: () => ({
        baseUrl: params.baseUrl,
        pathname: params.pathname,
      }),

      getStateInfo: () => params.state,

      getAuthInfo: () => ({
        access_token: params.access_token,
        token_type: params.token_type,
        expires_in: params.expires_in,
        authuser: params.authuser,
        prompt: params.prompt,
      }),

      getScopeInfo: () => ({
        rawScope: params.scope,
        scopeArray: scopes,
      }),
    }
  }
  const parsedURL = parseAuthURL(window.location.href)

  const handleLoginOauth = async () => {
    const access_token = parsedURL.access_token
    const state = parsedURL.state
    setLoadingOAuth(true)
    await ProviderService.getToken({ access_token, state }).then((res) => {
      LocalStorageService.setToken(res)
      LocalStorageService.setRefreshToken(res)
      setLoadingOAuth(false)
      window.location.href = `${window.location.origin}/sales-dashboard`
    }).catch((err) => {
      if (err?.message === 'Access Denied') {
        alertify.error(t("Tài khoản chưa được liên kết"))
      } else {
        alertify.error(err?.message)
      }
      setLoadingOAuth(false)
    })
  }

  useEffect(() => {
    if (parsedURL.access_token) {
      handleLoginOauth()
    }
  }, [parsedURL])

  const redirectAuthUrl = (provider) => {
    const hostname = window.location.hostname
    const dbName = hostname?.split('.')[0]
    if (provider) {
      const urlRedirect = window.location.origin + window.location.pathname
      const encodedRedirectURL = encodeURIComponent(`${urlRedirect}`);
      const state = {
        d: `${dbName}`,
        p: provider.id,
        r: window.location.origin,
      }
      const stateJson = encodeURIComponent(JSON.stringify(state))
      window.location.href = `${provider.auth_endpoint}?response_type=token&client_id=${provider.client_id}&redirect_uri=${encodedRedirectURL}&scope=${provider.scope}&state=${stateJson}`
    }
  }

  const { data: providersResponse, isLoading: isLoadingProviders } = useQuery({
    queryKey: ['providers'],
    queryFn: () => {
      return ProviderService.getListProviders()
    },
    refetchOnWindowFocus: false
  })
  const providers = providersResponse?.data || []

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center"
        >
          <ImageWithBasePath
            src={'assets/img/main-bg.png'}
            className="object-cover w-[548px] h-[576px] hidden md:block"
          />
          <div className="flex flex-col gap-[28px] bg-white p-10 shadow-lg rounded-md w-[479px] h-[576px]">
            <div className='w-full flex justify-center mb-[15px]'>
              <ImageWithBasePath
                src={'assets/img/logoLotte.png'}
                className="object-contain  w-[230px] h-auto"
              />
            </div>
            <div className='flex flex-col gap-[18px]'>
              <div className='flex flex-col gap-2'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  Tên đăng nhập
                </label>
                <input type="text"   {...register('email')} className='border-[1px] border-solid w-full border-[rgba(217,217,217,1)] py-[10px] pl-4 text-[14px] font-normal rounded' placeholder='Nhập tên dăng nhập' />
              </div>
              <div className='flex flex-col gap-2'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  Mật khẩu
                </label>
                <div className='relative'>
                  <input type={isPasswordVisible ? 'text' : 'password'}
                    {...register('password')}
                    className='border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[10px] pl-4 text-[14px] font-normal rounded' placeholder='Nhập mật khẩu' />
                  <div onClick={togglePasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!isPasswordVisible ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
              </div>
              <div className='flex justify-between items-center'>
                <div className='flex gap-3 items-center'>
                  <label
                    className={`group cursor-pointer rounded-[4px] ${!isRemember && 'h-[20px] w-[20px] border-[2px] border-[#D9D9D9]'
                      }`}
                  >
                    <div className='flex size-5 items-center justify-center rounded-[4px] transition-all group-has-[:checked]:border-[rgba(15,48,112,1)] group-has-[:checked]:bg-[rgba(15,48,112,1)] group-has-[:checked]:opacity-100'>
                      <ImageWithBasePath
                        src={'assets/img/authentication/tick.svg'}
                        className="object-contain w-[13px] h-[13px]"
                      />
                    </div>
                    <input
                      type='checkbox'
                      onChange={() => handleRemember()}
                      checked={isRemember}
                      width={20}
                      className='hidden cursor-pointer'
                      height={20}
                    />
                  </label>
                  <div className='text-[rgba(15,48,112,1)] font-medium text-[14px]'>Lưu tài khoản</div>
                </div>
                <button type="button" onClick={() => navigate('/forgot-password')} className='underline text-[rgba(15,48,112,1)] font-medium text-[14px]'>Quên mật khẩu?</button>
              </div>
            </div>
            <ButtonLoading loading={isLoading} content={'Đăng nhập'} className='text-[16px] rounded font-semibold py-[12px] text-[rgba(255,255,255,1)] !bg-[#FF505B]' />
            <div style={{ gap: '10px' }} className='flex flex-col'>
              <div
                style={{
                  gap: '10px',
                  color: '#6E6E6E',
                }}
                className='flex items-center '
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='182'
                  height='1'
                  viewBox='0 0 182 1'
                  fill='none'
                >
                  <path d='M182 0.5H0' stroke='#D9D9D9' />
                </svg>
                <span
                  style={{
                    fontSize: '14px',
                  }}
                  className=' font-medium'
                >
                  {t('or')}
                </span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='182'
                  height='1'
                  viewBox='0 0 182 1'
                  fill='none'
                >
                  <path d='M182 0.5H0' stroke='#D9D9D9' />
                </svg>
              </div>
              {
                providers?.map((provider) => {
                  return <button button
                    type='button'
                    // style={{
                    //   gap: '8px',
                    //   borderRadius: '10px',
                    //   border: '1px solid #E3E3E3',
                    //   backgroundColor: '#fff',
                    //   fontSize: '16px',
                    //   fontWeight: 'bold',
                    //   color: '#232323',
                    //   padding: '16px',
                    // }}
                    onClick={() => redirectAuthUrl(provider)}
                    className='flex gap-2 w-full border border-[#E3E3E3] items-center justify-center text-[16px] text-black rounded font-semibold py-[12px]'
                  >
                    {isLoadingOAuth ? <Loader
                      type="ping-cube"
                      color="#ED1C24"
                      bgColor="#ED1C24"
                      size={30}
                    /> :
                      <>
                        {t(`Login with`)} {provider?.name}
                        {provider?.name.toLowerCase() === 'atom' && <ImageWithBasePath width={20} height={20} src={'assets/logo/logo-atom.svg'} />}
                      </>
                    }
                    {/* <svg
                      width='18'
                      height='17'
                      viewBox='0 0 18 17'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g id='Group'>
                        <path
                          id='Shape'
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M17.16 8.69176C17.16 8.08904 17.1059 7.50949 17.0055 6.95312H9V10.2411H13.5746C13.3775 11.3036 12.7786 12.2038 11.8784 12.8065V14.9393H14.6255C16.2327 13.4595 17.16 11.2804 17.16 8.69176Z'
                          fill='#4285F4'
                        />
                        <path
                          id='Shape_2'
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M8.99997 16.9983C11.295 16.9983 13.2191 16.2372 14.6254 14.939L11.8784 12.8062C11.1173 13.3162 10.1436 13.6176 8.99997 13.6176C6.78611 13.6176 4.91224 12.1224 4.24383 10.1133H1.40405V12.3156C2.80269 15.0935 5.67724 16.9983 8.99997 16.9983Z'
                          fill='#34A853'
                        />
                        <path
                          id='Shape_3'
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M4.24387 10.1159C4.07387 9.60587 3.97728 9.0611 3.97728 8.50087C3.97728 7.94064 4.07387 7.39587 4.24387 6.88587V4.68359H1.40409C0.82841 5.8311 0.5 7.12928 0.5 8.50087C0.5 9.87247 0.82841 11.1706 1.40409 12.3182L4.24387 10.1159Z'
                          fill='#FBBC05'
                        />
                        <path
                          id='Shape_4'
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M8.99997 3.38069C10.2479 3.38069 11.3684 3.80955 12.2493 4.65183L14.6873 2.21387C13.2152 0.842274 11.2911 0 8.99997 0C5.67724 0 2.80269 1.90478 1.40405 4.68273L4.24383 6.88501C4.91224 4.87592 6.78611 3.38069 8.99997 3.38069Z'
                          fill='#EA4335'
                        />
                      </g>
                    </svg> */}
                  </button>
                })
              }
            </div>
          </div>
        </form>
      </div >
    </div >
  )
}

export default Signin
