import React from 'react'
import ImageWithBasePath from '../../general/ImageCustom'
import useTableStore from '../../../store/table'
import useFormStore from '../../../store/form'
import { useTranslation } from 'react-i18next'
import ActionService from '../../../core/services/actions'
import { showErrorMessage, showSuccessMessage } from '../../../util/util'

const ActionCheckbox = (props) => {
  const { viewDataStore } = useFormStore()
  const { dataToolbar, openModal, setOpenImport, setOpenModal, model, funcRefetch } = props
  const { selectedRowKeys, setSelectedRowKeys } = useTableStore()
  const { t } = useTranslation()
  const handleClick = (id) => {
    if (id == openModal) {
      setOpenModal(0)
    } else {
      setOpenModal(id)
    }
    setOpenImport(false)
  }

  const rootActions = viewDataStore?.views?.form?.toolbar?.root

  const handleActionCheckBox = async (actionName) => {
    try {
      if (actionName === 'delete') {
        await ActionService.removeRows({ model: model, ids: selectedRowKeys })
        showSuccessMessage(t('success_delete'))
      } else if (actionName === 'duplicate') {
        await ActionService.duplicateModel({ model: model, ids: selectedRowKeys })
        showSuccessMessage(t('successs_duplicate'))
      }
      funcRefetch()
    } catch (error) {
      showErrorMessage(actionName === "delete" ? t('fail_delete') : t("fail_duplicate"))
    } finally {
      setOpenModal(0)
      setSelectedRowKeys([])
    }
  }

  const listRootActions = {
    duplicate: {
      icon: 'assets/img/icons/copy.svg',
      default: true,
    },
    read: {
      icon: 'assets/img/icons/export.svg',
      default: true,
    },
    delete: {
      icon: 'assets/img/icons/delete3.svg',
      default: true,
    },
    edit: {
      icon: 'assets/img/icons/edit-5.svg',
    },
    import: {
      icon: 'assets/img/icons/import.svg',
    },
    create: {
      icon: 'assets/img/icons/create.svg',
    },
  }
  const listDefaultActionsKey = Object.keys(listRootActions)

  return (
    <div className={`flex items-center gap-[8px] !order-3 xl:!order-2 min-w-full xl:min-w-max flex-1`}>
      <div className='flex items-center gap-[8px] rounded-[8px] bg-[#d4ebfa] px-[16px] py-[8px] lowercase whitespace-nowrap min-w-max'>
        {selectedRowKeys?.length} {t("selected")}
        <button onClick={() => setSelectedRowKeys([])}>
          <ImageWithBasePath src='assets/img/close-icon.svg' alt='close icon' className='h-4' />
        </button>
      </div>
      <div
        onClick={() => handleClick(1)}
        className='relative flex cursor-pointer items-center gap-[8px] rounded-[8px] bg-[#dee2e6] px-[16px] py-[8px] whitespace-nowrap min-w-max'
      >
        <ImageWithBasePath src='assets/img/print.svg' alt='setting' className='h-4' />
        {t('print')}
        {openModal == '1' && (
          <div
            onClick={(e) => e.stopPropagation()}
            className='absolute left-[-190px] top-[37px] z-[5] flex h-max max-h-[400px] w-[280px] flex-col gap-[6px] overflow-hidden rounded-[8px] border-[1px] border-solid border-[#ebeaea] bg-[#fff] shadow-sm'
          >
            <div className='h-full w-full overflow-y-scroll'>
              {dataToolbar?.print.map((item) => {
                return (
                  <div key={item.id} className='px-[24px] py-[12px] hover:bg-[#f6f4f4]'>
                    {item.name}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => handleClick(2)}
        className='relative flex cursor-pointer items-center rounded-[8px] bg-[#dee2e6] px-[16px] py-[8px] whitespace-nowrap min-w-max gap-2'
      >
        <ImageWithBasePath src='assets/img/setting.svg' className='h-4 w-4' alt='setting' />
        {t("action")}
        {openModal == 2 && (
          <div
            onClick={(e) => e.stopPropagation()}
            className='absolute left-0 top-[42px] z-[5] flex h-max max-h-[400px] w-[280px] flex-col gap-[6px] overflow-hidden rounded-[8px] border-[1px] border-solid border-[#ebeaea] bg-[#fff] shadow-sm'
          >
            <div className='h-full w-full overflow-y-scroll'>
              {listDefaultActionsKey?.length > 0 &&
                listDefaultActionsKey?.map(
                  (actionKey, index) =>
                    listRootActions[actionKey]?.default && (
                      <button
                        onClick={() => handleActionCheckBox(actionKey)}
                        key={`action-key-${index}`}
                        className='flex w-full items-center gap-2 px-[24px] py-[10px] text-left hover:bg-[#f6f4f4]'
                        type='button'
                      >
                        <ImageWithBasePath src={listRootActions[actionKey]?.icon} className='opacity-65' />
                        <span className='text-sm font-normal'>{t(actionKey)}</span>
                      </button>
                    )
                )}
              {props?.dataToolbar?.action?.map((item) => {
                return (
                  <div key={item.id} className='px-[24px] py-[10px] text-left hover:bg-[#f6f4f4]'>
                    {item.name}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ActionCheckbox
