import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableHead from './table-head'
import TableBody from './table-body'
import TableFooter from './table-footer'
import ResizableHeader from './ResizableHeader'
import TableFilter from './table-filter'
import { COMPONENT_TYPE } from '../../../util/constant/componen-type'

const TableView = ({
  dataSource,
  columns,
  visibleOptionalFields,
  model,
  isForm = false,
  funcAddLine,
  rootField,
  dataResponseLength,
  funcRemoveLine,
  handleOnchange,
  viewData,
  resModel,
  tabIndex,
  type,
  append,
  typeTable,
  specification,
  showCheckBox = true,
  typeCo,
  showFooterTable = true
}) => {
  const initialColumnWidths = useMemo(() => columns?.map(() => 50), [columns])
  const [columnWidths, setColumnWidths] = useState(initialColumnWidths)

  useEffect(() => {
    setColumnWidths(initialColumnWidths)
  }, [columns, initialColumnWidths])

  const handleResize = useCallback(
    (index) =>
      (e, { size }) => {
        setColumnWidths((prev) => {
          const newWidths = [...prev]
          newWidths[index] = size.width
          return newWidths
        })
      },
    []
  )
  const memoizedColumnWidths = useMemo(() => columnWidths, [columnWidths])
  
  return (
    <>
      <div className='w-full max-w-full overflow-auto'>
        {memoizedColumnWidths && (
          <table className='relative w-full bg-white custom-dropdown'>
            <TableHead
              model={model}
              isForm={isForm}
              columns={columns}
              dataSource={dataSource}
              visibleOptionalFields={visibleOptionalFields}
              columnWidths={memoizedColumnWidths}
              handleResize={handleResize}
              ResizableHeader={ResizableHeader}
              tabIndex={tabIndex}
              type={type}
              showCheckBox={showCheckBox}
              typeCo={typeCo}
            />
            <TableBody
              viewData={viewData}
              resModel={resModel}
              model={model}
              columns={columns}
              isForm={isForm}
              dataSource={dataSource}
              rootField={rootField}
              dataResponseLength={dataResponseLength}
              columnWidths={memoizedColumnWidths}
              funcRemoveLine={funcRemoveLine}
              handleOnchange={handleOnchange}
              visibleOptionalFields={visibleOptionalFields}
              append={append}
              type={type}
              typeTable={typeTable}
              specification={specification}
              level={0}
              showCheckBox={showCheckBox}
              typeCo={typeCo}
            />
            {isForm && typeCo !== COMPONENT_TYPE.TREE && dataResponseLength > 0 && <div className='h-[60px]' />}
            {isForm && typeCo !== COMPONENT_TYPE.TREE && showFooterTable && <TableFooter columns={columns} funcAddLine={funcAddLine} />}
          </table>
        )}
      </div>
      <TableFilter model={model} tabIndex={tabIndex} type={type} visibleOptionalFields={visibleOptionalFields} />
    </>
  )
}

export default TableView
