import { create } from 'zustand'

const useSidebar = create((set) => ({
  isToggle: false,
  menuList: {},
  setToggle: () => set((state) => ({ isToggle: !state.isToggle })),
  setIsToggle: (newIsToggle) => set(() => ({ isToggle: newIsToggle })),
  setMenuList: (newMenuList) => set(() => ({ menuList: newMenuList })),
}))

export default useSidebar
