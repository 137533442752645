import React from 'react'
import PropTypes from 'prop-types'
import useHandleField from '../model/main'

const GroupView = (props) => {
    const { fields, viewData, resModel, onchangeData, is_root_group, handleOnchange, string } = props

    const { fieldList } = useHandleField({ fields: fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData, handleOnchange: handleOnchange })

    return (
        <div className={`flex flex-col gap-3 ${is_root_group ? "grid col-span-2 grid-cols-2 gap-x-6" : "col-span-2 md:col-span-1"}`}>
            {string && <div className='w-full bg-[rgba(241,246,241,1)] !border-none text-center font-semibold text-sm text-[rgba(82,88,102,1)] py-4 uppercase'>{string}</div>}
            <div className={`${is_root_group ? "grid col-span-2 grid-cols-2 gap-x-6" : "col-span-2 md:col-span-1"}`}>
                {fieldList}
            </div>
        </div>
    )
}

GroupView.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    viewData: PropTypes.object,
    resModel: PropTypes.string,
    onchangeData: PropTypes.object,
    is_root_group: PropTypes.bool,
}

export default GroupView