import axios from 'axios'
import { baseURL } from '../../util/constant/endpoint'
import { LocalStorageService } from '../../util/lib/LocalStorage'

// for multiple requests
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue?.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  paramsSerializer: (params) => queryString.stringify(params),
})

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = LocalStorageService.getAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
//Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return handleResponse(response)
  },
  async (error) => {
    console.log("🚀 ~ error:", error)
    const handleError = (error) => {
      if (!error.response) {
        return error
      }
      const { data } = error.response
      if (
        data &&
        data.code === 400 &&
        ['invalid_grant'].includes(data.data?.error)
      ) {
        clearAuthToken()
      }
      return data
    }
    const originalRequest = error.config
    if (
      (error.response?.status === 403 ||
        error.response?.status === 401 ||
        error.response?.status === 404) &&
      ['TOKEN_EXPIRED', 'AUTHEN_FAIL', 401].includes(error.response.data.code)
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return instance.request(originalRequest)
          })
          .catch((err) => {
            if (
              (err.response?.status === 400 || err.response?.status === 401) &&
              ['invalid_grant'].includes(err.response.data.error)
            ) {
              clearAuthToken()
            }
            // reject(err)
            // processQueue(err, null)
          })
      }

      const refreshToken = LocalStorageService.getSchoolRefreshToken()
      const accessTokenExp = LocalStorageService.getSchoolAccessToken()
      isRefreshing = true

      if (!refreshToken) {
        clearAuthToken()
      }

      return new Promise(function (resolve, reject) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/authentication/oauth2/token`,
            {
              refresh_token: refreshToken,
              grant_type: 'refresh_token',
              client_id: process.env.REACT_APP_CLIENT_ID,
              client_secret: process.env.REACT_APP_CLIENT_SECRET,
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${accessTokenExp}`,
              },
            }
          )
          .then((res) => {
            const data = res.data
            // 1) put token to LocalStorage
            LocalStorageService.setToken(data)
            LocalStorageService.setRefreshToken(data)
            // get information

            // 2) Change Authorization header
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + data.access_token
            originalRequest.headers['Authorization'] =
              'Bearer ' + data.access_token

            processQueue(null, data.access_token)

            // 3) return originalRequest object with Axios
            resolve(instance.request(originalRequest))
          })
          .catch((err) => {
            console.log("hehe", err);

            if (
              err &&
              (err?.error_code === 'AUTHEN_FAIL' ||
                err?.error_code === 'TOKEN_EXPIRED' ||
                err?.error_code === 'TOKEN_INCORRECT' ||
                err?.code === 'ERR_BAD_REQUEST')
            ) {
              clearAuthToken()
            }

            if (err && err.response) {
              const { error_code } = err.response?.data
              if (error_code === 'AUTHEN_FAIL') {
                clearAuthToken()
              }
            }
            processQueue(err, null)
            // reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })

    }

    return Promise.reject(handleError(error))
  }
)

const handleResponse = (res) => {
  if (res && res.data) {
    return res.data
  }

  return res
}

const handleError = (error) => {
  // Check if it's an Axios error and if it's a network error
  if (error.isAxiosError && error.code === 'ECONNABORTED') {
    console.error('Request Timeout Error:', error)
    // Handle timeout error
    // You can return a custom message or perform any action you want
    return 'Request Timeout Error'
  } else if (error.isAxiosError && !error.response) {
    console.error('Network Error:', error)
    // Handle network error
    // You can return a custom message or perform any action you want
    return 'Network Error'
  } else {
    // For other errors, log and return the error
    console.error('Other Error:', error?.response);
    const errorMessage = error?.response?.data?.message || 'An error occurred';
    return { message: errorMessage, status: error?.response?.status };
  }
}
const clearAuthToken = () => {
  LocalStorageService.clearToken()
  window.location.href = `/signin`
}

const responseBody = (response) => response

const requests = {
  get: (url, body, headers) => instance.get(url, body, headers).then(responseBody),

  post: (url, body, headers) => instance.post(url, body, { headers: headers }).then(responseBody),

  put: (url, body, headers) => instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
}

export default requests
