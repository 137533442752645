
import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'react-feather';
import QRCode from './QR/qr_code';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../core/utils/formatter';

const PaymentWaitingScreen = ({ orderData, showQr, paymentSuccess = false }) => {
    const { t } = useTranslation();

    const [paymentStatus, setPaymentStatus] = useState('waiting');

    const { imgTag } = QRCode(
        JSON.stringify(orderData?.info?.amount_total),
        orderData?.info?.des_payment || '',
        orderData?.info?.res_partner_bank_id?.acc_number
    )


    console.log(orderData, "orderData");


    useEffect(() => {
        if (paymentSuccess) {
            setPaymentStatus("success")
        }

    }, [paymentSuccess]);

    // Format time remaining


    return (
        <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} className="d-flex justify-content-center align-items-center mx-auto">
            <div className="w-full max-w-full bg-white rounded-xl shadow-lg p-4 text-center">
                {paymentStatus === 'waiting' && (
                    <>
                        <div className="bg-gray-200 w-48 h-48 mx-auto mb-4 flex items-center justify-center">
                            <div
                                className='w-full h-auto qr-code mb-1 d-flex justify-content-center align-items-center'
                                dangerouslySetInnerHTML={{ __html: imgTag }}
                            />
                        </div>
                        <h2 className="text-lg font-bold mb-2">{t('total')} : {formatPrice(orderData?.info?.amount_total, 'VND')}</h2>
                    </>
                )}

                {paymentStatus === 'success' && (
                    <div className="text-center ">
                        <CheckCircle className="mx-auto mb-4 text-green-500" size={100} />
                        <h2 className="text-2xl font-bold text-green-600 mb-2">Payment Successful!</h2>

                    </div>
                )}

            </div>
        </div>
    );
};

export default PaymentWaitingScreen;