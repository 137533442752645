import React from 'react'

const style = {
    primary: 'text-blue-500 ',
    secondary: 'text-lime-600 ',
    danger: 'text-red-500 ',
    info: 'text-aqua-600 ',
    success: 'text-green-500',
}

const ActiveBadge = ({ type, className }) => {

    if(type === undefined) return
    
    const typeStyle = type ? style?.success : style?.danger
    const text = type ? 'Đang hoạt động' : 'Ngưng hoạt động'

    return (
        <div className={`${typeStyle} ${className}`}>
            {text}
        </div>
    )
}

export default ActiveBadge