import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import React, { Fragment } from 'react'
import { X } from 'react-feather'
import ViewServices from '../../../../core/services/view-service/view-service'
import { useSearchParams } from 'react-router-dom'
import useHeaderStore from '../../../../store/header'
import { evalJSONContext } from '../../../../util/util'
import Form from '../../../../pages/form-view/form'

// const ModalDynamicView = ({ fields, dataModel, viewData, resModel, onchangeResponse, handleOnchange }) => {
//     if (!fields || !dataModel || !onchangeResponse) return;
//     console.log("fields", fields);

//     const { fieldList } = useHandleField({
//         fields: fields,
//         viewData: viewData,
//         resModel: resModel,
//         onchangeData: onchangeResponse,
//         handleOnchange: handleOnchange,
//     });

//     return (
//         <div>
//             {fieldList}
//         </div>
//     );
// };

const ModalDynamic = (props) => {
    const { idModal, actionResult, handleCloseActions, formValues, formSpecification } = props
    const { res_model, views, display_name, res_id, context } = actionResult

    const [searchParams] = useSearchParams()
    const vid = searchParams.get('vid')
    const model = searchParams.get("model")
    const id = parseInt(searchParams.get('id'))
    const { rootContext } = useHeaderStore()

    const contextAction = evalJSONContext(context, {
        active_id: id,
        active_ids: [id],
        active_model: model,
        allowed_company_ids: [1],
        ...formValues
    }) ?? {}

    const { data: viewResponse, isError } = useQuery({
        queryKey: [`view-${vid}`, idModal, res_model],
        queryFn: () => ViewServices.getFieldView({
            id: res_id > 0 ? false : idModal,
            resModel: res_model,
            views: views,
            context: rootContext
        }),
        enabled: !!id,
        refetchOnWindowFocus: false
    })

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleCloseActions} aria-labelledby="modal-title">
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[500]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto flex flex-col justify-center items-center'>
                            <div className='relative z-[1] my-[88px] p-4 flex flex-col gap-4 w-[1000px] transform rounded-3xl bg-[#FFF] min-h-fit'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div id="modal-title" className='text-[20px] font-semibold'>
                                        {display_name ?? viewResponse?.views?.form?.name}
                                    </div>
                                    <button onClick={handleCloseActions} aria-label="Close">
                                        <X size={20} />
                                    </button>
                                </div>

                                {
                                    // isLoading ? (
                                    //     <div className='flex justify-center py-6 flex-1 items-center'>
                                    //         <ClipLoader size={35} color={"#123abc"} loading={isLoading} />
                                    //     </div>
                                    // ) :
                                    isError ? (
                                        <div className='flex justify-center py-6 text-red-500'>
                                            {isError}
                                        </div>
                                    ) : (
                                        <Form
                                            resModel={!idModal && res_id === 0 ? model : res_model}
                                            actionData={{
                                                ...actionResult,
                                                context: {
                                                    ...rootContext,
                                                    active_id: id,
                                                    active_ids: [id],
                                                    active_model: model,
                                                    allowed_company_ids: [1],
                                                    ...contextAction
                                                }
                                            }}
                                            id={!idModal ? res_id === 0 ? id : res_id : null}
                                            viewData={viewResponse}
                                            isDisplayBreadcrumbs={false}
                                            vid={vid}
                                            parentSpecification={!idModal && res_id === 0 ? formSpecification : null}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalDynamic