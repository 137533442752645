
export const origin = 'saas.wesap.vn'
export const baseURL = process.env.REACT_APP_BASE_URL
export const authTokenPath = `/authentication/oauth2/token`
export const profilePath = `/userinfo`
export const logOutPath = `/authentication/oauth2/revoke`
export const callPath = `/call`
export const createPath = `/create`
export const updatePath = `/create_update`
export const deletePath = `/delete`
export const uploadFilePath = `/upload`
export const searchReadPath = `/search_read`
export const searchPath = `/search`
export const delelePath = `/unlink`
export const companyPath = `/company`
export const resetPasswordPath = `/reset_password`
export const checkExpiredTokenPath = `/check_token`
export const uploadFile = `/upload/file`
export const changePasswordPath = `/change_password`
export const reportPath = `/report`
export const loadActionPath = `/load_action`
export const runActionPath = `/run_action`
export const getProvidersPath = `/oauth/providers`
export const generateTokenPath = `/token/generate`
