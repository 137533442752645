import { useState, useEffect, useCallback, useRef } from 'react';


export const useApiPolling = (
    fetchFn,
    checkCondition,
    options = {}
) => {
    const {
        interval = 5000, // Default 5 seconds
        maxAttempts = 20, // Prevent infinite polling
        onSuccess = () => { },
        onError = () => { }
    } = options;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [attempts, setAttempts] = useState(0);

    const pollingRef = useRef(null);

    const startPolling = useCallback(() => {
        console.log("start polling");

        // Clear any existing polling
        if (pollingRef.current) {
            clearInterval(pollingRef.current);
        }

        setLoading(true);
        setAttempts(0);

        const poll = async () => {
            try {
                const result = await fetchFn();
                setData(result);

                // Check if condition is met
                if (checkCondition(result)) {
                    setLoading(false);
                    onSuccess(result);
                    if (pollingRef.current) {
                        clearInterval(pollingRef.current);
                    }
                    return;
                }

                // Increment attempts
                setAttempts(prev => {
                    if (prev >= maxAttempts) {
                        setLoading(false);
                        if (pollingRef.current) {
                            clearInterval(pollingRef.current);
                        }
                        return prev;
                    }
                    return prev + 1;
                });

            } catch (err) {
                setError(err);
                setLoading(false);
                onError(err);

                // Stop polling on error
                if (pollingRef.current) {
                    clearInterval(pollingRef.current);
                }
            }
        };

        // Initial immediate call
        poll();

        // Start interval polling
        pollingRef.current = setInterval(poll, interval);

        // Cleanup function
        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
        };
    }, [fetchFn, checkCondition, interval, maxAttempts, onSuccess, onError]);

    const stopPolling = useCallback(() => {
        if (pollingRef.current) {
            clearInterval(pollingRef.current);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        // Cleanup on unmount
        return () => {
            if (pollingRef.current) {
                clearInterval(pollingRef.current);
            }
        };
    }, []);

    return {
        data,
        loading,
        error,
        attempts,
        startPolling,
        stopPolling
    };
};