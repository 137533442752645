/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ViewServices from "../../../core/services/view-service/view-service";
import HorizontalSidebar from "./HorizontalSidebar";
import useHeaderStore from "../../../store/header";
import { useMetaContext } from "../../../Router/MetaContext";
import { CookieStorage } from "../../../util/lib/Cookies";
import useSidebar from "../../../store/sidebar";


const Sidebar = () => {
  localStorage.setItem("layoutStyling", "horizontal")
  const [openMenus, setOpenMenus] = useState({});
  const [menuFocus, setMenuFocus] = useState();
  const { fadeIn, setFadeIn } = useHeaderStore();
  const [toggle, setToggle] = useState(false);
  const { metadata, loadingImport, openTouch, setOpenTouch, hover, setHover } =
    useMetaContext();
  const lang = CookieStorage.getLanguageContext()
  let pathname = location.pathname;
  const { setMenuList } = useSidebar()

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: listMenu } = useQuery({
    queryKey: ["get_menu_view", lang],
    queryFn: () => ViewServices.getMenuView(lang),
  });

  const handleSidebar = (e) => {
    e.preventDefault();
    document.body.classList.toggle("mini-sidebar");
    setToggle((current) => !current);
    setOpenTouch(!openTouch);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
    setHover(false);
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
    setHover(true);
  };

  const toggleMenu = (item, level) => {
    setOpenMenus((prev) => {
      const newOpenMenus = { ...prev };
      Object.keys(newOpenMenus).forEach((key) => {
        if (
          newOpenMenus[key] &&
          key !== item.id.toString() &&
          key.startsWith(`level-${level}-`)
        ) {
          delete newOpenMenus[key];
        }
      });
      newOpenMenus[`level-${level}-${item.id}`] =
        !prev[`level-${level}-${item.id}`];
      sessionStorage.setItem("parent_icon", JSON.stringify(item));
      return newOpenMenus;
    });
  };

  useEffect(() => {
    const url_icon = sessionStorage.getItem("url_icon") !== 'undefined' && JSON.parse(sessionStorage.getItem("url_icon"));
    const parent_icon = sessionStorage.getItem("url_icon") !== 'undefined' && JSON.parse(sessionStorage.getItem("parent_icon"));
    if (listMenu && !url_icon && !parent_icon) {
      const parent_icon = listMenu?.records[0];
      const url_icon = parent_icon.child_id[0];
      sessionStorage.setItem("parent_icon", JSON.stringify(parent_icon));
      sessionStorage.setItem("url_icon", JSON.stringify(url_icon));
    }
    const menuActive = url_icon ? url_icon : parent_icon;
    setMenuFocus(menuActive?.id);
    setMenuList(listMenu?.records)
  }, [listMenu]);

  const handleNavigation = (item) => {
    const id = item?.action?.id?.id;
    const model = item?.action?.res_model;
    const view = item?.action?.binding_view_types?.split(",")[0];

    sessionStorage.setItem("url_icon", JSON.stringify(item));

    dispatch(menu_focus(id));
    setFadeIn(false)
    navigate(buildUrl(view, id, model));
  };

  const renderMultiLevelMenu = (items, level = 0) => {
    return items?.child_id.map((item, index) => {
      return item.is_display && (
        <li
          key={index}
          className={`submenu submenu-level-${level} ${!item.child_id || item.child_id.length === 0 ? "" : ""}`}
        >
          <button
            className={`btn-transparent button-menu-item w-full flex items-center ${menuFocus == item.id ? "menu-focus" : ""}`}
            onClick={() => {
              if (item.child_id && item.child_id.length > 0) {
                toggleMenu(item, level);
              } else {
                handleNavigation(item);
                setMenuFocus(item.id);
              }
            }}
          >
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                // Your click handler logic here
              }}
              className={
                openMenus[`level-${level}-${item.id}`] ? "subdrop" : ""
              }
            >
              <div className="menu-item">
                {level < 1 &&
                  <img
                    className="icon submenu-hdr"
                    src={item?.web_icon_data || ''}
                    width={16}
                    height={16}
                    alt="img"
                  />}
                <span className="custom-active-span !text-[14px] font-medium leading-5 text-dark">
                  {item.name}
                </span>

                {item.child_id && item.child_id.length > 0 && (
                  <span style={{ top: "0px" }} className="menu-arrow" />
                )}
              </div>
            </Link>
          </button>
          {item.child_id && item.child_id.length > 0 && (
            <ul
              style={{
                display:
                  openMenus[`level-${level}-${item.id}`] && item.is_display
                    ? "block"
                    : "none",
                marginLeft: level * 15,
              }}
              className={`mega-menu`}
            >
              {renderMultiLevelMenu(item, level + 1)}
            </ul>
          )}
        </li>
      );
    });
  };

  return (
    <div className="h-full bg-white">
      <HorizontalSidebar menuList={listMenu?.records} />
    </div>
  );
};

export default Sidebar;