import React from 'react';

const IntegerField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods = null,
  isForm,
  defaultValue,
  handleOnchange,
  type
}) => {
  const formProps = methods ? methods.register(name, {
    onBlur: (e) => {
      const value = parseInt(e.target.value);

      if (!isNaN(value)) {
        methods.setValue(e.target.name, value, {
          shouldDirty: true
        });
        handleOnchange(e.target.name, value);
      }
    }
  }) : {}

  if (!isForm) {
    return <span>{value || defaultValue}</span>
  }

  return (
    <input
      {...formProps}
      defaultValue={value || defaultValue}
      type="text"
      readOnly={isForm ? readonly : true}
      disabled={readonly}
      placeholder={placeholder}
      required={!invisible && required}
      style={invisible ? { display: 'none' } : {}}
      className={` ${!isForm ? "!bg-transparent focus-within:!border-none" : "field"}`}
      onChange={(e) => { e.preventDefault() }}
    />
  )
}

export default IntegerField
