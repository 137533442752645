/* eslint-disable no-unused-vars */
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import POSService from '../../core/services/POSService'
import RestaurantService from '../../core/services/RestaurantService'
import { useNavigate } from 'react-router-dom'
import PosCreateSession from './posCreateSession'
import { useTranslation } from 'react-i18next'
import { Card, CardHeader, CardTitle, Nav, Tab } from 'react-bootstrap'
import useHeaderStore from '../../store/header'
import Loading from '../../components/general/Loading'
import { MoreVertical } from 'react-feather'
import { CardContent } from '@mui/material'
function PosSession() {
  const { t } = useTranslation()
  const [listPOS, setListPOS] = useState([])
  const [listKitchen, setListKitchen] = useState([])

  const [posId, setPosId] = useState({})
  const [activeKey, setActiveKey] = useState('pos')
  const { rootContext } = useHeaderStore()



  useEffect(() => {
    const fetchData = async () => {

      const [posListData] = await Promise.all([POSService.getListPOS(rootContext.allowed_company_ids)])
      if (posListData?.length > 0 && posListData?.records && posListData?.records?.length > 0) {
        setListPOS(posListData.records)
      }

      const [kitchenListData] = await Promise.all([RestaurantService.getAllPreparationDisplay(rootContext.allowed_company_ids)])
      if (kitchenListData?.length > 0 && kitchenListData?.records && kitchenListData?.records?.length > 0) {
        setListKitchen(kitchenListData.records)
      }
    }

    if (rootContext.allowed_company_ids.length > 0) {
      fetchData()
    }
  }, [rootContext])


  const navigate = useNavigate()

  const handleNavigatePOS = (posId) => {
    navigate(`/pos/${posId}`)
  }

  const handleData = (posId) => {
    setPosId(posId)
  }

  const handleNavigateKitchen = (kitchenId) => {
    navigate(`/kitchen/${kitchenId}`)
  }

  const POSContent = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'flex-start',
      }}
      className='row'
    >
      {listPOS?.length > 0 &&
        listPOS.map((pos) => (
          // <div key={pos.id} className='col-sm-4 col-md-6 col-lg-6 col-xl-6'>
          //   <div
          //     className='product-info default-cover card'
          //     style={{
          //       padding: '16px',
          //       border: '1px solid #dee2e6',
          //     }}
          //   >
          //     <div
          //       style={{
          //         display: 'flex',
          //         width: '100%',
          //         justifyContent: 'space-between',
          //         alignItems: 'flex-start',
          //         marginBottom: '16px',
          //       }}
          //     >
          //       <div>
          //         <div style={{ fontWeight: 'bold', fontSize: '20px' }}>{pos?.name}</div>
          //         {/* <div>To Close</div> */}
          //       </div>

          //       {/* <div style={{ cursor: 'pointer' }}>
          //         <MoreVertical />
          //       </div> */}
          //     </div>

          //     <div
          //       style={{
          //         display: 'flex',
          //         width: '100%',
          //         justifyContent: 'space-between',
          //         alignItems: 'center',
          //       }}
          //     >
          //       <div>
          //         <Button
          //           style={{ width: '18vh' }}
          //           className='btn-submit'
          //           type='primary'
          //           onClick={() => (pos.pos_session_state ? handleNavigatePOS(pos.id) : handleData(pos.id))}
          //           {...(pos.pos_session_state
          //             ? {}
          //             : {
          //               'data-bs-toggle': 'modal',
          //               'data-bs-target': '#create-new-session',
          //             })}
          //         >
          //           {' '}
          //           {pos.pos_session_state ? t('continue_selling') : t('new_session')}
          //         </Button>
          //       </div>

          //       <div
          //         style={{
          //           display: 'flex',
          //           width: '40%',
          //           justifyContent: 'space-between',
          //           alignItems: 'center',
          //         }}
          //       >
          //         <div>
          //           <div>{t('closing_date')}</div>
          //           {/* <div>{t('balance')}</div> */}
          //         </div>

          //         <div>
          //           <div>{pos?.last_session_closing_date}</div>
          //           {/* <div>{formatPrice(pos?.number_of_rescue_session, pos.currency_id.display_name)}</div> */}
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          <Card className="w-full shadow-sm hover:shadow-md transition-shadow duration-300">
            <CardHeader className="pb-2">
              <div className="flex justify-between items-start">
                <CardTitle className="text-xl font-bold text-gray-800">
                  {pos?.name}
                </CardTitle>
                {/* Uncomment if needed */}
                <div className="cursor-pointer text-gray-500 hover:text-gray-700">
                  <MoreVertical size={20} />
                </div>
              </div>
            </CardHeader>

            <CardContent className="space-y-4">
              <div className="flex flex-col sm:flex-row justify-between items-center space-y-3 sm:space-y-0">
                <Button
                  className="btn-submit w-full sm:w-48"
                  type="primary"
                  variant={pos.pos_session_state ? "default" : "outline"}
                  onClick={() => (pos.pos_session_state ? handleNavigatePOS(pos.id) : handleData(pos.id))}
                  {...(pos.pos_session_state
                    ? {}
                    : {
                      'data-bs-toggle': 'modal',
                      'data-bs-target': '#create-new-session',
                    })}
                >
                  {' '}
                  {pos.pos_session_state ? t('continue_selling') : t('new_session')}
                </Button>

                <div className="w-full sm:w-auto grid grid-cols-2 gap-2 text-sm text-gray-600">
                  <div className="text-right sm:text-left">
                    <div>{t('closing_date')}</div>
                  </div>

                  <div>
                    <div className="font-semibold">
                      {pos?.last_session_closing_date || t('no_previous_session')}
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))
      }
    </div>
  )

  const KitchenContent = () => (
    <div
      style={{
        width: '100%',
        justifyContent: 'flex-start',
      }}
      className='row'
    >
      {listKitchen?.length > 0 &&
        listKitchen.map((kitchen) => (
          <Card className="w-full shadow-sm hover:shadow-md transition-shadow duration-300">
            <CardHeader className="pb-2">
              <div className="flex justify-between items-start">
                <CardTitle className="text-xl font-bold text-gray-800">
                  {kitchen?.display_name}
                </CardTitle>
                {/* Uncomment if needed */}
                <div className="cursor-pointer text-gray-500 hover:text-gray-700">
                  <MoreVertical size={20} />
                </div>
              </div>
            </CardHeader>

            <CardContent className="space-y-4">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-3 sm:space-y-0">
                <Button
                  onClick={() => handleNavigateKitchen(kitchen.id)}
                  className="btn-submit w-full sm:w-auto"
                  type='primary'
                >
                  {t('open')}
                </Button>

                <div className="w-full sm:w-auto grid grid-cols-2 gap-2 text-sm text-gray-600">
                  <div className="text-right sm:text-left">
                    <div>{t('total_orders_count')}</div>
                    <div>{t('point_of_sale')}</div>
                  </div>

                  <div>
                    <div className="font-semibold">{kitchen?.order_count}</div>
                    <div>{kitchen?.pos_config_ids[0]?.name}</div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
    </div>
  )

  return (
    <div className='page-wrapper fade-in'>
      <div className="page-header flex items-center justify-between !pb-4 md:!pb-6 pt-2 md:!pt-8 gap-3 sm:gap-2 flex-wrap lg:gap-1">
        <div className="add-item d-flex">
          <div style={{ fontSize: '20px' }} className="title text-base md:text-xl tracking-wide text-dark font-semibold whitespace-nowrap">
            {t(`${activeKey}`).toUpperCase()}
          </div>
        </div>

        {/* <div className="page-btn">
          <button className='button-primary flex items-center gap-1 text-[14px]' >
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
              <path
                d='M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z'
                fill='white'
              />
              <path
                d='M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z'
                fill='white'
              />
            </svg>
            {t('add')}
          </button>

        </div> */}
      </div>

      <div className='content'>
        <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
          <Nav variant='tabs' className='mb-4'>
            <Nav.Item>
              <Nav.Link eventKey='pos'>{t('pos_session_list')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey='kitchen'>{t('list_kitchen_display')}</Nav.Link>
            </Nav.Item>
          </Nav>

          {rootContext.allowed_company_ids.length > 0 ? <Tab.Content>
            <Tab.Pane eventKey='pos'>
              <POSContent />
            </Tab.Pane>
            <Tab.Pane eventKey='kitchen'>
              <KitchenContent />
            </Tab.Pane>
          </Tab.Content> : <div style={{ paddingTop: "500px" }}>
            <Loading />
          </div>
          }


        </Tab.Container>
      </div>

      <PosCreateSession posId={posId} />
    </div>
  )
}

export default PosSession
