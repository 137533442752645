import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
const StageOption = memo(({ label, isActive, isFirst, isLast, setActiveStage, optionId }) => {

  const { t } = useTranslation()
  return (
    <label
      onClick={() => { setActiveStage(optionId) }}
      className={`relative flex-shrink-0 cursor-default py-[10px] px-24 font-semibold 
        ${isActive ? 'bg-primary text-white' : 'bg-[#f5e7e7] text-[#121212]'}
        ${isFirst ? 'rounded-l-xl ' : 'rounded-none'} 
        ${isLast ? 'rounded-r-xl' : 'rounded-none'}
      `}
    >
      <input
        type='radio'
        checked={isActive}
        className='hidden'
        disabled
      />
      {t(`${label.display_name}`)} - {`${label.total_orders}`}
      {!isLast && (
        <div
          className={`absolute top-[5px] z-[2] -right-4 w-[30px] h-[30px] border-t-4 border-r-4 border-white transform rotate-45 
            ${isActive ? 'bg-primary' : 'bg-[#f5e7e7]'}
          `}
        ></div>
      )}
    </label>
  );
});

export default StageOption;
