import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ButtonLoading from '../../components/general/ButtonLoading'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UserService from '../../core/services/auth'
import { showErrorMessage, showSuccessMessage } from '../../util/util'
import alertify from 'alertifyjs'

const ResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isValidToken, setIsValidToken] = useState(null); // Initially null to indicate the validation is pending
  const [isLoading, setIsLoading] = useState(true); // Loading state for token validation
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    let isMounted = true;
    
    const validateToken = async () => {

      if (token) {
        try {
          await UserService.checkToken(token);
          if (isMounted) {
            setIsValidToken(true);
          }
        } catch (err) {
          if (isMounted) {
            navigate('/expired');
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        navigate('/expired'); // If no token, directly redirect
      }
    };

    validateToken();
    return () => {
      isMounted = false;
    };
  }, [token, navigate]);

  const togglePasswordVisibility = (e) => {
    e.preventDefault()
    setShowPassword((prevState) => !prevState)
  }

  const toggleConfirmPasswordVisibility = (e) => {
    e.preventDefault()
    setShowConfirmPassword((prevState) => !prevState)
  }

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      showErrorMessage(t('reset_password_matching'));
      return;
    }

    setIsSubmitting(true)
    UserService.resetPassword({ data: data, token: token })
    .then((res) => {
      showSuccessMessage(t('reset_password_success'));
      setTimeout(() => {
        alertify.dismissAll();
        navigate('/signin');
      }, 2000);
    })
    .catch((err) => {
      showErrorMessage(t('reset_password_error'))
    })
    .finally(() => {
      setIsSubmitting(false)
    })
  }

  if (isLoading) {
    // Show a loader or blank state while the token is being validated
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (!isValidToken) {
    // This condition should rarely occur since invalid tokens redirect during validation
    return null;
  }

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center">
          <div className="flex flex-col gap-[64px] bg-white p-10 shadow-lg rounded-md w-[479px]">
            <div className='flex flex-col gap-3'>
              <div className='flex flex-col items-center gap-3'>
                <ImageWithBasePath
                  src={'assets/img/forgot-password/lock.svg'}
                  className="object-cover w-[32px] h-[40px]"
                />
                <div className='text-[20px] text-center font-bold text-[rgb(38,38,38)]'>{t('reset_password_title')}</div>
              </div>

              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  {t('reset_password_new_password')}
                </label>
                <div className='relative'>
                  <input type={showPassword ? 'text' : 'password'}
                    {...register('password')}
                    className='border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder='Vui lòng nhập mật khẩu mới' />
                  <div onClick={togglePasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!showPassword ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  {t('reset_password_confirm_password')}
                </label>
                <div className='relative'>
                  <input type={showConfirmPassword ? 'text' : 'password'}
                    {...register('confirmPassword')}
                    className='border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder='Vui lòng nhập lại mật khẩu mới' />
                  <div onClick={toggleConfirmPasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!showConfirmPassword ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            <ButtonLoading 
              loading={isSubmitting} 
              content={t('reset_password_submit')} 
              className='w-full bg-emerald-500 text-white rounded-md py-3 px-4 font-medium hover:bg-emerald-600 transition-colors'
            />
          </div>
        </form>
      </div>
    </div>

  )
}

export default ResetPassword
