import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { ArrowLeft, ChevronLeft, ChevronRight, Settings, Trash2 } from 'react-feather'
import { useTranslation } from 'react-i18next'
import RestaurantService from '../../core/services/RestaurantService'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import StageBar from './stage-bar/stageBar'
import ImageWithBasePath from '../../components/general/ImageCustom'
const KitchenDisplay = ({ stages, orders, kitchenId, setKitchenDisplay, setStages, setOrders }) => {
  const MySwal = withReactContent(Swal)
  const { t } = useTranslation()

  const preparationStage = stages.reduce((acc, stage) => {
    acc[stage.display_name] = stage.id
    return acc
  }, {})

  const [activeStage, setActiveStage] = useState()

  useEffect(() => {
    if (!activeStage) {
      setActiveStage(preparationStage['To prepare'])
    }
  }, [preparationStage])
  const showConfirmationTakeNoteAlert = (lineId) => {
    MySwal.fire({
      title: t('check_note_confirm'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Đồng ý',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Không',
    }).then((result) => {
      if (result.isConfirmed) {
        // MySwal.fire({
        //   title: 'Done !',
        //   className: 'btn btn-success',
        //   confirmButtonText: 'OK',
        //   customClass: {
        //     confirmButton: 'btn btn-success',
        //   },
        // })
        toggleLineThrough(lineId)
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationRemoveTakeNoteAlert = (lineId) => {
    MySwal.fire({
      title: t('remove_check_note_confirm'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Đồng ý',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Không',
    }).then((result) => {
      if (result.isConfirmed) {
        // MySwal.fire({
        //   title: 'Done !',
        //   className: 'btn btn-success',
        //   confirmButtonText: 'OK',
        //   customClass: {
        //     confirmButton: 'btn btn-success',
        //   },
        // })
        toggleLineThrough(lineId)
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationChangeStageAlert = (orderId, orderStage) => {
    MySwal.fire({
      title: t('change_stage_confirm'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Đồng ý',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Không',
    }).then((result) => {
      if (result.isConfirmed) {
        // MySwal.fire({
        //   title: 'Done !',
        //   className: 'btn btn-success',
        //   confirmButtonText: 'OK',
        //   customClass: {
        //     confirmButton: 'btn btn-success',
        //   },
        // })
        changeOrderStage(orderId, kitchenId, orderStage + 1)
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationDeleteAlert = (orderId) => {
    MySwal.fire({
      title: t('delete_state_confirm'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Đồng ý',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Không',
    }).then((result) => {
      if (result.isConfirmed) {
        // MySwal.fire({
        //   title: 'Done !',
        //   className: 'btn btn-success',
        //   confirmButtonText: 'OK',
        //   customClass: {
        //     confirmButton: 'btn btn-success',
        //   },
        // })
        doneOrderStage(orderId, kitchenId)
      } else {
        MySwal.close()
      }
    })
  }

  const showConfirmationDeleteAllAlert = () => {
    MySwal.fire({
      title: t('delete_all_state_confirm'),
      showCancelButton: true,
      confirmButtonColor: '#00ff00',
      confirmButtonText: 'Đồng ý',
      cancelButtonColor: '#ff0000',
      cancelButtonText: 'Không',
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: t('notify_done'),
          className: 'btn btn-success',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        doneAllOrderStage()
      } else {
        MySwal.close()
      }
    })
  }

  const getOrdersByStage = (stageId) => {
    return orders.filter((order) => order.stage_id === stageId)
  }

  const getStageById = (stageId) => {
    stages.forEach((stage) => {
      return (preparationStage[stage.display_name] = stage.id)
    })
    return Object.keys(preparationStage).find((key) => preparationStage[key] === stageId)
  }

  const getStageIdByName = (stageName) => {
    return stages?.find((stage) => stage.display_name === stageName)?.id
  }

  const stageOrderCount = stages.map((stage) => {
    const orderCount = orders.filter((order) => order.stage_id === stage.id).length
    return { ...stage, total_orders: orderCount }
  })

  const [lineThroughItems, setLineThroughItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 8
  const currentOrders = getOrdersByStage(activeStage)
  const totalPages = Math.ceil(currentOrders.length / itemsPerPage)
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = currentOrders.slice(indexOfFirstItem, indexOfLastItem)

  // Filter orders by stage

  const toggleLineThrough = (itemId) => {
    setLineThroughItems(
      (prev) =>
        prev.includes(itemId)
          ? prev.filter((id) => id !== itemId) // Remove item if it exists
          : [...prev, itemId] // Add item if it doesn't exist
    )
  }
  const getPageNumbers = () => {
    const pageNumbers = []
    const maxVisiblePages = 5
    const halfVisible = Math.floor(maxVisiblePages / 2)

    let startPage = Math.max(1, currentPage - halfVisible)
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const changeOrderStage = async (orderId, kitchenId, stageId) => {
    await RestaurantService.changePreparationStage(orderId, kitchenId, stageId).then(async () => {
      const [kitchenDisplayData] = await Promise.all([RestaurantService.getPreparationDisplayData(kitchenId)])
      if (kitchenDisplayData) {
        setKitchenDisplay(kitchenDisplayData)
        setStages(kitchenDisplayData.stages)
        setOrders(kitchenDisplayData.orders)
      }
    })
  }

  const doneOrderStage = async (orderId, kitchenId) => {
    await RestaurantService.donePreparationStage(orderId, kitchenId).then(async () => {
      const [kitchenDisplayData] = await Promise.all([RestaurantService.getPreparationDisplayData(kitchenId)])
      if (kitchenDisplayData) {
        setKitchenDisplay(kitchenDisplayData)
        setStages(kitchenDisplayData.stages)
        setOrders(kitchenDisplayData.orders)
      }
    })
  }

  const doneAllOrderStage = async () => {
    let orderCompleteIds = orders
      .filter((order) => order.stage_id === preparationStage['Completed'])
      .map((order) => order.id)
    await RestaurantService.donePreparationStage(orderCompleteIds, kitchenId).then(async () => {
      const [kitchenDisplayData] = await Promise.all([RestaurantService.getPreparationDisplayData(kitchenId)])
      if (kitchenDisplayData) {
        setKitchenDisplay(kitchenDisplayData)
        setStages(kitchenDisplayData.stages)
        setOrders(kitchenDisplayData.orders)
      }
    })
  }

  const checkCompletedStage = (stageId) => {
    return stageId === preparationStage['Completed']
  }
  return (
    <div>
      <div className='page-wrapper-pos fade-in p-2'>
        <div className='content'>
          <div className='card flex w-full justify-between rounded-[10px] border border-[#F2F2F2] bg-white p-2 p-3 md:p-6'>
            <div className='card-body'>
              <div className='table-top table-top-two table-top-new'>
                <div className='container-fluid'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='custom-pagination'>
                      <div className='paginations d-flex align-items-center justify-content-center'>
                        <StageBar
                          statusOptions={stageOrderCount}
                          defaultValue={activeStage}
                          setActiveStage={setActiveStage}
                        />
                      </div>
                    </div>
                    <div
                      className='dropdown pos-menu-action'
                      onClick={(e) => {
                        e.preventDefault()
                      }}
                    >
                      <Link
                        className='dropdown-toggle'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        <Settings style={{ backgroundColor: 'white', borderRadius: '8px' }} />
                      </Link>
                      <ul className='dropdown-menu'>
                        <li>
                          <Link
                            to='#'
                            className='dropdown-item confirm-text mb-0'
                            onClick={(e) => {
                              e.preventDefault()
                              showConfirmationDeleteAllAlert()
                            }}
                          >
                            <span className='d-flex align-items-center me-1'>
                              <Trash2 className='feather-16 mr-2' />
                              {t('delete_all_complete_order')}
                            </span>
                          </Link>
                        </li>

                        <li>
                          <Link to='/pos-session' className='dropdown-item'>
                            <span className='d-flex align-items-center me-1'>
                              <ArrowLeft className='feather-16 mr-2' />
                              {t('back')}
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {currentItems.length > 0 ? (
            <div
              style={{ minHeight: '70vh' }}
              className='employee-grid-widget mt-4 flex w-full justify-between rounded-[10px] border border-[#F2F2F2] bg-white p-1 p-3 md:p-6'
            >
              <div className='grid grid-cols-1 gap-4 p-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                {preparationStage != null ? (
                  currentItems.map((order) => (
                    <div key={order.id} className='employee-grid-profile'>
                      <div className='profile-head d-flex justify-content-between pb-2'>
                        <div className='profile-head-action'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='badge badge-linedanger me-1 w-auto text-center'>
                              {order.table.name ? `${t('table')} ${order.table.name}` : 'Take Away'}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h4 className='font-semibold'>Order #{order.id}</h4>
                        </div>
                      </div>

                      <div className='department'>
                        {order.orderlines.map((line) => (
                          <div
                            key={line.id}
                            onClick={() =>
                              lineThroughItems.includes(line.id)
                                ? showConfirmationRemoveTakeNoteAlert(line.id)
                                : showConfirmationTakeNoteAlert(line.id)
                            }
                            style={{
                              borderBottom: '1px solid #DEE7DD',
                              backgroundColor: lineThroughItems.includes(line.id) ? '#e8ebed' : 'transparent',
                              padding: '10px',
                            }}
                            className='d-flex flex-column gap-2'
                          >
                            <div
                              style={{
                                textDecoration: lineThroughItems.includes(line.id) ? 'line-through' : 'none',
                              }}
                              className='d-flex flex-row gap-2'
                            >
                              <div
                                style={{ color: lineThroughItems.includes(line.id) ? '#9DABB7' : '#092C4C' }}
                                className='text-style-kitchen'
                              >
                                {line.product_quantity - line.product_cancelled}x
                              </div>
                              <div
                                style={{ color: lineThroughItems.includes(line.id) ? '#9DABB7' : '#092C4C' }}
                                className='text-style-kitchen'
                              >
                                {line.product_name}
                              </div>
                            </div>

                            {line.product_cancelled > 0 && (
                              <div className='d-flex flex-row gap-2'>
                                <div style={{ textDecoration: 'line-through' }}>{line.product_quantity}x</div>
                                <div
                                  style={{
                                    color: '#ffffff',
                                    backgroundColor: '#FF505B',
                                    padding: '1px 10px',
                                    borderRadius: '5px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {line.product_cancelled} {t('order_canelled')}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      {order.stage_id != getStageIdByName('Completed') ? (
                        <div style={{ marginTop: '10px' }} className='d-flex justify-content-center'>
                          <Button
                            style={{ width: '100%' }}
                            onClick={() => showConfirmationChangeStageAlert(order.id, order.stage_id)}
                          >
                            {t(`${getStageById(order.stage_id + 1)}`)}
                          </Button>
                        </div>
                      ) : (
                        <div style={{ marginTop: '10px' }} className='d-flex justify-content-center'>
                          <Button style={{ width: '100%' }} onClick={() => showConfirmationDeleteAlert(order.id)}>
                            {t('delete_kitchen_order')}
                          </Button>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>{' '}
            </div>
          ) : (
            <div
              style={{ minHeight: '70vh' }}
              className='d-flex align-items-center flex-column justify-center rounded-[10px] border border-[#F2F2F2] bg-white p-6'
            >
              <ImageWithBasePath src='assets/img/empty-content.svg' alt='empty-doc' />
              <span style={{ fontSize: '24px', fontWeight: '600' }} className='text-center'>
                {t('no_data_found')}
              </span>
            </div>
          )}

          {totalPages > 0 && (
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='mb-3 mt-4 flex items-center justify-center gap-2'>
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className='rounded p-2 hover:bg-gray-100 disabled:opacity-50'
                    >
                      <ChevronLeft className='h-5 w-5' />
                    </button>

                    {getPageNumbers().map((number) => (
                      <button
                        key={number}
                        onClick={() => handlePageChange(number)}
                        className={`rounded px-3 py-1 ${currentPage === number ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                          }`}
                      >
                        {number}
                      </button>
                    ))}

                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className='rounded p-2 hover:bg-gray-100 disabled:opacity-50'
                    >
                      <ChevronRight className='h-5 w-5' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default KitchenDisplay
