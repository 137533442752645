import React from 'react';
import ModalLayer from './ModalLayer';
import Form from '../../pages/form-view/form';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ViewServices from '../../core/services/view-service/view-service';
import useFormStore from '../../store/form';

const ModalCalendar = ({ relationMappingData = {}, rootContext, resModel, id = null, setIsOpenModal, isOpenModal, quickCreateViewId }) => {
  const { formSubmitComponent } = useFormStore()

  const { data: viewData } = useQuery({
    queryKey: [`view-${quickCreateViewId}`,],
    queryFn: () => ViewServices.getFieldView({
      resModel: resModel,
      views: [[Number(quickCreateViewId), 'form']],
      context: rootContext
    }),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const handleClose = () => {
    setIsOpenModal(false);
  };

  const submitForm = () => {
    if (formSubmitComponent?.[resModel]) {
      console.log("🚀 ~ submitForm ~ formSubmitComponent:", formSubmitComponent?.[resModel])
      formSubmitComponent?.[resModel]?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <ModalLayer isOpen={isOpenModal} onClose={handleClose} title='Sự kiện mới.' >
      <div className={`min-w-[350px] min-h-[300px] pb-10`}>
        <Form relationMappingData={relationMappingData} isNoteBookViewMode={true} resModel={resModel} actionData={{}} viewData={viewData} id={id} className={'md:grid-cols-4'} />
        <div className="flex gap-3 right-6 justify-end py-2 fixed bottom-0">
          <button
            type='submit'
            onClick={submitForm}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
          >
            Lưu & Đóng
          </button>
        </div>
      </div>
    </ModalLayer>
  );
};

export default ModalCalendar;